import React, { useEffect } from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../styles/Container.styled';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, InputMainHeadingContainer, Select } from '../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../styles/Button.styled';
import { AccountingPeriodLabel } from '../styles/Label.styled';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getDropdownValues } from '../../features/operations/operationsSlice';
import Loader from '../layout/Loader';
import { toast } from 'react-toastify';
import { createHolding, reset, clearErrors, getHoldingById, updateHolding } from '../../features/footprint/holdingSlice';
import { IFootPrintMetaValues, IHoldingValues } from '../../features/footprint/holdingSlice.interfaces';
import { IHoldingInformationErrors } from './Footprint.interfaces';
import HoldingForm from './HoldingForm';
import FootprintForm from './FootprintForm';
import { getFootprintById, updateFootprint } from '../../features/footprint/footprintSlice';


type Props = {}

const UpdateFootprint = (props: Props) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { id } = useParams();

    const { loading, dropdowns } = useAppSelector(state => state.operations)
    const { isUpdated, errors, footprint } = useAppSelector(state => state.footprint)

    useEffect(() => {
        dispatch(getDropdownValues({ section: "HoldingInformationInput" }))
        dispatch(getFootprintById({ id: id ?? "" }))

        if (isUpdated) {
            toast.dismiss();
            toast.success("Footprint updated successfully")
            dispatch(reset());
            navigate("/holdings");
        }

        if (errors) {
            toast.error("Something went wrong");
            console.log(errors);
            dispatch(clearErrors())
        }

    }, [dispatch, errors, id, isUpdated, navigate])

    const submitHandler = (values: IFootPrintMetaValues) => {
        toast.dismiss();
        toast.info("Updating footprint...", {
            autoClose: false
        });
        dispatch(updateFootprint(values))
    }

    return (
        <InputContainer>
            <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>
            <InputFormContainer>
                <InputFormHeading>Update Footprint</InputFormHeading>

                {!footprint ? <Loader /> : <FootprintForm initialValues={footprint} submitHandler={submitHandler} dropdowns={dropdowns} submitText='Update' />}

            </InputFormContainer>
        </InputContainer>
    )
}

export default UpdateFootprint