import styled from "styled-components";

export const FooterContainer = styled.footer`
    width: 100%;
    min-height: 200px;
    background: ${props => props.theme.colours.mainDark};
    color: #fff;
    padding: 40px;
    display: flex;
    justify-content: space-between;
    font-size: 1.8rem;
    margin-top: 40px;
`;

export const LeftInnerContainer = styled.div`
    width: 40%;
`;

export const RightInnerContainer = styled.div`
    width: 40%;
    text-align: right;
`;

export const FooterADASLogo = styled.img`
    width: 50px;
    height: 50px;
`;

export const FooterADASLink = styled.a`
    text-decoration: none !important;
    color: #fff !important;
    font-weight: normal !important;
`;

