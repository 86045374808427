import React, { useEffect, useState } from 'react';
import { Formik, FieldArray } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../../styles/Button.styled';
import { InputSubContainer, InputSubContainerRow, LandUseInputsContainer } from '../../styles/Container.styled';
import { v4 as uuidv4 } from "uuid";
import { purchasedSupplementaryFeedValidationSchema } from '../ValidationSchemas';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getDropdownValues } from '../../../features/operations/operationsSlice';
import { toast } from 'react-toastify';
import FeedRecord from './FeedRecord';
import { ISupplementaryFeeds } from '../Input.interface';
import { clearErrors, getFeedAndBeddingDataById, getFootprintById, putSupplementaryFeedDataById, reset } from '../../../features/footprint/footprintSlice';
import { IFeedAndBeddingData } from '../../../features/footprint/footprintSlice.interfaces';
import Loader from '../../layout/Loader';

type Props = {}

const SupplementaryFeeds = (props: Props) => {

  const [pulledData, setPulledData] = useState(false);
  const [nextPage, setNextPage] = useState(false);
  const [otherLivestockPresent, setOtherLivestockPresent] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();
  const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
  const { loading, feedAndBedding, success, errors, footprint } = useAppSelector(state => state.footprint);
  const { user } = useAppSelector(state => state.auth);

  useEffect(() => {
    const id = sessionStorage.getItem("footprintId") ?? ''
    if (!pulledData) {
      dispatch(getFeedAndBeddingDataById({ id }))
      dispatch(getFootprintById({ id }))
      setPulledData(true)
    }

    if (success) {
      toast.dismiss();
      toast.success("Feed saved successfully");
      dispatch(reset())
      if (nextPage) navigate("/input/purchasedBeddingTypes");
    }

    if (errors) {
      toast.error(errors["Message"]);
      // console.log(errors)
      dispatch(clearErrors())
    }

    setActiveWideStep(4);
    setActiveMobileStep(3);

    dispatch(getDropdownValues({ section: "ForagesAndStrawBeddingInput" }))

  }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])

  const inputIsLocked = () => {
    return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
  }

  const parseDataIntoForm = (supplementaryFeeds: IFeedAndBeddingData["supplementaryFeed"]) => {
    return supplementaryFeeds.map(element => {
      return {
        totalTonnagePurchasedAnnually: element.totalTonnage,
        packagingDisposalMethod: element.packagingDisposalMethod,
        supplier: element.supplier,
        estimatedDistanceFromDistributor: element.distributorDistance,
        supplementaryFeedType: element.supplementaryFeedType,
      }
    })
  }

  return (
    <div>
      <InputFormHeading>Purchased feed and bedding - supplementary feeds</InputFormHeading>
      {loading ? <Loader /> : <Formik
        initialValues={{
          supplementaryFeeds: parseDataIntoForm(feedAndBedding?.supplementaryFeed ?? [])
        }}
        validationSchema={purchasedSupplementaryFeedValidationSchema}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            // console.log(false)
            setSubmitting(false);
          }, 400);

          var feed = values.supplementaryFeeds.map(element => {
            return {
              totalTonnage: element.totalTonnagePurchasedAnnually,
              packagingDisposalMethod: element.packagingDisposalMethod,
              supplier: element.supplier,
              distributorDistance: element.estimatedDistanceFromDistributor,
              supplementaryFeedType: element.supplementaryFeedType,
            }
          })
          dispatch(putSupplementaryFeedDataById(feed))
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
        }) => (
          <InputForm onSubmit={handleSubmit}>

            <InputFormDescription>
              Please enter the total tonnage of each supplementary feed purchased. Select supplier from the dropdown list (if other/unknown then select that option). Provide an estimate of the distance that feed is transported from the distributor. See the tutorial for this page by clicking <a href='https://www.youtube.com/watch?v=qF_iYfHcVeI&list=PLxMZB77n3_Or44Hw2dGQ6LGR8Js3GSJ1I&index=4' target='_blank' rel="noreferrer">here</a>
            </InputFormDescription>

            <FieldArray name="supplementaryFeeds">
              {({ form, ...fieldArrayHelpers }) => {
                const onAddClick = () => {
                  if (inputIsLocked()) {
                    toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                    return;
                  }
                  if (values.supplementaryFeeds.length >= ((dropdowns?.filter(element => element.theme === "SupplementaryFeed").length) ?? 8)) {
                    toast.dismiss();
                    toast.error("Maximum number of supplementary feed types reached");
                    return;
                  }
                  fieldArrayHelpers.push({
                    id: uuidv4(),
                    supplementaryFeedType: "",
                    totalTonnagePurchasedAnnually: 0,
                    packagingDisposalMethod: "",
                    supplier: "FeedSupplierOther",
                    estimatedDistanceFromDistributor: "",
                  });
                };

                const deleteRecordHandler = (id: string) => {
                  if (inputIsLocked()) {
                    toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                    return;
                  }
                  form.setFieldValue(
                    "supplementaryFeeds",
                    form.values.supplementaryFeeds.filter((record: { id: string; }) => record.id !== id)
                  );
                }

                return (
                  <>
                    <AddMainRecordButton src='/icons/plus.png' alt='Add main record' onClick={onAddClick} />

                    {form.values.supplementaryFeeds.length === 0 && (
                      <div>Click the green "plus" button above to add a record if you have any on your farm.</div>
                    )}

                    {form.values.supplementaryFeeds.map((record: ISupplementaryFeeds['supplementaryFeeds'], index: number) => (
                      <FeedRecord
                        index={index}
                        key={index}
                        deleteRecordHandler={deleteRecordHandler}
                        errors={errors}
                        touched={touched}
                        inputIsLocked={inputIsLocked}
                      />
                    ))}
                  </>
                );
              }}
            </FieldArray>

            <div className='d-flex w-100 justify-content-center'>
              <InputFormButtonsContainer>
                <InputFormButton type="button" onClick={() => navigate("/input/purchasedFeedForages")}>
                  Back
                </InputFormButton>

                {inputIsLocked() ? (
                  <>
                    <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                      Continue
                    </InputFormSaveButton>
                  </>
                ) : (
                  <>
                    <InputFormButton disabled={isSubmitting}>
                      Save
                    </InputFormButton>

                    <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                      Save & Continue
                    </InputFormSaveButton>
                  </>
                )}
              </InputFormButtonsContainer>
            </div>
          </InputForm>
        )}
      </Formik>}
    </div>
  )
}

export default SupplementaryFeeds