import React, { FormEvent, useEffect, useState } from 'react';
import { Formik, FieldArray, useField } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../../styles/Button.styled';
import { InputSubContainer, InputSubContainerRow, LandUseInputsContainer } from '../../styles/Container.styled';
import { v4 as uuidv4 } from "uuid";
import { purchasedSupplementaryFeedValidationSchema } from '../ValidationSchemas';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getDropdownValues } from '../../../features/operations/operationsSlice';
import { toast } from 'react-toastify';
import { ISupplementaryFeeds } from '../Input.interface';
import tippy from 'tippy.js';

type Props = {
    index: number,
    errors: any,
    touched: any,
    deleteRecordHandler: (a: string) => void,
    inputIsLocked: () => boolean
}

const FeedRecord = (props: Props) => {

    // binding record properties using index
    const [supplementaryFeedTypeField, supplementaryFeedTypeMeta, supplementaryFeedTypeHelpers] = useField(`supplementaryFeeds[${props.index}].supplementaryFeedType`);
    const [packagingDisposalMethodField, packagingDisposalMethodMeta, packagingDisposalMethodHelpers] = useField(`supplementaryFeeds[${props.index}].packagingDisposalMethod`);
    const [supplierField, supplierMeta, supplierHelpers] = useField(`supplementaryFeeds[${props.index}].supplier`);
    const [totalTonnagePurchasedAnnuallyField, totalTonnagePurchasedAnnuallyMeta, totalTonnagePurchasedAnnuallyHelpers] = useField(`supplementaryFeeds[${props.index}].totalTonnagePurchasedAnnually`);
    const [estimatedDistanceFromDistributorField, estimatedDistanceFromDistributorMeta, estimatedDistanceFromDistributorHelpers] = useField(`supplementaryFeeds[${props.index}].estimatedDistanceFromDistributor`);
    const [idField, idMeta, idHelpers] = useField(`supplementaryFeeds[${props.index}].id`);

    const [showFeedInfo, setShowFeedInfo] = useState(false);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    useEffect(() => {
        if (errors.supplementaryFeeds && errors.supplementaryFeeds[props.index] && touched.supplementaryFeeds &&
            touched.supplementaryFeeds[props.index]) setShowFeedInfo(true)

        tippy('.feed-type-tooltip', {
            content: "If more than one type of supplementary feed is used, use the green plus to add additional lines.  We have created generic feeds that represent the most commonly used feeds on the market.  Select the one(s) that most closely represent what you feed.",
        });

        tippy('.tonnage-purchased-tooltip', {
            content: "Enter the total weight in tonnes of the selected feed purchased annually.",
        });

        tippy('.disposal-method-tooltip', {
            content: "Select the most appropriate method used for disposal of packaging, if more than one method is used select the method that is used for the largest volume.",
        });

        tippy('.distributor-distance-tooltip', {
            content: "Select the estimated distance the holding is from the product distributor. This allows the tool to calculate the transport emissions associated with delivery of the product.",
        });
    }, [errors, touched, showFeedInfo, props.index])

    const onSupplementaryFeedTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        supplementaryFeedTypeHelpers.setValue(target.value);
    };
    const onSupplementaryFeedTypeBlur = () => {
        supplementaryFeedTypeHelpers.setTouched(true);
    };

    const onPackagingDisposalMethodInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        packagingDisposalMethodHelpers.setValue(target.value);
    };
    const onPackagingDisposalMethodBlur = () => {
        packagingDisposalMethodHelpers.setTouched(true);
    };

    const onSupplierInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        supplierHelpers.setValue(target.value);
    };
    const onSupplierBlur = () => {
        supplierHelpers.setTouched(true);
    };

    const onTotalTonnagePurchasedAnnuallyInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        totalTonnagePurchasedAnnuallyHelpers.setValue(target.value);
    };
    const onTotalTonnagePurchasedAnnuallyBlur = () => {
        totalTonnagePurchasedAnnuallyHelpers.setTouched(true);
    };

    const onEstimatedDistanceFromDistributorInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        estimatedDistanceFromDistributorHelpers.setValue(target.value);
    };
    const onEstimatedDistanceFromDistributorBlur = () => {
        estimatedDistanceFromDistributorHelpers.setTouched(true);
    };

    const toggleFeedInfo = () => {
        setShowFeedInfo(!showFeedInfo);
    }

    var [feed, meta, helpers] = useField("supplementaryFeeds")
    var existingSupplementaryFeedTypeRecords = feed.value.filter((element: { id: any; }) => element.id !== idField.value).map((element: { supplementaryFeedType: any; }) => { return element.supplementaryFeedType });

    return (
        <InputSubContainer>

            <InputSubContainerRow>
                <div>
                    <label>Feed type</label>
                </div>

                <div>
                    <select
                        className='form-control'
                        value={supplementaryFeedTypeField.value}
                        onChange={onSupplementaryFeedTypeInput}
                        onBlur={onSupplementaryFeedTypeBlur}
                        data-role="none"
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "SupplementaryFeed" && !existingSupplementaryFeedTypeRecords.includes(element.key)).map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText>{errors.supplementaryFeeds && errors.supplementaryFeeds[props.index] && touched.supplementaryFeeds && touched.supplementaryFeeds[props.index] && errors.supplementaryFeeds[props.index].supplementaryFeedType && touched.supplementaryFeeds[props.index].supplementaryFeedType && errors.supplementaryFeeds[props.index].supplementaryFeedType}</ErrorText>
                </div>


                <div>
                    <UtilityButton className='feed-type-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                    {showFeedInfo ? (
                        <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleFeedInfo} />) : (
                        <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleFeedInfo} />
                    )}
                    <UtilityButton src="/icons/delete.png" alt='tooltip' onClick={() => props.deleteRecordHandler(idField.value)} />
                </div>
            </InputSubContainerRow>

            {showFeedInfo && (
                <InputSubContainer>

                    <InputSubContainerRow>
                        <div>
                            <label>Total tonnage purchased annually (tonnes)</label>
                        </div>

                        <div>
                            <input
                                className='form-control'
                                data-role="none"
                                type="number"
                                value={totalTonnagePurchasedAnnuallyField.value}
                                onChange={onTotalTonnagePurchasedAnnuallyInput}
                                onBlur={onTotalTonnagePurchasedAnnuallyBlur}
                                disabled={props.inputIsLocked()}
                            />
                            <ErrorText>
                                {errors.supplementaryFeeds && errors.supplementaryFeeds[props.index] && touched.supplementaryFeeds &&
                                    touched.supplementaryFeeds[props.index] && errors.supplementaryFeeds[props.index].totalTonnagePurchasedAnnually && touched.supplementaryFeeds[props.index].totalTonnagePurchasedAnnually &&
                                    errors.supplementaryFeeds[props.index].totalTonnagePurchasedAnnually}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton className='tonnage-purchased-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow>

                    <InputSubContainerRow>
                        <div>
                            <label>Packaging disposal method</label>
                        </div>

                        <div>
                            <select
                                className='form-control'
                                data-role="none"
                                value={packagingDisposalMethodField.value}
                                onChange={onPackagingDisposalMethodInput}
                                onBlur={onPackagingDisposalMethodBlur}
                                disabled={props.inputIsLocked()}
                            >
                                <option value="">--Please choose an option--</option>
                                {dropdowns?.filter(element => element.theme === "PackagingDisposalOption").map(value => {
                                    return <option key={value.key} value={value.key}>{value.description}</option>
                                })}
                            </select>
                            <ErrorText>
                                {errors.supplementaryFeeds && errors.supplementaryFeeds[props.index] && touched.supplementaryFeeds &&
                                    touched.supplementaryFeeds[props.index] && errors.supplementaryFeeds[props.index].packagingDisposalMethod && touched.supplementaryFeeds[props.index].packagingDisposalMethod &&
                                    errors.supplementaryFeeds[props.index].packagingDisposalMethod}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton className='disposal-method-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow>

                    {/* <InputSubContainerRow>
                        <div>
                            <label>Supplier</label>

                        </div>

                        <div>
                            <select
                                className='form-control'
                                data-role="none"
                                value={supplierField.value}
                                onChange={onSupplierInput}
                                onBlur={onSupplierBlur}
                            >
                                <option value="">--Please choose an option--</option>
                                {dropdowns?.filter(element => element.theme === "SupplementaryFeedSupplier").map(value => {
                                    return <option key={value.key} value={value.key}>{value.description}</option>
                                })}
                            </select>
                            <ErrorText>
                                {errors.supplementaryFeeds && errors.supplementaryFeeds[props.index] && touched.supplementaryFeeds &&
                                    touched.supplementaryFeeds[props.index] && errors.supplementaryFeeds[props.index].supplier && touched.supplementaryFeeds[props.index].supplier &&
                                    errors.supplementaryFeeds[props.index].supplier}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow> */}


                    <InputSubContainerRow>
                        <div>
                            <label>Estimated distance from distributor (km)</label>

                        </div>

                        <div>
                            <select
                                className='form-control'
                                data-role="none"
                                value={estimatedDistanceFromDistributorField.value}
                                onChange={onEstimatedDistanceFromDistributorInput}
                                onBlur={onEstimatedDistanceFromDistributorBlur}
                                disabled={props.inputIsLocked()}
                            >
                                <option value="">--Please choose an option--</option>
                                {dropdowns?.filter(element => element.theme === "TransportDistance").map(value => {
                                    return <option key={value.key} value={value.key}>{value.description}</option>
                                })}

                            </select>
                            <ErrorText>
                                {errors.supplementaryFeeds && errors.supplementaryFeeds[props.index] && touched.supplementaryFeeds &&
                                    touched.supplementaryFeeds[props.index] && errors.supplementaryFeeds[props.index].estimatedDistanceFromDistributor && touched.supplementaryFeeds[props.index].estimatedDistanceFromDistributor &&
                                    errors.supplementaryFeeds[props.index].estimatedDistanceFromDistributor}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton className='distributor-distance-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow>
                </InputSubContainer>
            )}

        </InputSubContainer>
    )
}

export default FeedRecord