import * as yup from "yup";

const forageHarvestedValidationUnit = yup.object().shape({
    forageType: yup.string().required("Required"),
    metric: yup.string().required("Required"),
    balePlasticWasteDisposalMethod: yup.string().required("Required"),
    totalTonnageProduced: yup.number().when("metric", {
        is: "Quantity",
        then: (schema) => schema.required("Required")
    }),
    baleType: yup.string().when("metric", {
        is: "Bale",
        then: (schema) => schema.required("Required")
    }),
    numberOfBales: yup.number().when("metric", {
        is: "Bale",
        then: (schema) => schema.required("Required")
    }),
})

export const landUseValidationSchema = yup.object().shape({
    landUse: yup.array().of(
        yup.object({
            id: yup.string().required("Required"),
            landType: yup.string().required("Required"),
            landArea: yup.number().when("landType", {
                is: "Hedgerows",
                then: (schema) => schema.notRequired(),
                otherwise: (schema) => schema.moreThan(0).required("Required")
            }),
            totalHedgerowLength: yup.number().when("landType", {
                is: "Hedgerows",
                then: (schema) => schema.required("Required")
            }),
            averageHedgerowWidth: yup.number().when("landType", {
                is: "Hedgerows",
                then: (schema) => schema.required("Required")
            }),
            averageHedgerowHeight: yup.number().when("landType", {
                is: "Hedgerows",
                then: (schema) => schema.required("Required")
            }),
            grasslandGrazingMethod: yup.string().when("landType", {
                is: (landType : string) => ["PermanentGrasslandOver5Years", "TemporaryGrassUnder5Years"].includes(landType),
                then: (schema) => schema.required("Required")
            }),
            woodlandAge: yup.string().when("landType", {
                is: "WoodlandAndShelterBelts",
                then: (schema) => schema.required("Required")
            }),
            hedgerowAge: yup.string().when("landType", {
                is: "Hedgerows",
                then: (schema) => schema.required("Required")
            }),
            manufacturedFertiliserApplied: yup.boolean().when("landType", {
                is: (landType : string) => ["PermanentGrasslandOver5Years", "TemporaryGrassUnder5Years"].includes(landType),
                then: (schema) => schema.required("Required")
            }),
            manufacturedFertilisersApplied: yup.array().when("manufacturedFertiliserApplied", {
                is: true,
                then: (schema) => schema.of(
                    yup.object({
                        id: yup.string().required("Required"),
                        product: yup.string().required("Required"),
                        productName: yup.string().when("product", {
                            is: "OtherManufacturedFertiliser",
                            then: (schema) => schema.required("Required")
                        }),
                        nitrogenContentPercentage: yup.number().required("Required"),
                        phosphateContentPercentage: yup.number().required("Required"),
                        potassiumContentPercentage: yup.number().required("Required"),
                        metric: yup.string().required("Required"),
                        totalTonnageProduced: yup.number().when("metric", {
                            is: "Volume",
                            then: (schema) => schema.required("Required")
                        }),
                        applicationRateUnit: yup.string().when("metric", {
                            is: "ApplicationRate",
                            then: (schema) => schema.required("Required")
                        }),
                        averageApplicationRate: yup.number().when("metric", {
                            is: "ApplicationRate",
                            then: (schema) => schema.required("Required")
                        }),
                    })
                )
            }),
            organicMaterialApplied: yup.boolean().when("landType", {
                is: (landType : string) => ["PermanentGrasslandOver5Years", "TemporaryGrassUnder5Years"].includes(landType),
                then: (schema) => schema.required("Required")
            }),
            organicMaterialsApplied: yup.array().when("organicMaterialApplied", {
                is: true,
                then: (schema) => schema.of(
                    yup.object({
                        id: yup.string().required("Required"),
                        materialType: yup.string().required("Required"),
                        applicationMethod: yup.string().required("Required"),
                        metric: yup.string().required("Required"),
                    })
                )
            }),
            isGrassHarvested: yup.boolean().when("landType", {
                is: (landType : string) => ["PermanentGrasslandOver5Years", "TemporaryGrassUnder5Years"].includes(landType),
                then: (schema) => schema.required("Required")
            }),
            forageHarvested: yup.array().when("isGrassHarvested", {
                is: true,
                then: (schema) => schema.of(forageHarvestedValidationUnit)
            }),
            reference: yup.string()
        })
    )
});



export const horsesValidationSchema = yup.object().shape({
    horses: yup.array().of(
        yup.object({
            id: yup.string().required("Required"),
            animalType: yup.string().required("Required"),
            numOfAnimals: yup.number().integer().min(1).required("Required"),
            avgTimeSpentOnSite: yup.string().required("Required"),
            housingManagement: yup.array().of(
                yup.object({
                    id: yup.string().required("Required"),
                    managementType: yup.string().required("Required"),
                    percentageOfTimeHoused: yup.number().moreThan(0).max(100).required("Required")
                })
            ).notRequired().default(undefined),
            reference: yup.string().required("Required")
        })
    )
})

const forageValidationUnit = yup.object({
    forageType: yup.string().required("Required"),
    metric: yup.string().required("Required"),
    baleType: yup.string().when("metric", {
        is: "Bale",
        then: (schema) => schema.required("Required")
    }),
    numOfAnnuallyPurchasedBale: yup.string().when("metric", {
        is: "Bale",
        then: (schema) => schema.required("Required")
    }),
    totalTonnagePurchasedAnnually: yup.string().when("metric", {
        is: "Tonnage",
        then: (schema) => schema.required("Required")
    }),
    balePlasticWasteDisposal: yup.string().required("Required"),
    estimatedDistanceFromDistributor: yup.string().required("Required"),
})

export const purchasedFeedForageValidationSchema = yup.object().shape({
    foragesAndStrawBedding: yup.array().of(forageValidationUnit)
})

const supplementaryFeedsUnit = yup.object({
    totalTonnagePurchasedAnnually: yup.number().required("Required"),
    packagingDisposalMethod: yup.string().required("Required"),
    // supplier: yup.string().required("Required"),
    estimatedDistanceFromDistributor: yup.string().required("Required"),
    supplementaryFeedType: yup.string().required("Required"),
})

export const purchasedSupplementaryFeedValidationSchema = yup.object().shape({
    supplementaryFeeds: yup.array().of(supplementaryFeedsUnit)
})

const otherBeddingTypeUnit = yup.object({
    totalTonnagePurchasedAnnually: yup.number().required("Required"),
    packagingDisposalMethod: yup.string().required("Required"),
    estimatedDistanceFromDistributor: yup.string().required("Required"),
    otherBeddingType: yup.string().required("Required"),
})

export const purchasedOtherBeddingTypeValidationSchema = yup.object().shape({
    otherBeddingTypes: yup.array().of(otherBeddingTypeUnit)
})

export const horseTransportValidationSchema = yup.object().shape({
    horseTransports: yup.array().of(
        yup.object({
            id: yup.string().required("Required"),
            transportType: yup.string().required("Required"),
            roadTransport: yup.string().when("transportType", {
                is: "Road",
                then: (schema) => schema.required("Required")
            }),
            numOfHorsesTransportedLocal: yup.number().when("transportType", {
                is: "Road",
                then: (schema) => schema.required("Required")
            }),
            numOfHorsesTransportedRegional: yup.number().when("transportType", {
                is: "Road",
                then: (schema) => schema.required("Required")
            }),
            numOfHorsesTransportedNational: yup.number().when("transportType", {
                is: "Road",
                then: (schema) => schema.required("Required")
            }),
            numOfHorsesTransportedOverseasUpToFiveHundredKM: yup.number().when("transportType", {
                is: "Road",
                then: (schema) => schema.required("Required")
            }),
            numOfHorsesTransportedOverseasUpToOneThousandKM: yup.number().when("transportType", {
                is: "Road",
                then: (schema) => schema.required("Required")
            }),
            otherTransport: yup.string().when("transportType", {
                is: "Other",
                then: (schema) => schema.required("Required")
            }),
            location: yup.string().when("transportType", {
                is: "Other",
                then: (schema) => schema.required("Required")
            }),
            otherLocation: yup.string().when("location", {
                is: "Other",
                then: (schema) => schema.required("Required")
            }),
            otherNumOfHorsesTransported: yup.number().when("transportType", {
                is: "Other",
                then: (schema) => schema.required("Required")
            }),
        })
    )
})

const otherLivestockUnit = yup.object({
    livestockType: yup.string().required("Required"),
    numberOfAnimals: yup.number().required("Required"),
    grazingTime: yup.number().required("Required")
})

export const otherLivestockValidationSchema = yup.object().shape({
    otherLivestock: yup.array().of(otherLivestockUnit)
})