import styled, { keyframes } from "styled-components";

export const AuthSpan = styled.span`
    display: grid;
    position: absolute;
    top: 40%;
    bottom: 0;
    right: 0;
    padding-left: 1rem;
    padding-right: 1rem;
    place-content: center;
    color: #9CA3AF;
    width: 1rem;
    height: 1rem;
`;

export const AuthSvg = styled.svg`
    color: #9CA3AF;
    width: 1rem;
    height: 1rem;
`;

const LoaderElementAnimation = keyframes`
    0% {
        box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
    }
    25% {
        box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 2px;
    }
    50% {
        box-shadow: 14px 0 0 -2px,  38px 0 0 -2px,  -14px 0 0 2px,  -38px 0 0 -2px;
    }
    75% {
        box-shadow: 14px 0 0 2px,  38px 0 0 -2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
    }
    100% {
        box-shadow: 14px 0 0 -2px,  38px 0 0 2px,  -14px 0 0 -2px,  -38px 0 0 -2px;
    }
`;

export const LoaderElement = styled.span`
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin:15px auto;
    position: relative;
    color: #9CA3AF;
    box-sizing: border-box;
    animation: ${LoaderElementAnimation} 2s linear infinite;
`;

