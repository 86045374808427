import React, { FormEvent, useEffect, useState } from 'react';
import { FormikErrors, FormikTouched, useField } from "formik";
import { InputSubContainerRow, InputSubContainer } from '../../styles/Container.styled';
import { UtilityButton } from '../../styles/Button.styled';
import { ErrorText } from '../../styles/Form.styled';
import { ILandUse } from '../Input.interface';
import ManufacturedFertiliser from './ManufacturedFertiliser';
import OrganicMaterial from './OrganicMaterial';
import { v4 as uuidv4 } from "uuid";
import ForageHarvested from './ForageHarvested';
import { useAppSelector } from '../../../hooks';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling


type Props = {
    index: number,
    errors: any,
    touched: any,
    deleteForageHarvestedHandler: (a: string, b: string) => void,
    addForageHarvestedHandler: (a: string) => void,
    addManufacturedFertilizerHandler: (a: string) => void,
    deleteRecordHandler: (a: string) => void,
    deleteManufacturedFertilizerHandler: (a: string, b: string) => void
    addOrganicMaterialHandler: (a: string) => void,
    deleteOrganicMaterialHandler: (a: string, b: string) => void,
    updateManufacturedFertiliserHandler: (id: string, fertiliserId: string, fertiliserKey: string) => void,
    inputIsLocked: () => boolean
}

const LandUseRecord = (props: Props) => {

    // binding record properties using index
    const [manufacturedFertiliserAppliedField, manufacturedFertiliserAppliedMeta, manufacturedFertiliserAppliedHelpers] = useField({ name: `landUse[${props.index}].manufacturedFertiliserApplied`, type: "checkbox" });
    const [organicMaterialAppliedField, organicMaterialAppliedMeta, organicMaterialAppliedHelpers] = useField({ name: `landUse[${props.index}].organicMaterialApplied`, type: "checkbox" });
    const [isGrassHarvestedField, isGrassHarvestedMeta, isGrassHarvestedHelpers] = useField({ name: `landUse[${props.index}].isGrassHarvested`, type: "checkbox" });
    const [landTypeField, landTypeMeta, landTypeHelpers] = useField(`landUse[${props.index}].landType`);
    const [landAreaField, landAreaMeta, landAreaHelpers] = useField(`landUse[${props.index}].landArea`);
    const [totalHedgerowLengthField, totalHedgerowLengthMeta, totalHedgerowLengthHelpers] = useField(`landUse[${props.index}].totalHedgerowLength`);
    const [averageHedgerowWidthField, averageHedgerowWidthMeta, averageHedgerowWidthHelpers] = useField(`landUse[${props.index}].averageHedgerowWidth`);
    const [averageHedgerowHeightField, averageHedgerowHeightMeta, averageHedgerowHeightHelpers] = useField(`landUse[${props.index}].averageHedgerowHeight`);
    const [hedgerowAgeField, hedgerowAgeMeta, hedgerowAgeHelpers] = useField(`landUse[${props.index}].hedgerowAge`);
    const [woodlandAgeField, woodlandAgeMeta, woodlandAgeHelpers] = useField(`landUse[${props.index}].woodlandAge`);
    const [woodlandTypeField, woodlandTypeMeta, woodlandTypeHelpers] = useField(`landUse[${props.index}].woodlandType`);
    const [grasslandGrazingMethodField, grasslandGrazingMethodMeta, grasslandGrazingMethodHelpers] = useField(`landUse[${props.index}].grasslandGrazingMethod`);
    const [referenceField, referenceMeta, referenceHelpers] = useField(`landUse[${props.index}].reference`);
    const [idField, idMeta, idHelpers] = useField(`landUse[${props.index}].id`);
    const [manufacturedFertilisersAppliedField, manufacturedFertilisersAppliedMeta, manufacturedFertilisersAppliedHelpers] = useField(`landUse[${props.index}].manufacturedFertilisersApplied`);
    const [organicMaterialsAppliedField, organicMaterialsAppliedMeta, organicMaterialsAppliedHelpers] = useField(`landUse[${props.index}].organicMaterialsApplied`);
    const [forageHarvestedField, forageHarvestedMeta, forageHarvestHelpers] = useField(`landUse[${props.index}].forageHarvested`);

    const [showLandUseTypeInfo, setShowLandUseTypeInfo] = useState(false);
    const [showManufacturedFertilisers, setShowManufacturedFertilisers] = useState(false);
    const [showOrganicMaterialsApplied, setShowOrganicMaterialsApplied] = useState(false);
    const [showForageHarvest, setShowForageHarvest] = useState(false);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    useEffect(() => {
        if (errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index]
            && errors.landUse[props.index].forageHarvested && touched.landUse[props.index].forageHarvested) {
            setShowForageHarvest(true)
            setShowLandUseTypeInfo(true)
        }

        if (errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index]
            && errors.landUse[props.index].manufacturedFertilisersApplied && touched.landUse[props.index].manufacturedFertilisersApplied) {
            setShowManufacturedFertilisers(true)
            setShowLandUseTypeInfo(true);
        }

        if (errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index]
            && errors.landUse[props.index].organicMaterialsApplied && touched.landUse[props.index].organicMaterialsApplied) {
            setShowOrganicMaterialsApplied(true)
            setShowLandUseTypeInfo(true)
        }

        if (errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index]
            && errors.landUse[props.index].grasslandGrazingMethod && touched.landUse[props.index].grasslandGrazingMethod) {
            setShowLandUseTypeInfo(true)
        }

        tippy('.land-type-tooltip', {
            content: "Select the land types that are present on your holding.  If you have more than one land type use the green plus to add additional sections and complete a separate one for each and type.  If you manage areas of pasture grazing differently (e.g. cut some for forage and some is just grazed, or some if fertilised and some is not, you can have more than one selection of the same land type, just make sure the total area is correct for your holding. Permanent and temporary grassland typically used for grazing and/or forage, where as grass margins includes areas outside the pastures such as walkways, verges and lawns.",
        });
    
        tippy('.land-area-tooltip', {
            content: "Enter the area of the land type selected that is present on the holding (in hectares not acres). Multiple parcels of land comprising of the same land type can be grouped together if they have similar levels of fertiliser or manure inputs, or separated if management is different",
        });
    
        tippy('.land-use-forage-tooltip', {
            content: "If this grassland is harvested for forage, please select the tick box which will open another section for forage inputs to be entered.",
        });

        tippy('.land-use-forage-inputs-tooltip', {
            content: "Select the type of forage that is produced. The metric button then allows you to choose how you enter your yield, in total tonnes produced or in total bales (by type). If you are able to enter in tonnes the data will be more accurate. ",
        });
    
        tippy('.land-use-manu-fert-tooltip', {
            content: "If manufactured fertiliser is applied this land type, please select the tick box which will open another section for fertiliser inputs to be entered. If more than one type of fertiliser is used on that area of land use the green plus to add additional fertiliser sections.  Use the down arrow to open up the fertiliser details, and up arrow to close.",
        });

        tippy('.land-use-manu-fert-inputs-tooltip', {
            content: "For each product used select the type of product applied from the dropdown menu – for conventional fertilisers the calculator already knows the nutrient content. If you are using a bespoke blend, select ‘other’, then enter the percentage nitrogen (N), phosphate (P) and potassium (K) in the appropriate box this is often written 20:10:10 (N:P:K) on the packaging.  If other nutrients are included don’t worry about those. For all products use the metric button to determine how you enter the amount used.  This be done either by inputting the total volume applied to the area or application rate.  If application rate is chosen you can select either rate of product or rate of nutrient (e.g. 100 kgN /ha) applied per hectare.",
        });
        
        tippy('.land-use-org-mat-tooltip', {
            content: "If any organic materials (e.g. manures) or lime are applied to this land type, please select the tick box which will open another section for organic material inputs. ",
        });
        
        tippy('.land-use-org-mat-inputs-tooltip', {
            content: "Select the type of material and enter the quantity applied either by entering the total volume or application rate of the product. Any product is selected, and not applied on an annual basis, enter the average volume/application rate per year e.g. if 5 tonnes is applied once every 5 years enter 1 tonne.",
        });

        tippy('.land-use-grazing-tooltip', {
            content: "All year round set stocking - a set number of livestock grazing on this land type for the duration of the year. Set stocking in the summer, ungrazed in winter – a set number of animals grazing on this land type through summer, land rested and ungrazed through winter. Rotationally grazed – land type separated into smaller paddocks, animals frequently moved between paddocks to allow grass to recover.",
        });
        
        tippy('.land-use-reference-tooltip', {
            content: "Use a clear name to reference this land type on the holding especially if you have selected more than one of the same category. ",
        });
    

    }, [errors.landUse, props.index, touched.landUse, showLandUseTypeInfo, showForageHarvest, showManufacturedFertilisers, showOrganicMaterialsApplied])


    const onLandTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        landTypeHelpers.setValue(target.value);
    };
    const onLandTypeBlur = () => {
        landTypeHelpers.setTouched(true);
    };

    const onTotalLandAreaInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        landAreaHelpers.setValue(target.value);
    };
    const onTotalLandAreaBlur = () => {
        landAreaHelpers.setTouched(true);
    };

    const onTotalHedgerowLengthInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        totalHedgerowLengthHelpers.setValue(target.value);
    };
    const onTotalHedgerowLengthBlur = () => {
        totalHedgerowLengthHelpers.setTouched(true);
    };

    const onAverageHedgerowWidthInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        averageHedgerowWidthHelpers.setValue(target.value);
    };
    const onAverageHedgerowWidthBlur = () => {
        averageHedgerowWidthHelpers.setTouched(true);
    };

    const onAverageHedgerowHeightInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        averageHedgerowHeightHelpers.setValue(target.value);
    };
    const onAverageHedgerowHeightBlur = () => {
        averageHedgerowHeightHelpers.setTouched(true);
    };

    const onHedgerowAgeInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLSelectElement;
        hedgerowAgeHelpers.setValue(target.value);
    };
    const onHedgerowAgeBlur = () => {
        hedgerowAgeHelpers.setTouched(true);
    };

    const onWoodlandAgeInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLSelectElement;
        woodlandAgeHelpers.setValue(target.value);
    };
    const onWoodlandAgeBlur = () => {
        woodlandAgeHelpers.setTouched(true);
    };
    
    const onWoodlandTypeInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLSelectElement;
        woodlandTypeHelpers.setValue(target.value);
    };
    const onWoodlandTypeBlur = () => {
        woodlandTypeHelpers.setTouched(true);
    };

    const onGrasslandGrazingMethodInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        grasslandGrazingMethodHelpers.setValue(target.value);
    };
    const onGrasslandGrazingMethodBlur = () => {
        grasslandGrazingMethodHelpers.setTouched(true);
    };

    const onReferenceInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        referenceHelpers.setValue(target.value);
    };
    const onReferenceBlur = () => {
        referenceHelpers.setTouched(true);
    };

    const toggleLandUseTypeInfo = () => {
        setShowLandUseTypeInfo(!showLandUseTypeInfo);
    }

    const toggleShowManufacturedFertilisers = () => {
        setShowManufacturedFertilisers(!showManufacturedFertilisers);
        setShowOrganicMaterialsApplied(false);
        setShowForageHarvest(false);
    }

    const toggleShowOrganicMaterialsApplied = () => {
        setShowOrganicMaterialsApplied(!showOrganicMaterialsApplied);
        setShowManufacturedFertilisers(false);
        setShowForageHarvest(false);
    }

    const toggleShowForageHarvest = () => {
        setShowForageHarvest(!showForageHarvest);
        setShowManufacturedFertilisers(false);
        setShowOrganicMaterialsApplied(false);
    }

    

    return (
        <>
            <InputSubContainer>
                <InputSubContainerRow>
                    <div>
                        <label>Land type</label>
                    </div>

                    <div>
                        <select
                            className='form-control'
                            value={landTypeField.value}
                            onChange={onLandTypeInput}
                            onBlur={onLandTypeBlur}
                            data-role="none"
                            disabled={props.inputIsLocked()}
                        >
                            <option value="">--Please choose an option--</option>
                            {dropdowns?.filter(element => element.theme === "LandUseType").map(value => {
                                return <option key={value.key} value={value.key}>{value.description}</option>
                            })}
                        </select>
                        <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].landType && touched.landUse[props.index].landType && errors.landUse[props.index].landType}</ErrorText>
                    </div>

                    <div>
                        <UtilityButton className='land-type-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        {showLandUseTypeInfo ? (
                            <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleLandUseTypeInfo} />) : (
                            <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleLandUseTypeInfo} />
                        )}
                        <UtilityButton src="/icons/delete.png" alt='tooltip' onClick={() => props.deleteRecordHandler(idField.value)} />
                    </div>

                </InputSubContainerRow>

                {showLandUseTypeInfo && (
                    <InputSubContainer>
                        {landTypeField.value !== "Hedgerows" && (
                            <InputSubContainerRow>
                                <div>
                                    <label>Land Area (ha)</label>
                                </div>

                                <div>
                                    <input
                                        className='form-control'
                                        type='number'
                                        value={landAreaField.value}
                                        onInput={onTotalLandAreaInput}
                                        onBlur={onTotalLandAreaBlur}
                                        disabled={props.inputIsLocked()} />
                                    <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].landArea && touched.landUse[props.index].landArea && errors.landUse[props.index].landArea}</ErrorText>
                                </div>

                                <div>
                                    <UtilityButton className='land-area-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                                </div>
                            </InputSubContainerRow>
                        )}
                        {landTypeField.value === "WoodlandAndShelterBelts" && (
                            <>
                                <InputSubContainerRow>
                                    <div>
                                        <label>How old is this woodland?</label>
                                    </div>

                                    <div>
                                        <select
                                            className='form-control'
                                            value={woodlandAgeField.value}
                                            onChange={onWoodlandAgeInput}
                                            onBlur={onWoodlandAgeBlur}
                                            data-role="none"
                                            disabled={props.inputIsLocked()}
                                        >
                                            <option value="">--Please choose an option--</option>
                                            {dropdowns?.filter(element => element.theme === "AgeOfWoodland").map(value => {
                                                return <option key={value.key} value={value.key}>{value.description}</option>
                                            })}
                                        </select>
                                        <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].woodlandAge && touched.landUse[props.index].woodlandAge && errors.landUse[props.index].woodlandAge}</ErrorText>
                                    </div>

                                    {/* <div>
                                        <UtilityButton src="/icons/tooltip.png" alt='tooltip' />
                                    </div> */}
                                </InputSubContainerRow>
                                <InputSubContainerRow>
                                    <div>
                                        <label>What is the type of woodland?</label>
                                    </div>

                                    <div>
                                        <select
                                            className='form-control'
                                            value={woodlandTypeField.value}
                                            onChange={onWoodlandTypeInput}
                                            onBlur={onWoodlandTypeBlur}
                                            data-role="none"
                                            disabled={props.inputIsLocked()}
                                        >
                                            <option value="">--Please choose an option--</option>
                                            {dropdowns?.filter(element => element.theme === "TypeOfWoodland").map(value => {
                                                return <option key={value.key} value={value.key}>{value.description}</option>
                                            })}
                                        </select>
                                        <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].woodlandAge && touched.landUse[props.index].woodlandAge && errors.landUse[props.index].woodlandAge}</ErrorText>
                                    </div>

                                    {/* <div>
                                        <UtilityButton src="/icons/tooltip.png" alt='tooltip' />
                                    </div> */}
                                </InputSubContainerRow>
                            </>
                        )}

                        {landTypeField.value === "Hedgerows" && (
                            <>
                                <InputSubContainerRow>
                                    <div>
                                        <label>Total hedgerow length (m)</label>
                                    </div>

                                    <div>
                                        <input
                                            className='form-control'
                                            type='number'
                                            min={0}
                                            step="any"
                                            value={totalHedgerowLengthField.value}
                                            onInput={onTotalHedgerowLengthInput}
                                            onBlur={onTotalHedgerowLengthBlur}
                                            disabled={props.inputIsLocked()} />
                                        <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].totalHedgerowLength && touched.landUse[props.index].totalHedgerowLength && errors.landUse[props.index].totalHedgerowLength}</ErrorText>
                                    </div>

                                    {/* <div>
                                        <UtilityButton src="/icons/tooltip.png" alt='tooltip' />
                                    </div> */}
                                </InputSubContainerRow>
                                <InputSubContainerRow>
                                    <div>
                                        <label>Average hedgerow width (m)</label>
                                    </div>

                                    <div>
                                        <input
                                            className='form-control'
                                            type='number'
                                            min={0}
                                            step="any"
                                            value={averageHedgerowWidthField.value}
                                            onInput={onAverageHedgerowWidthInput}
                                            onBlur={onAverageHedgerowWidthBlur}
                                            disabled={props.inputIsLocked()} />
                                        <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].averageHedgerowWidth && touched.landUse[props.index].averageHedgerowWidth && errors.landUse[props.index].averageHedgerowWidth}</ErrorText>
                                    </div>

                                    {/* <div>
                                        <UtilityButton src="/icons/tooltip.png" alt='tooltip' />
                                    </div> */}
                                </InputSubContainerRow>
                                <InputSubContainerRow>
                                    <div>
                                        <label>Average hedgerow height (m)</label>
                                    </div>

                                    <div>
                                        <input
                                            className='form-control'
                                            type='number'
                                            min={0}
                                            value={averageHedgerowHeightField.value}
                                            onInput={onAverageHedgerowHeightInput}
                                            onBlur={onAverageHedgerowHeightBlur}
                                            disabled={props.inputIsLocked()} />
                                        <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].averageHedgerowHeight && touched.landUse[props.index].averageHedgerowHeight && errors.landUse[props.index].averageHedgerowHeight}</ErrorText>
                                    </div>

                                    <div>
                                        <UtilityButton src="/icons/tooltip.png" alt='tooltip' />
                                    </div>
                                </InputSubContainerRow>
                                <InputSubContainerRow>
                                    <div>
                                        <label>How old is this hedgerow?</label>
                                    </div>

                                    <div>
                                        <select
                                            className='form-control'
                                            value={hedgerowAgeField.value}
                                            onChange={onHedgerowAgeInput}
                                            onBlur={onHedgerowAgeBlur}
                                            data-role="none"
                                            disabled={props.inputIsLocked()}
                                        >
                                            <option value="">--Please choose an option--</option>
                                            {dropdowns?.filter(element => element.theme === "AgeOfHedgerow").map(value => {
                                                return <option key={value.key} value={value.key}>{value.description}</option>
                                            })}
                                        </select>
                                        <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].hedgerowAge && touched.landUse[props.index].hedgerowAge && errors.landUse[props.index].hedgerowAge}</ErrorText>
                                    </div>

                                    {/* <div>
                                        <UtilityButton src="/icons/tooltip.png" alt='tooltip' />
                                    </div> */}
                                </InputSubContainerRow>
                            </>
                        )}


                        {["PermanentGrasslandOver5Years", "TemporaryGrassUnder5Years"].includes(landTypeField.value) && (
                            <>
                                <InputSubContainer>
                                    <InputSubContainerRow>
                                        <div>
                                            <label>Is this grass harvested for forage?</label>
                                        </div>

                                        <div>
                                            <input
                                                type='checkbox'
                                                name={isGrassHarvestedField.name}
                                                checked={isGrassHarvestedField.checked}
                                                onChange={({ target }) => {
                                                    isGrassHarvestedHelpers.setValue(target.checked);
                                                    isGrassHarvestedHelpers.setTouched(true);
                                                    setShowForageHarvest(target.checked);
                                                    if (target.checked === false) {
                                                        forageHarvestHelpers.setValue([])
                                                    } else {
                                                        forageHarvestHelpers.setValue([{
                                                            id: uuidv4(),
                                                            forageType: '',
                                                            metric: '',
                                                            totalTonnageProduced: 0,
                                                            balePlasticWasteDisposalMethod: '',
                                                            baleType: '',
                                                            numberOfBales: 0,
                                                        }])
                                                    }
                                                }}
                                                disabled={props.inputIsLocked()} />
                                        </div>

                                        <div>
                                            <UtilityButton className='land-use-forage-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                                            {isGrassHarvestedField.checked && (
                                                <>
                                                    {showForageHarvest ? (
                                                        <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleShowForageHarvest} />) : (
                                                        <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleShowForageHarvest} />
                                                    )}
                                                    <UtilityButton src="/icons/plus.png" alt='add' onClick={() => props.addForageHarvestedHandler(idField.value)} />
                                                </>
                                            )}
                                        </div>
                                    </InputSubContainerRow>

                                    {/* forage types harvested */}
                                    {showForageHarvest && forageHarvestedField.value.map((_: any, index: number) => {
                                        return (
                                            <ForageHarvested 
                                                key={_.id} 
                                                landUseRecordIndex={props.index} 
                                                forageIndex={index} 
                                                errors={props.errors} 
                                                touched={props.touched} 
                                                landRecordId={idField.value} 
                                                deleteForageHarvestedHandler={props.deleteForageHarvestedHandler}
                                                inputIsLocked={props.inputIsLocked} />
                                        )
                                    })}
                                </InputSubContainer>

                                <InputSubContainer>
                                    <InputSubContainerRow>
                                        <div>
                                            <label>Is manufactured fertiliser used here?</label>
                                        </div>

                                        <div>
                                            <input
                                                type='checkbox'
                                                name={manufacturedFertiliserAppliedField.name}
                                                checked={manufacturedFertiliserAppliedField.checked}
                                                onChange={({ target }) => {
                                                    manufacturedFertiliserAppliedHelpers.setValue(target.checked);
                                                    manufacturedFertiliserAppliedHelpers.setTouched(true);
                                                    setShowManufacturedFertilisers(target.checked)
                                                    if (target.checked === false) { manufacturedFertilisersAppliedHelpers.setValue([]); }
                                                    else {
                                                        manufacturedFertilisersAppliedHelpers.setValue([{
                                                            id: uuidv4(),
                                                            product: "",
                                                            productName: "",
                                                            nitrogenContentPercentage: 0,
                                                            phosphateContentPercentage: 0,
                                                            potassiumContentPercentage: 0,
                                                            metric: '',
                                                            totalTonnageProduced: 0,
                                                            applicationRateUnit: '',
                                                            averageApplicationRate: 0
                                                        }])
                                                    }
                                                }}
                                                disabled={props.inputIsLocked()} />
                                        </div>

                                        <div>
                                            <UtilityButton className='land-use-manu-fert-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                                            {manufacturedFertiliserAppliedField.checked && (
                                                <>
                                                    {showManufacturedFertilisers ? (
                                                        <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleShowManufacturedFertilisers} />) : (
                                                        <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleShowManufacturedFertilisers} />
                                                    )}
                                                    <UtilityButton src="/icons/plus.png" alt='add' onClick={() => props.addManufacturedFertilizerHandler(idField.value)} />
                                                </>
                                            )}
                                        </div>
                                    </InputSubContainerRow>

                                    {/* list of fertiliser products */}
                                    {showManufacturedFertilisers && manufacturedFertilisersAppliedField.value.map((_: any, index: number) => {
                                        return (
                                            <ManufacturedFertiliser 
                                                key={_.id} 
                                                updateManufacturedFertiliserHandler={props.updateManufacturedFertiliserHandler} 
                                                landUseRecordIndex={props.index} 
                                                fertiliserIndex={index} 
                                                errors={props.errors} 
                                                touched={props.touched} 
                                                landRecordId={idField.value} 
                                                deleteManufacturedFertilizerHandler={props.deleteManufacturedFertilizerHandler}
                                                inputIsLocked={props.inputIsLocked} />
                                        )
                                    })}
                                </InputSubContainer>

                                <InputSubContainer>
                                    <InputSubContainerRow>
                                        <div>
                                            <label>Are organic materials applied?</label>
                                        </div>

                                        <div>
                                            <input
                                                type='checkbox'
                                                name={organicMaterialAppliedField.name}
                                                checked={organicMaterialAppliedField.checked}
                                                onChange={({ target }) => {
                                                    organicMaterialAppliedHelpers.setValue(target.checked);
                                                    organicMaterialAppliedHelpers.setTouched(true);
                                                    setShowOrganicMaterialsApplied(target.checked);
                                                    if (target.checked === false) { organicMaterialsAppliedHelpers.setValue([]); }
                                                    else {
                                                        organicMaterialsAppliedHelpers.setValue([{
                                                            id: uuidv4(),
                                                            materialType: '',
                                                            applicationMethod: '',
                                                            metric: '',
                                                            totalVolumeApplied: 0,
                                                            averageApplicationRate: 0,
                                                        }])
                                                    }
                                                }}
                                                disabled={props.inputIsLocked()} />
                                        </div>

                                        <div>
                                            <UtilityButton className='land-use-org-mat-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                                            {organicMaterialAppliedField.checked && (
                                                <>
                                                    {showOrganicMaterialsApplied ? (
                                                        <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleShowOrganicMaterialsApplied} />) : (
                                                        <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleShowOrganicMaterialsApplied} />
                                                    )}
                                                    <UtilityButton src="/icons/plus.png" alt='add' onClick={() => props.addOrganicMaterialHandler(idField.value)} />
                                                </>
                                            )}
                                        </div>
                                    </InputSubContainerRow>

                                    {/* list of organic materials */}
                                    {showOrganicMaterialsApplied && organicMaterialsAppliedField.value.map((_: any, index: number) => {
                                        return (
                                            <OrganicMaterial 
                                                key={_.id} 
                                                landUseRecordIndex={props.index} 
                                                fertiliserIndex={index} 
                                                errors={props.errors} 
                                                touched={props.touched} 
                                                landRecordId={idField.value} 
                                                deleteOrganicMaterialHandler={props.deleteOrganicMaterialHandler}
                                                inputIsLocked={props.inputIsLocked} />
                                        )
                                    })}

                                </InputSubContainer>

                                <InputSubContainerRow>
                                    <div>
                                        <label>How is this grassland grazed?</label>
                                    </div>

                                    <div>
                                        <select
                                            className='form-control'
                                            value={grasslandGrazingMethodField.value}
                                            onChange={onGrasslandGrazingMethodInput}
                                            onBlur={onGrasslandGrazingMethodBlur}
                                            data-role="none"
                                            disabled={props.inputIsLocked()}
                                        >
                                            <option value="">--Please choose an option--</option>
                                            {dropdowns?.filter(element => element.theme === "GrazingPattern").map(value => {
                                                return <option key={value.key} value={value.key}>{value.description}</option>
                                            })}
                                        </select>
                                        <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].grasslandGrazingMethod && touched.landUse[props.index].grasslandGrazingMethod && errors.landUse[props.index].grasslandGrazingMethod}</ErrorText>
                                    </div>

                                    <div>
                                        <UtilityButton className='land-use-grazing-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                                    </div>
                                </InputSubContainerRow>
                            </>
                        )}


                    </InputSubContainer>
                )}

                <InputSubContainerRow>
                    <div>
                        <label>Reference</label>
                    </div>

                    <div>
                        <input
                            className='form-control'
                            type='text'
                            value={referenceField.value}
                            onInput={onReferenceInput}
                            onBlur={onReferenceBlur}
                            disabled={props.inputIsLocked()} />
                        <ErrorText>{errors.landUse && errors.landUse[props.index] && touched.landUse && touched.landUse[props.index] && errors.landUse[props.index].reference && touched.landUse[props.index].reference && errors.landUse[props.index].reference}</ErrorText>
                    </div>

                    <div>
                        <UtilityButton className='land-use-reference-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                    </div>
                </InputSubContainerRow>

            </InputSubContainer>
        </>
    )
}

export default LandUseRecord