import styled from "styled-components"

export const InputMainHeadingContainer = styled.div`
    text-align: center;
`;

export const InputFormHeading = styled.h2`
    margin: 0;
`;

export const InputFormDescription = styled.p`
    line-height: 1.2;
    font-size: 15px;
    margin-bottom: 2rem;

    @media (max-width: 756px) {
        font-size: 13px;
    }
`;

export const Input = styled.input`
    min-width: 200px;

    @media (min-width: 992px) {
        min-width: 350px;
    }

    @media (min-width: 1200px) {
        min-width: 350px;
    }
`;

export const Select = styled.select`
    min-width: 200px;

    @media (min-width: 992px) {
        min-width: 350px;
    }

    @media (min-width: 1200px) {
        min-width: 350px;
    }
`;

export const CheckBox = styled.input`
    width: 20px;
    height: 20px;
    type: checkbox;
`;

export const InputFormButtonsContainer = styled.div`
    width: 80%;
    display: flex;
    justify-content: space-between;
    position: absolute;
    bottom: 0;
    
    @media (max-width: 992px) {
        width: 100%;
    }
`;

export const InputFormButton = styled.button`
    margin: 10px;
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
    border: 1px solid #000;
    background: #fff;

    @media (max-width: 992px) {
        margin-left: 8px;
        margin-right: 8px;
    }
`;

export const InputFormSaveButton = styled.button`
    margin: 10px;
    border-radius: 5px;
    padding: 0.5rem 1.5rem;
    background: #3aaa35;
    color: #dedc03;
    border: none;

    @media (max-width: 992px) {
        margin-left: 8px;
        margin-right: 8px;
    }
`;

export const AuthInput = styled.input`
    outline: none;
    border: 1px solid #e5e7eb;
    margin: 8px 0;
    background-color: #fff;
    padding: 1rem;
    padding-right: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 300px;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;

export const ProfileInput = styled.input`
    outline: none;
    border: 1px solid #e5e7eb;
    margin: 8px 0;
    background-color: #fff;
    padding: 1rem;
    padding-right: 3rem;
    font-size: 0.875rem;
    line-height: 1.25rem;
    width: 100%;
    border-radius: 0.5rem;
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
`;