import React, { useEffect, useState } from 'react'
import { useNavigate, Outlet, useOutletContext } from 'react-router-dom';
import { InputContainer, InputFormContainer, InputOutletContainer, InputSection } from '../styles/Container.styled';
import { InputMainHeadingContainer } from '../styles/Input.styled';
import ProgressSteps from './ProgressSteps';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { clearErrors, getFootprintById } from '../../features/footprint/footprintSlice';
import { toast } from 'react-toastify';

type Props = {}
type ContextType = {
    setActiveWideStep: (a: number) => void,
    setActiveMobileStep: (a: number) => void
};

export const useActiveStepHandler = () => {
    return useOutletContext<ContextType>();
}

const Input = (props: Props) => {

    const [activeWideStep, setActiveWideStep] = useState(1);
    const [activeMobileStep, setActiveMobileStep] = useState(1);

    const { metaDataLoading, footprint, errors, loading } = useAppSelector(state => state.footprint);

    const dispatch = useAppDispatch();

    return (
        <InputContainer>
            <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>

            {!loading && (
                <>
                    <div><h3>Assessment Reference: {footprint?.assessmentReference}</h3></div>
                    <div><h4>Footprint ID: {footprint?.id}</h4></div>
                </>
            )}
            
            <InputOutletContainer>
                {/* <div className='d-flex justify-content-between'>
                    <button>Menu</button>
                    <button>Results</button>
                </div> */}
                <ProgressSteps activeStep={activeWideStep} setActiveStep={setActiveWideStep} isWide={true} />
                <ProgressSteps activeStep={activeMobileStep} setActiveStep={setActiveMobileStep} isWide={false} />
                <InputSection>
                    <InputFormContainer>
                        <Outlet context={{ setActiveWideStep, setActiveMobileStep }} />
                    </InputFormContainer>
                    <InputFormContainer>
                        <div className='mb-3'><h3>Quick Summary</h3></div>

                        {!metaDataLoading && (
                            <div>
                                <div className='my-2'>Total holding area: {footprint?.result?.descriptiveStatisticsResult.totalHoldingArea}ha</div>
                                <div className='my-2'>Grazed area: {footprint?.result?.descriptiveStatisticsResult.grazedArea}ha</div>
                                <div className='my-2'>Total number of horses: {footprint?.result?.descriptiveStatisticsResult.totalNumberHorses}</div>
                                <div className='my-2'>Total weight of horses: {footprint?.result?.descriptiveStatisticsResult.totalWeightHorses}kg</div>
                                <div className='my-2'>Average number of horses per day: {footprint?.result?.descriptiveStatisticsResult.averageNumberHorsesPerDay}</div>
                                <div className='my-2'>Total number of other livestock: {footprint?.result?.descriptiveStatisticsResult.totalNumberOtherLivestock}</div>
                                <div className='my-2'>Total volume of feed per year: {footprint?.result?.descriptiveStatisticsResult.totalVolumeFeedPerYear}kg</div>
                                <div className='my-2'>Total volume of forage per year: {footprint?.result?.descriptiveStatisticsResult.totalVolumeForagePerYear}kg</div>
                                <div className='my-2'>Total volume of bedding per year: {footprint?.result?.descriptiveStatisticsResult.totalVolumeBeddingPerYear}kg</div>
                            </div>
                        )}
                    </InputFormContainer>
                </InputSection>
            </InputOutletContainer>
        </InputContainer>
    )
}

export default Input