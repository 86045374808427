import { configureStore, combineReducers } from "@reduxjs/toolkit";
import authReducer from "./features/auth/authSlice";
import userReducer from "./features/auth/userSlice";
import holdingReducer from "./features/footprint/holdingSlice";
import footprintReducer from "./features/footprint/footprintSlice";
import operationsReducer from "./features/operations/operationsSlice";

export const store =  configureStore({
    reducer: combineReducers({
        auth: authReducer,
        user: userReducer,
        holding: holdingReducer,
        footprint: footprintReducer,
        operations: operationsReducer
    })
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;