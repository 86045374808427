import { createGlobalStyle } from "styled-components";

const garamond = require("../../fonts/AGaramondPro-Regular.otf")

export const GlobalStyles = createGlobalStyle`
    * {
        box-sizing: border-box;
        font-size: 16px;

        

        @media (max-width: 1100px) {
            font-size: 14px;
        }

        @media (max-width: 756px) {
            font-size: 13px;
        }

    }

    @font-face {
        font-family: 'AGaramondPro';
        src: url(${garamond}) format('otf');
    }

    body {
        background-color: #F5F5F5;
        font-family: 'Lato';
        min-height: 100%;
        margin: 0;
    }

    table {
        table-layout: fixed;
    }

    select {
        font-size: 13px
    }

    h1 {
        font-family: 'AGaramondPro';
    }

    img {
        max-width: 100%;
    }

    li {
        min-height: 2rem !important;
        display: flex;
        align-items: center
    }

    td {
        vertical-align: middle !important;
    }
`;