import React from 'react'
import packageInfo from '../../../package.json';
import { FooterADASLink, FooterADASLogo, FooterContainer, LeftInnerContainer, RightInnerContainer } from '../styles/Footer.styled'

type Props = {}

const Footer = (props: Props) => {
    const today = new Date();
    const year = today.getFullYear();
    return (
        <FooterContainer>
            <LeftInnerContainer>
                The Thoroughbred Breeders' Association,<br />
                Stanstead House,<br />
                8 The Avenue,<br />
                Newmarket, Suffolk,<br />
                CB8 9AA<br /><br />
                E: info@thetba.co.uk<br />
                T: 01638 661321
            </LeftInnerContainer>
            <RightInnerContainer>
                <div><a href="https://adas.co.uk/" target="_blank" rel="nofollow" ><FooterADASLogo src="/assets/adas-whitelogo@2x.png" alt='adas-logo' /></a></div>
                © {year} <FooterADASLink href="https://adas.co.uk/" target="_blank" rel="nofollow" >RSK ADAS Ltd</FooterADASLink>.<br />
                Commissioned by The Thoroughbred Breeders' Association<br />
                All rights reserved <br />
                V{packageInfo.version}
            </RightInnerContainer>
        </FooterContainer>
    )
}

export default Footer