import React, { useEffect, useState } from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../styles/Container.styled';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, InputMainHeadingContainer, Select } from '../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../styles/Button.styled';
import { AccountingPeriodLabel } from '../styles/Label.styled';
import { useNavigate, useParams } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getDropdownValues } from '../../features/operations/operationsSlice';
import Loader from '../layout/Loader';
import { toast } from 'react-toastify';
import { IFootPrintMetaValues } from '../../features/footprint/holdingSlice.interfaces';
import FootprintForm from './FootprintForm';
import { createFootprint, resetFootprint, reset, clearErrors } from '../../features/footprint/footprintSlice';


type Props = {}

interface IFootprintErrors {
  assessmentReference?: string,
  assessmentType?: string,
}

const NewFootprint = (props: Props) => {

  const [resetStore, setResetStore] = useState(false);

  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const { loading, dropdowns } = useAppSelector(state => state.operations)
  const { success, errors } = useAppSelector(state => state.footprint)

  const { id } = useParams();

  useEffect(() => {

    if(!resetStore) {
      dispatch(resetFootprint());
      setResetStore(true)
    }

    dispatch(getDropdownValues({ section: "HoldingInformationInput" }))

    if (success) {
      toast.dismiss();
      toast.success("Footprint created successfully")
      dispatch(reset());
      navigate(`/holdings/footprints/${id}`);
      
    }

    if (errors) {
      toast.error("Something went wrong");
      console.log(errors);
      dispatch(clearErrors())

    }

  }, [dispatch, errors, navigate, success])

  const submitHandler = (values: IFootPrintMetaValues) => {
    toast.dismiss();
    toast.info("Creating footprint...", {
      autoClose: false
    });
    dispatch(createFootprint({ ...values, holdingId: id }))
  }

  return (
    <InputContainer>
      <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>
      <InputFormContainer>
        <InputFormHeading>Create Footprint</InputFormHeading>

        {loading ? <Loader /> : <FootprintForm initialValues={undefined} submitHandler={submitHandler} dropdowns={dropdowns} submitText='Create' />}

      </InputFormContainer>
    </InputContainer>
  )
}

export default NewFootprint