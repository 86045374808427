import React from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../styles/Container.styled';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButtonsContainer, InputFormHeading, Select } from '../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../styles/Form.styled';
import { UtilityButton } from '../styles/Button.styled';
import { AccountingPeriodLabel } from '../styles/Label.styled';

type Props = {}

interface IHoldingInformationErrors {
  email?: string,
  assessmentReference?: string,
  region?: string,
  accountingPeriodEndMonth?: string,
  accountingPeriodEndYear?: string,
  systemDescription?: string,
  assessmentType?: string,
  termsAgreed?: string
}

let listOfYears: number[] = []
let currentYear = new Date().getFullYear();
for (var i = 2; i > -5; i--) {
  listOfYears.push(currentYear - i);
}

const HoldingInformation = (props: Props) => {
  return (
    <div>
      <InputFormHeading>Holding Information</InputFormHeading>
      <Formik
        initialValues={{ email: '', region: '', assessmentReference: '', systemDescription: '', assessmentType: '', termsAgreed: false, accountingPeriodEndMonth: undefined, accountingPeriodEndYear: undefined }}
        validate={values => {
          const errors: IHoldingInformationErrors = {};
          // if (!values.email) {
          //   errors.email = 'Required';
          // } else if (
          //   !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)
          // ) {
          //   errors.email = 'Invalid email address';
          // }
          if (!values.assessmentReference) {
            errors.assessmentReference = 'Required';
          }
          if (!values.region) {
            errors.region = 'Required';
          }
          if (!values.systemDescription) {
            errors.systemDescription = 'Required';
          }
          if (!values.assessmentType) {
            errors.assessmentType = 'Required';
          }
          if (!values.termsAgreed) {
            errors.termsAgreed = 'Must agree to terms to proceed';
          }
          return errors;
        }}
        onSubmit={(values, { setSubmitting }) => {
          setTimeout(() => {
            alert(JSON.stringify(values, null, 2));
            setSubmitting(false);
          }, 400);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <InputForm onSubmit={handleSubmit}>
            <StandardInputGroup>
              <label>
                Assessment reference
              </label>

              <div className='d-flex align-items-center'>
                <div>
                  <Input
                    type="assessmentReference"
                    name="assessmentReference"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.assessmentReference}
                    className='form-control'
                  />
                  <ErrorText>{errors.assessmentReference && touched.assessmentReference && errors.assessmentReference}</ErrorText>
                </div>
                <UtilityButton src="/icons/tooltip.png" alt='Tooltip' />
              </div>
            </StandardInputGroup>

            <StandardInputGroup>
              <label>
                Region
              </label>

              <div className='d-flex align-items-center'>
                <div>
                  <Select
                    name="region"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.region}
                    className='form-control'
                  >
                    <option value="">--Please choose an option--</option>
                    <option value="East Midlands (England)">East Midlands (England)</option>
                    <option value="East of England">East of England</option>
                    <option value="London">London</option>
                    <option value="Northern Ireland">Northern Ireland</option>
                    <option value="North East (England)">North East (England)</option>
                    <option value="North West (England)">North West (England)</option>
                    <option value="Scotland">Scotland</option>
                    <option value="South East (England)">South East (England)</option>
                    <option value="South West (England)">South West (England)</option>
                    <option value="Wales">Wales</option>
                    <option value="West Midlands (England)">West Midlands (England)</option>
                    <option value="Yorkshire and the Humber">Yorkshire and the Humber</option>
                  </Select>
                  <ErrorText>{errors.region && touched.region && errors.region}</ErrorText>
                </div>
                <UtilityButton src="/icons/tooltip.png" alt='Tooltip' />
              </div>
            </StandardInputGroup>

            <StandardInputGroup>
              <label>
                End date of accounting period
              </label>

              <div className='d-flex align-items-center'>
                <AccountingPeriodLabel>
                  Month
                </AccountingPeriodLabel>
                <div>
                  <select
                    name="accountingPeriodEndMonth"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.accountingPeriodEndMonth}
                    className='form-control'
                  >
                    <option value="">--</option>
                    <option value={1}>1</option>
                    <option value={2}>2</option>
                    <option value={3}>3</option>
                    <option value={4}>4</option>
                    <option value={5}>5</option>
                    <option value={6}>6</option>
                    <option value={7}>7</option>
                    <option value={8}>8</option>
                    <option value={9}>9</option>
                    <option value={10}>10</option>
                    <option value={11}>11</option>
                    <option value={12}>12</option>
                  </select>
                  <ErrorText>{errors.accountingPeriodEndMonth && touched.accountingPeriodEndMonth && errors.accountingPeriodEndMonth}</ErrorText>
                </div>
                <AccountingPeriodLabel>
                  Year
                </AccountingPeriodLabel>
                <div>
                  <select
                    name="accountingPeriodEndYear"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.accountingPeriodEndYear}
                    className='form-control'
                  >
                    <option value="">--</option>
                    {listOfYears.map(element => {
                      return <option key={element} value={element}>{element}</option>
                    })}
                  </select>
                  <ErrorText>{errors.accountingPeriodEndYear && touched.accountingPeriodEndYear && errors.accountingPeriodEndYear}</ErrorText>
                </div>
                <UtilityButton src="/icons/tooltip.png" alt='Tooltip' />
              </div>
            </StandardInputGroup>

            <StandardInputGroup>
              <label>
                Brief description of system
              </label>

              <div className='d-flex align-items-center'>
                <div>
                  <Select
                    name="systemDescription"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.systemDescription}
                    className='form-control'
                  >
                    <option value="">--Please choose an option--</option>
                    <option value="Owner/breeder with resident stallions">Owner/breeder with resident stallions</option>
                    <option value="Owner/breeder without resident stallions">Owner/breeder without resident stallions</option>
                    <option value="Commercial farm with resident stallions">Commercial farm with resident stallions</option>
                    <option value="Commercial farm without resident stallions">Commercial farm without resident stallions</option>
                    <option value="Youngstock rearing farm">Youngstock rearing farm</option>
                    <option value="Breaking in and pre-training">Breaking in and pre-training</option>
                    <option value="Sales preparation">Sales preparation</option>
                    <option value="Resting and rehabilitation">Resting and rehabilitation</option>
                  </Select>
                  <ErrorText>{errors.systemDescription && touched.systemDescription && errors.systemDescription}</ErrorText>
                </div>
                <UtilityButton src="/icons/tooltip.png" alt='Tooltip' />
              </div>
            </StandardInputGroup>

            <StandardInputGroup>
              <label>
                Type of assessment
              </label>

              <div className='d-flex align-items-center'>
                <div>
                  <Select
                    name="assessmentType"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.assessmentType}
                    className='form-control'
                  >
                    <option value="">--Please choose an option--</option>
                    <option value="Baseline assessment">Baseline assessment</option>
                    <option value="Follow-up assessment">Follow-up assessment</option>
                    <option value="Scenario/testing">Scenario/testing</option>
                  </Select>
                  <ErrorText>{errors.assessmentType && touched.assessmentType && errors.assessmentType}</ErrorText>
                </div>
                <UtilityButton src="/icons/tooltip.png" alt='Tooltip' />
              </div>
            </StandardInputGroup>

            <StandardInputGroup>
              <label htmlFor='termsAgreed'>
                I agree to the TBA’s <a href=''>terms and conditions</a>
              </label>

              <div className='d-flex align-items-center'>
                <div className='d-flex'>
                  <ErrorText className='mx-2'>{errors.termsAgreed && touched.termsAgreed && errors.termsAgreed}</ErrorText>
                  <CheckBox
                    type="checkbox"
                    name="termsAgreed"
                    id='termsAgreed'
                    onChange={handleChange}
                    onBlur={handleBlur}
                    checked={values.termsAgreed}
                    className='form-control'
                  />
                </div>
                <UtilityButton src="/icons/tooltip.png" alt='Tooltip' />
              </div>
            </StandardInputGroup>

            <InputFormButtonsContainer>
              <button>
                Back
              </button>

              <button disabled={isSubmitting}>
                Save
              </button>

              <button type="submit" disabled={isSubmitting}>
                Save & Continue
              </button>
            </InputFormButtonsContainer>
          </InputForm>
        )}
      </Formik>

    </div>
  )
}

export default HoldingInformation