import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../hooks';
import { useNavigate } from 'react-router-dom';
import { getDropdownValues } from '../features/operations/operationsSlice';
import { toast } from 'react-toastify';
import { clearErrors, getAllFootprints, getBenchmarkingData, resetBenchmarking } from '../features/footprint/footprintSlice';
import Loader from './layout/Loader';
import { InputContainer, SummaryFilterOptionsContainer, SummaryGraphContainer, SummaryStatRow } from './styles/Container.styled';
import { AdminSummaryFilterButton } from './styles/Button.styled';
import { InputMainHeadingContainer } from './styles/Input.styled';
import { SummaryGraphTitle } from './styles/Label.styled';
import Chart from "react-apexcharts";

type Props = {}

const Benchmarking = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [region, setRegion] = useState("");
    const [system, setSystem] = useState("");
    const [startYear, setStartYear] = useState("");
    const [endYear, setEndYear] = useState("");
    const [minHorses, setMinHorses] = useState("");
    const [maxHorses, setMaxHorses] = useState("");
    const [minArea, setMinArea] = useState("");
    const [maxArea, setMaxArea] = useState("");
    const [assessmentType, setAssessmentType] = useState("");
    const [currentFootprintId, setCurrentFootprintId] = useState("");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { loading, errors, benchmarkLoading, benchmarkingData, footprints } = useAppSelector(state => state.footprint);
    const { loading: operartionsLoading, dropdowns } = useAppSelector(state => state.operations);

    useEffect(() => {
        dispatch(getDropdownValues({ section: "HoldingInformationInput" }))

        if (!pulledData) {
            dispatch(getAllFootprints())
            dispatch(resetBenchmarking());
            setPulledData(true);
        }

        if (errors) {
            toast.error(errors["Message"])
            console.log(errors);
            dispatch(clearErrors())
        }

    }, [dispatch, errors, pulledData])

    const totalEmissionOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Total Emissions Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const fertiliserProductionOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Fertiliser Production Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const feedAndBeddingOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Feed And Bedding Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const entericMethaneOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Enteric Methane Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const manureManagementOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Feed And Bedding Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const otherLivestockOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Other Livestock Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const nitrogenApplicationOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Nitrogen Application Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const organicMaterialOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Organic Materials Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const energyUseOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Energy Use Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const horseTransportOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Horse Transport Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }
    const inputTransportOptions = {
        colors: ['#008FFB', '#FEB019'],
        title: {
            text: 'BoxPlot - Scatter Inputs Transport Emission Chart',
        },
        tooltip: {
            shared: false,
            intersect: true
        }
    }

    // sort array ascending
    const asc = (arr: number[]) => arr.sort((a, b) => a - b);

    const sum = (arr: number[]) => arr.reduce((a, b) => a + b, 0);

    const mean = (arr: number[]) => sum(arr) / arr.length;

    const quantile = (arr: number[], q: number) => {
        const sorted = asc(arr);
        const pos = (sorted.length - 1) * q;
        const base = Math.floor(pos);
        const rest = pos - base;
        if (sorted[base + 1] !== undefined) {
            return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
        } else {
            return sorted[base];
        }
    };

    const q25 = (arr: number[]) => quantile(arr, .25);

    const q50 = (arr: number[]) => quantile(arr, .50);

    const q75 = (arr: number[]) => quantile(arr, .75);

    const median = (arr: number[]) => q50(arr);

    const generateTotalEmissionsSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => {
            return Number(element.fetiliserProduction.toFixed(0)) + Number(element.feedAndBedding.toFixed(0)) + Number(element.entericMethane.toFixed(0)) + Number(element.manureManagement.toFixed(0)) + Number(element.otherLivestock.toFixed(0)) + Number(element.nitrogenApplication.toFixed(0))
                + Number(element.organicMaterials.toFixed(0)) + Number(element.energyUse.toFixed(0)) + Number(element.transportOfHorses.toFixed(0)) + Number(element.inputsTransport.toFixed(0));
        }) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.fetiliserProduction.toFixed(0)) + Number(benchmarkingData?.currentFootPrintEmission.feedAndBedding.toFixed(0)) + Number(benchmarkingData?.currentFootPrintEmission.entericMethane.toFixed(0)) + Number(benchmarkingData?.currentFootPrintEmission.manureManagement.toFixed(0)) + Number(benchmarkingData?.currentFootPrintEmission.otherLivestock.toFixed(0)) + Number(benchmarkingData?.currentFootPrintEmission.nitrogenApplication.toFixed(0))
                        + Number(benchmarkingData?.currentFootPrintEmission.organicMaterials.toFixed(0)) + Number(benchmarkingData?.currentFootPrintEmission.energyUse.toFixed(0)) + Number(benchmarkingData?.currentFootPrintEmission.transportOfHorses.toFixed(0)) + Number(benchmarkingData?.currentFootPrintEmission.inputsTransport.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateFertiliserProductionSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageFertiliserProduction.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.averageFertiliserProduction.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateFeedAndBeddingSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageFeedAndBedding.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.averageFeedAndBedding.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateEntericMethaneSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageEntericMethane.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.averageEntericMethane.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateManureManagementSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageManureManagement.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.averageManureManagement.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateOtherLivestockSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageOtherLivestock.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: (benchmarkingData?.currentFootPrintEmission.averageOtherLivestock.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateNitrogenApplicationSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageNitrogenApplication.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.averageNitrogenApplication.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateOrganicMaterialSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageOrganicMaterials.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.averageOrganicMaterials.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateEnergyUseSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageEnergyUse.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.averageEnergyUse.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateHorseTransportSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageTransportOfHoreses.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.averageTransportOfHoreses.toFixed(0))
                    }
                ]
            }
        ]
    }
    const generateInputsTransportSeries = () => {
        var values = benchmarkingData?.allFootPrintEmissions.map(element => Number(element.averageInputsTransport.toFixed(0))) ?? []
        return [
            {
                name: "Benchmark",
                type: "boxPlot",
                data: [
                    {
                        x: "CO2e",
                        y: [Math.min(...values), q25(values), median(values), q75(values), Math.max(...values)]
                    }
                ]
            },
            {
                name: "Your footprint",
                type: "scatter",
                data: [
                    {
                        x: "CO2e",
                        y: Number(benchmarkingData?.currentFootPrintEmission.averageInputsTransport.toFixed(0))
                    }
                ]
            }
        ]
    }


    const resetFilters = () => {
        setRegion("");
        setSystem("");
        setAssessmentType("");
        setStartYear("");
        setEndYear("");
        setMinArea("");
        setMaxArea("");
        setMinHorses("");
        setMaxHorses("");
    }

    const footprintBenchmarkHandler = () => {
        if (currentFootprintId === "") {
            toast.error("Please select a footprint to benchmark")
            return;
        }

        toast.info("Benchmarking data...");
        dispatch(getBenchmarkingData({
            region,
            minimumTotalHoldingArea: Number(minArea),
            minimumNumberOfHorses: Number(minHorses),
            maximumTotalHoldingArea: Number(maxArea),
            maximumNumberOfHorses: Number(maxHorses),
            holdingSystem: system,
            startYear: Number(startYear),
            endYear: Number(endYear),
            currentFootprintId
        }))
    }

    return (
        <>
            {loading ? <Loader /> : <InputContainer>

                <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>

                <div><h4>Footprint Benchmarking</h4></div>

                <div>
                    {(footprints?.filter(element => element.status === "Submitted") ?? []).length > 0 && (
                        <>
                            <label>Selected Footprint</label>
                            <select
                                value={currentFootprintId}
                                onChange={(e) => setCurrentFootprintId(e.target.value)}
                                className='form-control'
                            >
                                <option value="">--Please choose an option--</option>
                                {footprints?.filter(element => element.status === "Submitted").map(value => {
                                    return <option key={value.id} value={value.id}>{value.assessmentReference}</option>
                                })}
                            </select>
                        </>
                    )}

                    {footprints?.filter(element => element.status === "Submitted").length === 0 && (
                        <p style={{ color: "red" }}>You must complete at least one footprint to access this.</p>
                    )}
                </div>


                <SummaryFilterOptionsContainer>
                    <div className='row'>
                        <div className='col-sm-6 col-md-4 col-12'>
                            <div>
                                <label>Region</label>
                                <select
                                    value={region}
                                    onChange={(e) => setRegion(e.target.value)}
                                    className='form-control'
                                >
                                    <option value="">--Please choose an option--</option>
                                    {dropdowns?.filter(element => element.theme === "RegionList").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                            </div>
                            <div>
                                <label>System</label>
                                <select
                                    value={system}
                                    onChange={(e) => setSystem(e.target.value)}
                                    className='form-control'
                                >
                                    <option value="">--Please choose an option--</option>
                                    {dropdowns?.filter(element => element.theme === "FarmType").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                            </div>
                        </div>
                        <div className='col-sm-6 col-md-4 col-12'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label>Start Year</label>
                                    <input
                                        type='number'
                                        value={startYear}
                                        onChange={(e) => setStartYear(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                                <div>
                                    <label>End Year</label>
                                    <input
                                        type='number'
                                        value={endYear}
                                        onChange={(e) => setEndYear(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label>Min Horses</label>
                                    <input
                                        type='number'
                                        value={minHorses}
                                        onChange={(e) => setMinHorses(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                                <div>
                                    <label>Max Horses</label>
                                    <input
                                        type='number'
                                        value={maxHorses}
                                        onChange={(e) => setMaxHorses(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                            </div>

                        </div>
                        <div className='col-sm-6 col-md-4 col-12'>
                            <div className='d-flex justify-content-between'>
                                <div>
                                    <label>Min Area (Ha)</label>
                                    <input
                                        type='number'
                                        value={minArea}
                                        onChange={(e) => setMinArea(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                                <div>
                                    <label>Max Area (Ha)</label>
                                    <input
                                        type='number'
                                        value={maxArea}
                                        onChange={(e) => setMaxArea(e.target.value)}
                                        className='form-control'
                                    />
                                </div>
                            </div>
                            <div>
                                <label>Assessment Type</label>
                                <select
                                    value={assessmentType}
                                    onChange={(e) => setAssessmentType(e.target.value)}
                                    className='form-control'
                                >
                                    <option value="">--Please choose an option--</option>
                                    {dropdowns?.filter(element => element.theme === "AssessmentType").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                            </div>

                        </div>

                    </div>
                    <div className='d-flex flex-column justify-content-center align-items-center'>
                        <AdminSummaryFilterButton onClick={resetFilters} >Reset Filters</AdminSummaryFilterButton>
                        <AdminSummaryFilterButton onClick={footprintBenchmarkHandler} disabled={benchmarkLoading}>Get Benchmark Data</AdminSummaryFilterButton>
                    </div>

                </SummaryFilterOptionsContainer>



                {!benchmarkLoading && benchmarkingData && (
                    <>

                        <SummaryStatRow>
                            <SummaryGraphContainer style={{ width: '100%' }}>
                                <Chart
                                    options={totalEmissionOptions}
                                    series={generateTotalEmissionsSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                        </SummaryStatRow>

                        <div>
                            <h5 className='mb-4'>The following visualizations describe the selected footprints performance in terms of emissions per number of horses from each source</h5>
                        </div>

                        <SummaryStatRow>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Fertiliser Production</SummaryGraphTitle>
                                <Chart
                                    options={fertiliserProductionOptions}
                                    series={generateFertiliserProductionSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Feed and Bedding</SummaryGraphTitle>
                                <Chart
                                    options={feedAndBeddingOptions}
                                    series={generateFeedAndBeddingSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                        </SummaryStatRow>
                        <SummaryStatRow>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Enteric Methane</SummaryGraphTitle>
                                <Chart
                                    options={entericMethaneOptions}
                                    series={generateEntericMethaneSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Manure Management</SummaryGraphTitle>
                                <Chart
                                    options={manureManagementOptions}
                                    series={generateManureManagementSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                        </SummaryStatRow>
                        <SummaryStatRow>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Other Livestock</SummaryGraphTitle>
                                <Chart
                                    options={otherLivestockOptions}
                                    series={generateOtherLivestockSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Nitrogen Application</SummaryGraphTitle>
                                <Chart
                                    options={nitrogenApplicationOptions}
                                    series={generateNitrogenApplicationSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                        </SummaryStatRow>
                        <SummaryStatRow>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Organic Materials</SummaryGraphTitle>
                                <Chart
                                    options={organicMaterialOptions}
                                    series={generateOrganicMaterialSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Energy Use</SummaryGraphTitle>
                                <Chart
                                    options={energyUseOptions}
                                    series={generateEnergyUseSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                        </SummaryStatRow>
                        <SummaryStatRow>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Horse Transport</SummaryGraphTitle>
                                <Chart
                                    options={horseTransportOptions}
                                    series={generateHorseTransportSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                            <SummaryGraphContainer>
                                <SummaryGraphTitle>Inputs Transport</SummaryGraphTitle>
                                <Chart
                                    options={inputTransportOptions}
                                    series={generateInputsTransportSeries()}
                                    type="boxPlot"
                                    height={350}
                                />
                            </SummaryGraphContainer>
                        </SummaryStatRow>
                    </>
                )}
            </InputContainer>}
        </>
    )
}

export default Benchmarking