import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, InputMainHeadingContainer, Select } from '../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../styles/Button.styled';
import { AccountingPeriodLabel } from '../styles/Label.styled';
import { IFootPrintMetaValues, IHoldingValues } from '../../features/footprint/holdingSlice.interfaces';
import { IFootprintErrors, IHoldingInformationErrors } from './Footprint.interfaces';
import { IDropdownValue } from '../../features/operations/operationsSlice.interfaces';
import { useNavigate } from 'react-router-dom';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling
import { useAppSelector } from '../../hooks';

type Props = {
    initialValues?: IFootPrintMetaValues,
    submitHandler: (val: IFootPrintMetaValues) => void,
    dropdowns?: IDropdownValue[]
    submitText: string
}

const initialValues = {
    assessmentType: '',
    assessmentReference: '',
    accountingPeriodEndMonth: 0,
    accountingPeriodEndYear: 0,
}

let listOfYears: number[] = []
let currentYear = new Date().getFullYear();
for (var i = 2; i > -5; i--) {
    listOfYears.push(currentYear - i);
}


const FootprintForm = (props: Props) => {

    const { footprint } = useAppSelector(state => state.footprint)
    const { user } = useAppSelector(state => state.auth)

    const navigate = useNavigate();

    const dropdowns = props.dropdowns;

    tippy('#assessment-reference', {
        content: "Name your footprints with a memorable name, especially if you are creating scenarios, so that you know which one is which.",
    });
    tippy('#assessment-type', {
        content: "Baseline assessment – this should be selected if it is the first time an assessment has been carried out for a holding. Follow-up assessment – where a baseline assessment has been carried out, any future annual assessments should be created as follow up assessments. Scenario testing – where a user has completed a baseline assessment and then wants to test what if scenarios, to see what would happen if they changed certain inputs or practices they can select scenario testing.",
    });
    tippy('#accounting-period', {
        content: "Each accounting period should be one year long.  The end of the period could be end of calendar year, end of the financial year (can be easier to get data from accounts), or possibly start of foaling (as this can make it easier to calculate how many horses are present for how long).",
    });

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    return (
        <div>
            <Formik
                initialValues={props.initialValues ? { ...props.initialValues } : initialValues}
                validate={values => {
                    const errors: IFootprintErrors = {};
                    if (!values.assessmentReference) {
                        errors.assessmentReference = 'Required';
                    }
                    if (!values.assessmentType) {
                        errors.assessmentType = 'Required';
                    }
                    if (!values.accountingPeriodEndMonth || values.accountingPeriodEndMonth === 0) {
                        errors.accountingPeriodEndMonth = 'Required';
                    }
                    if (!values.accountingPeriodEndYear || values.accountingPeriodEndYear === 0) {
                        errors.accountingPeriodEndYear = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        console.log(values)
                        props.submitHandler(values)
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (

                    <InputForm onSubmit={handleSubmit}>

                        <InputFormDescription>
                            This page is used to set up the footprint assessments for your farm.  The footprint will be completed for a one year period (this could start from the beginning of January, the start of foaling or align with your financial year, whichever is easiest for data entry).  The first footprint you create is the baseline assessment, this is the footprint that you use to understand where your main emission sources are, and start to create a plan to address this will be used in Benchmarking.  Subsequently you can create scenarios - where you test 'what ifs' so using your baseline data you can then see what happens if you change practice. In future years you can create follow up assessments to monitor progress over time.

                        </InputFormDescription>

                        <StandardInputGroup>
                            <label>
                                Assessment Reference
                            </label>

                            <div className='d-flex align-items-center'>
                                <div className='mx-2'>
                                    <Input
                                        name="assessmentReference"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.assessmentReference}
                                        className='form-control'
                                        data-role='none'
                                        disabled={inputIsLocked()}
                                    />
                                    <ErrorText>{errors.assessmentReference && touched.assessmentReference && errors.assessmentReference}</ErrorText>
                                </div>
                                <UtilityButton id='assessment-reference' src="/icons/tooltip.png" alt='Tooltip' />
                            </div>
                        </StandardInputGroup>

                        <StandardInputGroup>
                            <label>
                                Assessment Type
                            </label>

                            <div className='d-flex align-items-center'>
                                <div className='mx-2'>
                                    <Select
                                        name="assessmentType"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.assessmentType}
                                        className='form-control'
                                        data-role='none'
                                        disabled={inputIsLocked()}
                                    >
                                        <option value="">--Please choose an option--</option>
                                        {dropdowns?.filter(element => element.theme === "AssessmentType").map(value => {
                                            return <option key={value.key} value={value.key}>{value.description}</option>
                                        })}
                                    </Select>
                                    <ErrorText>{errors.assessmentType && touched.assessmentType && errors.assessmentType}</ErrorText>
                                </div>
                                <UtilityButton id='assessment-type' src="/icons/tooltip.png" alt='Tooltip' />
                            </div>
                        </StandardInputGroup>

                        <StandardInputGroup>
                            <label>
                                End date of accounting period
                            </label>

                            <div className='d-flex align-items-center ml-2'>
                                <AccountingPeriodLabel>
                                    Month
                                </AccountingPeriodLabel>
                                <div className='mx-2'>
                                    <select
                                        name="accountingPeriodEndMonth"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.accountingPeriodEndMonth}
                                        className='form-control'
                                        data-role='none'
                                        disabled={inputIsLocked()}
                                    >
                                        <option value={0}>--</option>
                                        <option value={1}>1</option>
                                        <option value={2}>2</option>
                                        <option value={3}>3</option>
                                        <option value={4}>4</option>
                                        <option value={5}>5</option>
                                        <option value={6}>6</option>
                                        <option value={7}>7</option>
                                        <option value={8}>8</option>
                                        <option value={9}>9</option>
                                        <option value={10}>10</option>
                                        <option value={11}>11</option>
                                        <option value={12}>12</option>
                                    </select>
                                    <ErrorText>{errors.accountingPeriodEndMonth && touched.accountingPeriodEndMonth && errors.accountingPeriodEndMonth}</ErrorText>
                                </div>
                                <AccountingPeriodLabel>
                                    Year
                                </AccountingPeriodLabel>
                                <div className='mx-2'>
                                    <select
                                        name="accountingPeriodEndYear"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.accountingPeriodEndYear}
                                        className='form-control'
                                        data-role='none'
                                        disabled={inputIsLocked()}
                                    >
                                        <option value={0}>--</option>
                                        {listOfYears.map(element => {
                                            return <option key={element} value={element}>{element}</option>
                                        })}
                                    </select>
                                    <ErrorText>{errors.accountingPeriodEndYear && touched.accountingPeriodEndYear && errors.accountingPeriodEndYear}</ErrorText>
                                </div>
                                <UtilityButton id='accounting-period' src="/icons/tooltip.png" alt='Tooltip' />
                            </div>
                        </StandardInputGroup>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton onClick={() => navigate("/holdings")}>
                                    Back
                                </InputFormButton>

                                <InputFormSaveButton type="submit" disabled={isSubmitting}>
                                    {props.submitText}
                                </InputFormSaveButton>
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>

        </div>
    )
}

export default FootprintForm