import React from 'react'
import { CheckMark, ProgressContainer, StepContainer, StepCount, StepLabel, StepStyle, StepWrapper, StepsLabelContainer } from '../styles/ProgressSteps.styled'
import { useNavigate } from 'react-router-dom'

type Props = {
    activeStep: number,
    setActiveStep: (a: number) => void
    isWide: boolean,
}

const mobileSteps = [
    {
        label: 'Land Use',
        step: 1,
        route: '/input/landUse'
    },
    {
        label: 'Horses',
        step: 2,
        route: '/input/horses'
    },
    {
        label: 'Feed & Bedding',
        step: 3,
        route: '/input/purchasedFeedForages'
    },
    {
        label: 'Energy Use',
        step: 4,
        route: '/input/electricity'
    },
    {
        label: 'Horse Transport',
        step: 5,
        route: '/input/horseTransport'
    }
]

const wideSteps = [
    {
        label: 'Land Use',
        step: 1,
        route: '/input/landUse'
    },
    {
        label: 'Horses',
        step: 2,
        route: '/input/horses'
    },
    {
        label: 'Manure Management',
        step: 3,
        route: '/input/manureManagement'
    },
    {
        label: 'Feed & Bedding',
        step: 4,
        route: '/input/purchasedFeedForages'
    },
    {
        label: 'Energy Use',
        step: 5,
        route: '/input/electricity'
    },
    {
        label: 'Horse Transport',
        step: 6,
        route: '/input/horseTransport'
    }
]

const ProgressSteps = (props: Props) => {

    const selectedSteps = props.isWide ? wideSteps : mobileSteps;

    const navigate = useNavigate();

    const totalSteps = selectedSteps.length;

    const onStepClickHandler = (step: number, route: string) => {
        navigate(route);
        props.setActiveStep(step);
    }

    const width = `${(100 / (totalSteps - 1)) * (props.activeStep - 1)}%`

    return (
        <ProgressContainer isWide={props.isWide}>
            <StepContainer width={width}>
                {selectedSteps.map(({ step, label, route }) => (
                    <StepWrapper onClick={() => onStepClickHandler(step, route)} key={step}>
                        <StepStyle step={props.activeStep >= step ? 'completed' : 'incomplete'}>
                            {props.activeStep > step ? (
                                <CheckMark>L</CheckMark>
                            ) : (
                                <StepCount>{step}</StepCount>
                            )}
                        </StepStyle>
                        <StepsLabelContainer>
                            <StepLabel key={step}>{label}</StepLabel>
                        </StepsLabelContainer>
                    </StepWrapper>
                ))}
            </StepContainer>
        </ProgressContainer>
    )
}

export default ProgressSteps