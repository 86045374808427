import styled from "styled-components"


export const ProgressContainer = styled.div<{ isWide: boolean }>`
    display: ${(props) => props.isWide ? "block" : "none"};
    width: 100%;
    max-width: 80%;
    margin: 0 auto;
    padding: 0 15px;
    @media (max-width: 992px) {
        display: ${(props) => props.isWide ? "none" : "block"};
    }
    @media (max-width: 1200px) {
        max-width: 100%;
    }
`;

export const StepContainer = styled.div<{ width: string }>`
    display: flex;
    justify-content: space-between;
    margin-top: 35px;
    margin-bottom: 5rem;
    position: relative;
    cursor: pointer;
    :before {
        content: '';
        position: absolute;
        background: #E2E2F3;
        height: 4px;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        left: 0;
    }
    :after {
        content: '';
        position: absolute;
        background: #23254F;
        height: 4px;
        width: ${(props) => props.width};
        top: 50%;
        transition: 0.4s ease;
        transform: translateY(-50%);
        left: 0;
    }
`;

export const StepWrapper = styled.div`
    position: relative;
    z-index: 1;
`;

export const StepStyle = styled.div<{ step: string }>`
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background-color: #fff;
    border: 3px solid 
        ${({ step }) => (step === 'completed' ? '#23254F' : '#E2E2F3')};
    transition: 0.4s ease;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const CheckMark = styled.div`
    font-size: 26px;
    font-weight: 600;
    color: #23254F;
    -ms-transform: scaleX(-1) rotate(-46deg); /* IE 9 */
    -webkit-transform: scaleX(-1) rotate(-46deg); /* Chrome, Safari, Opera */
    transform: scaleX(-1) rotate(-46deg);
`;

export const StepCount = styled.span`
    font-size: 14px;
    color: #E2E2F3;
`;

export const StepsLabelContainer = styled.div`
    position: absolute;
    top: 66px;
    left: 50%;
    transform: translate(-50%, -50%);
`;

export const StepLabel = styled.span`
    font-size: 12px;
    color: #23254F;
`;
