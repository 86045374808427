import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify';
import { AuthForm, AuthFormLink, AuthFormTitle, StyledAuthFormLink } from '../styles/Form.styled';
import { AuthInput } from '../styles/Input.styled';
import { AuthButton } from '../styles/Button.styled';
import { AuthSpan, AuthSvg } from '../styles/Span.styled';
import { AuthInputContainer } from '../styles/Container.styled';
import { clearErrors, clearMessage, loginUser, resendConfirmationEmail } from '../../features/auth/authSlice'

type Props = {}

const Login = (props: Props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [rememberMe, setRememberMe] = useState(false);

    const { isAuthenticated, user, loading, message, errors } = useAppSelector((state) => state.auth);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        if (isAuthenticated) {
            navigate("/");
            toast.dismiss();
            toast.success("Login successful");
        }

        if (message) {
            navigate("/");
            toast.dismiss();
            toast.success(message);
            dispatch(clearMessage());
        }

        if (typeof errors === 'string') {
            toast.dismiss();
            toast.error(errors);
            dispatch(clearErrors());
        }
    }, [dispatch, errors, isAuthenticated, message, navigate])

    const togglePassword = () => {
        var x = document.getElementById("password") as HTMLInputElement | null;
        if (x != null) {
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        }
    }

    const resendConfirmEmail = () => {
        if (email === "") {
            toast.error("Please enter your email and try again")
            return;
        }

        toast.info("Sending confirmation...", {
            autoClose: false,
        });
        dispatch(resendConfirmationEmail({email}));
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        if (email === "") {
            toast.error("Enter your email")
            return
        }

        if (password === "") {
            toast.error("Enter your password")
            return
        }

        toast.info("Logging in...", {
            autoClose: false,
        });
        dispatch(loginUser({ email, password, rememberMe }))
    }


    return (
        <>
            <div>
                <AuthForm>
                    <AuthFormTitle>Sign in to your account</AuthFormTitle>
                    <AuthInputContainer>
                        <AuthInput placeholder="Enter email" data-role="none" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <AuthSpan>
                            <AuthSvg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                            </AuthSvg>
                        </AuthSpan>
                    </AuthInputContainer>
                    <AuthInputContainer>
                        <AuthInput placeholder="Enter password" data-role="none" type="password" value={password} onChange={(e) => setPassword(e.target.value)} id='password' />
                        <AuthSpan onClick={togglePassword} style={{ cursor: "pointer" }}>
                            <AuthSvg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                                <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                            </AuthSvg>
                        </AuthSpan>
                    </AuthInputContainer>
                    <div className='d-flex justify-content-between align-items-center'>
                        <label htmlFor='rememberMe'><small>Remember my account details?</small></label>
                        <input id='rememberMe' type="checkbox" data-role='none' checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />
                    </div>
                    <AuthButton type="submit" onClick={submitHandler}>
                        Sign in
                    </AuthButton>

                    <AuthFormLink className='mb-0'>
                        Forgotten password?
                        <StyledAuthFormLink to={'/forgot-password'}>   Reset password</StyledAuthFormLink>
                    </AuthFormLink>

                    <AuthFormLink>
                        No account?
                        <StyledAuthFormLink to={'/register'}>  Register</StyledAuthFormLink>
                    </AuthFormLink>

                    <AuthFormLink>
                        Need a new confirmation link?
                        <StyledAuthFormLink to={'#'} onClick={resendConfirmEmail}>  Resend Email</StyledAuthFormLink>
                    </AuthFormLink>

                </AuthForm>

            </div>
        </>
    )
}

export default Login