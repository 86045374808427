import React, { FormEvent, useEffect, useState } from 'react';
import { FormikErrors, FormikTouched, useField } from "formik";
import { InputSubContainerRow, InputSubContainer } from '../../styles/Container.styled';
import { UtilityButton } from '../../styles/Button.styled';
import { ErrorText } from '../../styles/Form.styled';
import { ILandUse } from '../Input.interface';
import { Select } from '../../styles/Input.styled';
import { useAppSelector } from '../../../hooks';
import tippy from 'tippy.js';

type Props = {
    index: number,
    errors: any,
    touched: any,
    deleteRecordHandler: (a: string) => void,
    inputIsLocked: () => boolean
}

const HorseTransportRecord = (props: Props) => {

    // binding record properties using index
    const [transportTypeField, transportTypeMeta, transportTypeHelpers] = useField(`horseTransports[${props.index}].transportType`);
    const [roadTransportField, roadTransportMeta, roadTransportHelpers] = useField(`horseTransports[${props.index}].roadTransport`);
    const [numOfHorsesTransportedLocalField, numOfHorsesTransportedLocalMeta, numOfHorsesTransportedLocalHelpers] = useField(`horseTransports[${props.index}].numOfHorsesTransportedLocal`);
    const [numOfHorsesTransportedRegionalField, numOfHorsesTransportedRegionalMeta, numOfHorsesTransportedRegionalHelpers] = useField(`horseTransports[${props.index}].numOfHorsesTransportedRegional`);
    const [numOfHorsesTransportedNationalField, numOfHorsesTransportedNationalMeta, numOfHorsesTransportedNationalHelpers] = useField(`horseTransports[${props.index}].numOfHorsesTransportedNational`);
    const [numOfHorsesTransportedOverseasUpToFiveHundredKMField, numOfHorsesTransportedOverseasUpToFiveHundredKMMeta, numOfHorsesTransportedOverseasUpToFiveHundredKMHelpers] = useField(`horseTransports[${props.index}].numOfHorsesTransportedOverseasUpToFiveHundredKM`);
    const [numOfHorsesTransportedOverseasUpToOneThousandKMField, numOfHorsesTransportedOverseasUpToOneThousandKMMeta, numOfHorsesTransportedOverseasUpToOneThousandKMHelpers] = useField(`horseTransports[${props.index}].numOfHorsesTransportedOverseasUpToOneThousandKM`);
    const [otherTransportField, otherTransportMeta, otherTransportHelpers] = useField(`horseTransports[${props.index}].otherTransport`);
    const [locationField, locationMeta, locationHelpers] = useField(`horseTransports[${props.index}].location`);
    const [otherLocationField, otherLocationMeta, otherLocationHelpers] = useField(`horseTransports[${props.index}].otherLocation`);
    const [otherNumOfHorsesTransportedField, otherNumOfHorsesTransportedMeta, otherNumOfHorsesTransportedHelpers] = useField(`horseTransports[${props.index}].otherNumOfHorsesTransported`);
    const [idField, idMeta, idHelpers] = useField(`horseTransports[${props.index}].id`);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    const [showHorseTransportInfo, setShowHorseTransportInfo] = useState(false);

    useEffect(() => {
        if (errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index]) setShowHorseTransportInfo(true);

        tippy('.transport-type-tooltip', {
            content: "Select the most compatible option for the transport type from the dropdown.",
        });
    
        tippy('.road-transport-tooltip', {
            content: "Select the most compatible option for the transport vehicle type from the dropdown.",
        });
        tippy('.other-transport-tooltip', {
            content: "Select the most compatible option for the transport vehicle type from the dropdown.",
        });
        tippy('.other-location-tooltip', {
            content: "Select the most compatible option for the transport location from the dropdown. If the desired transport route is not available in the dropdown, please select the most relevant from the options present.",
        });
        tippy('.other-number-tooltip', {
            content: "Enter the number of horses transported by this mode of transport to the selected location.",
        });
    
        tippy('.road-local-tooltip', {
            content: "Enter the number of horses transported by this method for this distance.",
        });
        tippy('.road-regional-tooltip', {
            content: "Enter the number of horses transported by this method for this distance.",
        });
        tippy('.road-national-tooltip', {
            content: "Enter the number of horses transported by this method for this distance.",
        });
        tippy('.road-overseas-1-tooltip', {
            content: "Enter the number of horses transported by this method for this distance.",
        });
        tippy('.road-overseas-2-tooltip', {
            content: "Enter the number of horses transported by this method for this distance.",
        });
    }, [errors.horseTransports, props.index, touched.horseTransports, showHorseTransportInfo])

    const onTransportTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        transportTypeHelpers.setValue(target.value);
    };
    const onTransportTypeBlur = () => {
        transportTypeHelpers.setTouched(true);
    };

    const onRoadTransportInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        roadTransportHelpers.setValue(target.value);
    };
    const onRoadTransportBlur = () => {
        roadTransportHelpers.setTouched(true);
    };

    const onNumOfHorsesTransportedLocalInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        numOfHorsesTransportedLocalHelpers.setValue(target.value);
    };
    const onNumOfHorsesTransportedLocalBlur = () => {
        numOfHorsesTransportedLocalHelpers.setTouched(true);
    };

    const onNumOfHorsesTransportedRegionalInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        numOfHorsesTransportedRegionalHelpers.setValue(target.value);
    };
    const onNumOfHorsesTransportedRegionalBlur = () => {
        numOfHorsesTransportedRegionalHelpers.setTouched(true);
    };

    const onNumOfHorsesTransportedNationalInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        numOfHorsesTransportedNationalHelpers.setValue(target.value);
    };
    const onNumOfHorsesTransportedNationalBlur = () => {
        numOfHorsesTransportedNationalHelpers.setTouched(true);
    };

    const onNumOfHorsesTransportedOverseasUpToFiveHundredKMInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        numOfHorsesTransportedOverseasUpToFiveHundredKMHelpers.setValue(target.value);
    };
    const onNumOfHorsesTransportedOverseasUpToFiveHundredKMBlur = () => {
        numOfHorsesTransportedOverseasUpToFiveHundredKMHelpers.setTouched(true);
    };

    const onNumOfHorsesTransportedOverseasUpToOneThousandKMInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        numOfHorsesTransportedOverseasUpToOneThousandKMHelpers.setValue(target.value);
    };
    const onNumOfHorsesTransportedOverseasUpToOneThousandKMBlur = () => {
        numOfHorsesTransportedOverseasUpToOneThousandKMHelpers.setTouched(true);
    };

    const onOtherTransportInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        otherTransportHelpers.setValue(target.value);
    };
    const onOtherTransportBlur = () => {
        otherTransportHelpers.setTouched(true);
    };

    const onLocationInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        locationHelpers.setValue(target.value);
    };
    const onLocationBlur = () => {
        locationHelpers.setTouched(true);
    };

    const onOtherLocationInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        otherLocationHelpers.setValue(target.value);
    };
    const onOtherLocationBlur = () => {
        otherLocationHelpers.setTouched(true);
    };

    const onOtherNumOfHorsesTransportedInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        otherNumOfHorsesTransportedHelpers.setValue(target.value);
    };
    const onOtherNumOfHorsesTransportedBlur = () => {
        otherNumOfHorsesTransportedHelpers.setTouched(true);
    };

    const toggleHorseTransportInfo = () => {
        setShowHorseTransportInfo(!showHorseTransportInfo);
    }

    // console.log(errors)

    return (
        <>
            <InputSubContainer>
                <InputSubContainerRow>
                    <div>
                        <label>Transport Type</label>
                    </div>

                    <div>
                        <select
                            className='form-control'
                            data-role='none'
                            value={transportTypeField.value}
                            onChange={onTransportTypeInput}
                            onBlur={onTransportTypeBlur}
                            disabled={props.inputIsLocked()}
                        >
                            <option value="">--Please choose an option--</option>
                            <option value="Road">Road</option>
                            <option value="Other">Other</option>
                        </select>
                        <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].transportType && touched.horseTransports[props.index].transportType && errors.horseTransports[props.index].transportType}</ErrorText>
                    </div>

                    <div>
                        <UtilityButton className='transport-type-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        {showHorseTransportInfo ? (
                            <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleHorseTransportInfo} />) : (
                            <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleHorseTransportInfo} />
                        )}
                        <UtilityButton src="/icons/delete.png" alt='tooltip' onClick={() => props.deleteRecordHandler(idField.value)} />
                    </div>

                </InputSubContainerRow>

                {showHorseTransportInfo && transportTypeField.value === "Road" && (
                    <InputSubContainer>
                        <InputSubContainerRow>
                            <div>
                                <label>Road Transport</label>
                            </div>

                            <div>
                                <select
                                    className='form-control'
                                    data-role='none'
                                    value={roadTransportField.value}
                                    onChange={onRoadTransportInput}
                                    onBlur={onRoadTransportBlur}
                                    disabled={props.inputIsLocked()}
                                >
                                    <option value="">--Please choose an option--</option>
                                    {dropdowns?.filter(element => element.theme === "HorseTransportVehicle" && element.key !== "OtherTransport").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                                <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].roadTransport && touched.horseTransports[props.index].roadTransport && errors.horseTransports[props.index].roadTransport}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='road-transport-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        <InputSubContainerRow>
                            <div>
                                <label>Local ({'<'}15km)</label>
                            </div>

                            <div>
                                <input
                                    type="text"
                                    className='form-control'
                                    data-role='none'
                                    value={numOfHorsesTransportedLocalField.value}
                                    onChange={onNumOfHorsesTransportedLocalInput}
                                    onBlur={onNumOfHorsesTransportedLocalBlur}
                                    disabled={props.inputIsLocked()}
                                />
                                <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].numOfHorsesTransportedLocal && touched.horseTransports[props.index].numOfHorsesTransportedLocal && errors.horseTransports[props.index].numOfHorsesTransportedLocal}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='road-local-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        <InputSubContainerRow>
                            <div>
                                <label>Regional (15-75km)</label>
                            </div>

                            <div>
                                <input
                                    type="text"
                                    className='form-control'
                                    data-role='none'
                                    value={numOfHorsesTransportedRegionalField.value}
                                    onChange={onNumOfHorsesTransportedRegionalInput}
                                    onBlur={onNumOfHorsesTransportedRegionalBlur}
                                    disabled={props.inputIsLocked()}
                                />
                                <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].numOfHorsesTransportedRegional && touched.horseTransports[props.index].numOfHorsesTransportedRegional && errors.horseTransports[props.index].numOfHorsesTransportedRegional}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='road-regional-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        <InputSubContainerRow>
                            <div>
                                <label>National (75-250km)</label>
                            </div>

                            <div>
                                <input
                                    type="text"
                                    className='form-control'
                                    data-role='none'
                                    value={numOfHorsesTransportedNationalField.value}
                                    onChange={onNumOfHorsesTransportedNationalInput}
                                    onBlur={onNumOfHorsesTransportedNationalBlur}
                                    disabled={props.inputIsLocked()}
                                />
                                <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].numOfHorsesTransportedNational && touched.horseTransports[props.index].numOfHorsesTransportedNational && errors.horseTransports[props.index].numOfHorsesTransportedNational}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='road-national-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        <InputSubContainerRow>
                            <div>
                                <label>Overseas by road (250-500km)</label>
                            </div>

                            <div>
                                <input
                                    type="text"
                                    className='form-control'
                                    data-role='none'
                                    value={numOfHorsesTransportedOverseasUpToFiveHundredKMField.value}
                                    onChange={onNumOfHorsesTransportedOverseasUpToFiveHundredKMInput}
                                    onBlur={onNumOfHorsesTransportedOverseasUpToFiveHundredKMBlur}
                                    disabled={props.inputIsLocked()}
                                />
                                <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].numOfHorsesTransportedOverseasUpToFiveHundredKM && touched.horseTransports[props.index].numOfHorsesTransportedOverseasUpToFiveHundredKM && errors.horseTransports[props.index].numOfHorsesTransportedOverseasUpToFiveHundredKM}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='road-overseas-1-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        <InputSubContainerRow>
                            <div>
                                <label>Overseas by road (500-1000km)</label>
                            </div>

                            <div>
                                <input
                                    type="text"
                                    className='form-control'
                                    data-role='none'
                                    value={numOfHorsesTransportedOverseasUpToOneThousandKMField.value}
                                    onChange={onNumOfHorsesTransportedOverseasUpToOneThousandKMInput}
                                    onBlur={onNumOfHorsesTransportedOverseasUpToOneThousandKMBlur}
                                    disabled={props.inputIsLocked()}
                                />
                                <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].numOfHorsesTransportedOverseasUpToOneThousandKM && touched.horseTransports[props.index].numOfHorsesTransportedOverseasUpToOneThousandKM && errors.horseTransports[props.index].numOfHorsesTransportedOverseasUpToOneThousandKM}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='road-overseas-2-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                    </InputSubContainer>
                )}

                {showHorseTransportInfo && transportTypeField.value === "Other" && (
                    <InputSubContainer>
                        <InputSubContainerRow>
                            <div>
                                <label>Transport</label>
                            </div>

                            <div>
                                <select
                                    className='form-control'
                                    data-role='none'
                                    value={otherTransportField.value}
                                    onChange={onOtherTransportInput}
                                    onBlur={onOtherTransportBlur}
                                    disabled={props.inputIsLocked()}
                                >
                                    <option value="">--Please choose an option--</option>
                                    {dropdowns?.filter(element => element.theme === "OtherTransport").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                                <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].otherTransport && touched.horseTransports[props.index].otherTransport && errors.horseTransports[props.index].otherTransport}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='other-transport-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        <InputSubContainerRow>
                            <div>
                                <label>Location</label>
                            </div>

                            <div>
                                <select
                                    className='form-control'
                                    data-role='none'
                                    value={locationField.value}
                                    onChange={onLocationInput}
                                    onBlur={onLocationBlur}
                                    disabled={props.inputIsLocked()}
                                >
                                    <option value="">--Please choose an option--</option>
                                    {/* TODO: check for flight/ferry */}
                                    {otherTransportField.value === "Flight" && dropdowns?.filter(element => element.theme === "FlightLocation" && element.description !== "Other").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                    {otherTransportField.value === "Ferry" && dropdowns?.filter(element => element.theme === "FerryLocation" && element.description !== "Other").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                                <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].location && touched.horseTransports[props.index].location && errors.horseTransports[props.index].location}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='other-location-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        {locationField.value === "Other" && (
                            <InputSubContainerRow>
                                <div>
                                    <label>Please specify location</label>
                                </div>

                                <div>
                                    <input
                                        type="text"
                                        className='form-control'
                                        data-role='none'
                                        value={otherLocationField.value}
                                        onChange={onOtherLocationInput}
                                        onBlur={onOtherLocationBlur}
                                        disabled={props.inputIsLocked()}
                                    />
                                    <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].otherLocation && touched.horseTransports[props.index].otherLocation && errors.horseTransports[props.index].otherLocation}</ErrorText>
                                </div>

                                {/* <div>
                                    <UtilityButton className='other-transport-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                                </div> */}

                            </InputSubContainerRow>
                        )}
                        <InputSubContainerRow>
                            <div>
                                <label>Number of horses</label>
                            </div>

                            <div>
                                <input
                                    type="text"
                                    className='form-control'
                                    data-role='none'
                                    value={otherNumOfHorsesTransportedField.value}
                                    onChange={onOtherNumOfHorsesTransportedInput}
                                    onBlur={onOtherNumOfHorsesTransportedBlur}
                                    disabled={props.inputIsLocked()}
                                />
                                <ErrorText>{errors.horseTransports && errors.horseTransports[props.index] && touched.horseTransports && touched.horseTransports[props.index] && errors.horseTransports[props.index].otherNumOfHorsesTransported && touched.horseTransports[props.index].otherNumOfHorsesTransported && errors.horseTransports[props.index].otherNumOfHorsesTransported}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='other-number-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                    </InputSubContainer>
                )}



            </InputSubContainer>
        </>
    )
}

export default HorseTransportRecord