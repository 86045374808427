import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { toast } from 'react-toastify';
import { HeaderCenterSection, HeaderContainer, HeaderLayout, HeaderLinks, HeaderMobileMenuSection, HeaderNav, HeaderNavButton, HeaderNavLogOutButton, HeaderWideMenuSection, LogoImg, StyledHeader, StyledLink } from '../styles/Header.styled';
import { SideBarNavElements } from '../styles/Container.styled';
import { logoutUser } from '../../features/auth/authSlice';

type Props = {}

const Header = (props: Props) => {

    const [showSideBar, setShowSideBar] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const handleShowSideBar = () => {
        setShowSideBar(!showSideBar);
    }

    const { user, isAuthenticated } = useAppSelector((state) => state.auth);

    useEffect(() => {

    }, [user, user?.userRoles])

    const logoutHandler = () => {
        dispatch(logoutUser());
        toast.info("Logout successful");
        navigate('/')
    }

    const isAdmin = () => {
        return user?.userRoles === "Admin" || user?.roles?.includes("Admin");
    }

    return (
        <>
            <StyledHeader>
                <HeaderContainer>
                    <HeaderLayout>
                        <HeaderNav>
                            {/* collapsible menu that shows on mobile screens */}

                            <HeaderCenterSection className='col-auto'>
                                <StyledLink to={'/'}>
                                    <LogoImg src='/assets/TBA_logo_landscape.png' alt='Logo' />
                                </StyledLink>
                            </HeaderCenterSection>

                            <HeaderMobileMenuSection className="navbar navbar-expand-lg navbar-light">
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#header-collapse" aria-controls="header-collapse" aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>

                                <div className="collapse navbar-collapse" id="header-collapse">
                                    <ul className="navbar-nav mt-2 mt-lg-0">
                                        {!isAuthenticated ? (
                                            <>
                                                <StyledLink to={'/register'}>
                                                    Register
                                                </StyledLink>
                                                <StyledLink to={'/login'}>
                                                    Login
                                                </StyledLink>
                                            </>
                                        ) : (
                                            <>
                                                {isAdmin() && (
                                                    <StyledLink to={'/admin/users'}>
                                                        Users
                                                    </StyledLink>
                                                )}
                                                {!isAdmin() ? (
                                                    <StyledLink to={'/holdings'}>
                                                        Holdings
                                                    </StyledLink>
                                                ) : (
                                                    <StyledLink to={'/admin/holdings'}>
                                                        Holdings
                                                    </StyledLink>
                                                )}
                                                {isAdmin() && (
                                                    <>
                                                        <StyledLink to={'/admin/summary'}>
                                                            Summary
                                                        </StyledLink>
                                                        <StyledLink to={'/benchmarking'}>
                                                            Benchmarking
                                                        </StyledLink>
                                                    </>
                                                )}

                                                <li>
                                                    <StyledLink to={"/user/profile"} className="py-1 px-1 ml-2 border-none cursor-pointer">
                                                        My Profile
                                                    </StyledLink>
                                                </li>
                                                <HeaderNavLogOutButton onClick={logoutHandler}>
                                                    Log out
                                                </HeaderNavLogOutButton>
                                            </>
                                        )}

                                    </ul>
                                </div>
                            </HeaderMobileMenuSection>

                            <HeaderWideMenuSection>
                                <HeaderLinks>
                                    {/* TODO: switch to holdings and logout if authenticated */}
                                    {!isAuthenticated ? (
                                        <>
                                            <HeaderNavButton onClick={() => navigate("/register")}>
                                                Register
                                            </HeaderNavButton>
                                            <StyledLink to={'/login'}>
                                                Login
                                            </StyledLink>
                                        </>
                                    ) : (
                                        <>
                                            {!isAdmin() ? (
                                                <StyledLink to={'/holdings'}>
                                                    Holdings
                                                </StyledLink>
                                            ) : (
                                                <StyledLink to={'/admin/holdings'}>
                                                    Holdings
                                                </StyledLink>
                                            )}
                                            {isAdmin() && (
                                                <>
                                                    <StyledLink to={'/admin/summary'}>
                                                        Summary
                                                    </StyledLink>
                                                    <StyledLink to={'/benchmarking'}>
                                                        Benchmarking
                                                    </StyledLink>
                                                </>
                                            )}

                                            <div className="dropdown">
                                                <button className="btn btn-primary" style={{ background: "#1fb6e8", border: "none" }} type="button" id="myAccountDropdownMenuButton" data-bs-toggle="dropdown" aria-expanded="false">
                                                    My Account
                                                </button>
                                                <ul className="dropdown-menu" aria-labelledby="myAccountDropdownMenuButton">
                                                    {isAdmin() && (
                                                        <li>
                                                            <StyledLink to={'/admin/users'} className="py-1 px-1 ml-2 border-none cursor-pointer">
                                                                Users
                                                            </StyledLink>
                                                        </li>
                                                    )}
                                                    <li>
                                                        <StyledLink to={"/user/profile"} className="py-1 px-1 ml-2 border-none cursor-pointer">
                                                            My Profile
                                                        </StyledLink>
                                                    </li>
                                                    <li>
                                                        <StyledLink to={"#"} onClick={logoutHandler} className="py-1 px-1 ml-2 border-none cursor-pointer">
                                                            Log out
                                                        </StyledLink>
                                                        {/* <HeaderNavLogOutButton onClick={logoutHandler}>
                                                            Log out
                                                        </HeaderNavLogOutButton> */}
                                                    </li>
                                                </ul>
                                            </div>

                                        </>
                                    )}

                                </HeaderLinks>
                            </HeaderWideMenuSection>
                        </HeaderNav>
                    </HeaderLayout>
                </HeaderContainer>
            </StyledHeader>
        </>
    )
}

export default Header