import React, { useEffect, useState } from 'react';
import { Formik, FieldArray } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../../styles/Button.styled';
import { LandUseInputsContainer } from '../../styles/Container.styled';
import { v4 as uuidv4 } from "uuid";
import { horseTransportValidationSchema } from '../ValidationSchemas';
import { IHorseTransport } from '../Input.interface';
import HorseTransportRecord from './HorseTransportRecord';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getDropdownValues } from '../../../features/operations/operationsSlice';
import { clearErrors, getElectricityAndFuelDataById, getFootprintById, putHorseTransportDetailsById, reset } from '../../../features/footprint/footprintSlice';
import { toast } from 'react-toastify';
import { IElectricityAndFuelData } from '../../../features/footprint/footprintSlice.interfaces';
import Loader from '../../layout/Loader';

type Props = {}

const HorseTransport = (props: Props) => {

    const [pulledData, setPulledData] = useState(false)
    const [nextPage, setNextPage] = useState(false)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();

    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, electricityAndFuel, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {
        setActiveWideStep(6);
        setActiveMobileStep(5);

        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getElectricityAndFuelDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Horse transport saved successfully");
            dispatch(reset())
            if (nextPage) navigate("/results");
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }

        dispatch(getDropdownValues({ section: "ElectricityAndFuelInput" }))

    }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const parseDataIntoForm = (horseTransportDetail:
        {
            "vehicleType": string,
            "animalsTransportedByRoad":
            {
                "distance": string,
                "numberOfAnimals": number
            }[],
            "animalsTransportedOther"?:
            {
                "otherTransportType": string,
                "location": string,
                "numberOfAnimals": number
            }
        }[]) => {

        var horseTransports = horseTransportDetail.map(element => {

            var local = element.animalsTransportedByRoad.find(element => element.distance === "Local_0_15km")
            var regional = element.animalsTransportedByRoad.find(element => element.distance === "Regional_16_75km")
            var national = element.animalsTransportedByRoad.find(element => element.distance === "National_75_250km")
            var overseasTo500 = element.animalsTransportedByRoad.find(element => element.distance === "OverseasByRoad_250_500km")
            var overseasTo1000 = element.animalsTransportedByRoad.find(element => element.distance === "OverseasByRoad_500_1000km")
            return {
                id: uuidv4(),
                transportType: element.animalsTransportedOther === null ? "Road" : "Other",
                roadTransport: element.vehicleType,
                numOfHorsesTransportedLocal: local?.numberOfAnimals ?? 0,
                numOfHorsesTransportedRegional: regional?.numberOfAnimals ?? 0,
                numOfHorsesTransportedNational: national?.numberOfAnimals ?? 0,
                numOfHorsesTransportedOverseasUpToFiveHundredKM: overseasTo500?.numberOfAnimals ?? 0,
                numOfHorsesTransportedOverseasUpToOneThousandKM: overseasTo1000?.numberOfAnimals ?? 0,
                otherTransport: element.animalsTransportedOther?.otherTransportType ?? "",
                location: element.animalsTransportedOther?.location ?? "",
                // otherLocation: element.animalsTransportedOther?.locationOther ?? "",
                otherNumOfHorsesTransported: element.animalsTransportedOther?.numberOfAnimals ?? 0,
            }
        })
        // console.log(horseTransports)

        return { horseTransports }
    }

    // console.log(parseDataIntoForm(electricityAndFuel?.horseTransport?.horseTransportDetail ?? []))



    return (
        <LandUseInputsContainer>
            <InputFormHeading>Electricity and fuel - horse transport</InputFormHeading>
            {loading ? <Loader /> : <Formik
                initialValues={parseDataIntoForm(electricityAndFuel?.horseTransport?.horseTransportDetail ?? [])}
                validationSchema={horseTransportValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 400);

                    var data = values.horseTransports.map((element) => {

                        var vehicleType = element.roadTransport

                        var roadData = []

                        if (element.numOfHorsesTransportedLocal > 0) roadData.push({
                            "distance": "Local_0_15km",
                            "numberOfAnimals": element.numOfHorsesTransportedLocal
                        })
                        if (element.numOfHorsesTransportedRegional > 0) roadData.push({
                            "distance": "Regional_16_75km",
                            "numberOfAnimals": element.numOfHorsesTransportedRegional
                        })
                        if (element.numOfHorsesTransportedNational > 0) roadData.push({
                            "distance": "National_75_250km",
                            "numberOfAnimals": element.numOfHorsesTransportedNational
                        })
                        if (element.numOfHorsesTransportedOverseasUpToFiveHundredKM > 0) roadData.push({
                            "distance": "OverseasByRoad_250_500km",
                            "numberOfAnimals": element.numOfHorsesTransportedOverseasUpToFiveHundredKM
                        })
                        if (element.numOfHorsesTransportedOverseasUpToOneThousandKM > 0) roadData.push({
                            "distance": "OverseasByRoad_500_1000km",
                            "numberOfAnimals": element.numOfHorsesTransportedOverseasUpToOneThousandKM
                        })

                        var otherData = undefined

                        if (element.transportType === "Other") {
                            vehicleType = "OtherTransport"
                            otherData = {
                                "otherTransportType": element.otherTransport,
                                "location": element.location,
                                "numberOfAnimals": element.otherNumOfHorsesTransported
                            }
                        }


                        return {
                            vehicleType,
                            animalsTransportedByRoad: roadData,
                            animalsTransportedOther: otherData
                        }
                    })

                    dispatch(putHorseTransportDetailsById(data))

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <InputForm onSubmit={handleSubmit}>

                        <InputFormDescription>
                            Please only include horse transport in this section where it has not already been captured in the previous pages (e.g. if you fuel your own horsebox from a tank on site or with purchased fuel from service stations it may have already been accounted for in the earlier data fields).  Please only record horse transport data for any horses arriving on the farm, whether temporary borders arriving or resident mares returning.  To streamline data entry please group horses together who travelled via similar methods of transport and distance. See the tutorial for this page by clicking <a href='https://www.youtube.com/watch?v=mbGc7_bsslk&list=PLxMZB77n3_Or44Hw2dGQ6LGR8Js3GSJ1I&index=6' target='_blank' rel="noreferrer">here</a>
                        </InputFormDescription>

                        <FieldArray name="horseTransports">
                            {({ form, ...fieldArrayHelpers }) => {
                                const onAddClick = () => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    fieldArrayHelpers.push({
                                        id: uuidv4(),
                                        transportType: "",
                                        roadTransport: "",
                                        numOfHorsesTransportedLocal: 0,
                                        numOfHorsesTransportedRegional: 0,
                                        numOfHorsesTransportedNational: 0,
                                        numOfHorsesTransportedOverseasUpToFiveHundredKM: 0,
                                        numOfHorsesTransportedOverseasUpToOneThousandKM: 0,
                                        otherTransport: "",
                                        location: "",
                                        otherLocation: "",
                                        otherNumOfHorsesTransported: 0
                                    });
                                };

                                const deleteRecordHandler = (id: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    form.setFieldValue(
                                        "horseTransports",
                                        form.values.horseTransports.filter((record: { id: string; }) => record.id !== id)
                                    );
                                }

                                return (
                                    <>
                                        <AddMainRecordButton src='/icons/plus.png' alt='Add main record' onClick={onAddClick} />
                                        {form.values.horseTransports.map((record: IHorseTransport['horseTransports'], index: number) => (
                                            <HorseTransportRecord
                                                index={index}
                                                key={index}
                                                deleteRecordHandler={deleteRecordHandler}
                                                errors={errors}
                                                touched={touched}
                                                inputIsLocked={inputIsLocked}
                                            />
                                        ))}
                                        {form.values.horseTransports.length === 0 &&
                                            <div>
                                                There are no horse transport records available. Click on the green "+" to add one.
                                            </div>
                                        }
                                    </>
                                );
                            }}
                        </FieldArray>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton>
                                    Back
                                </InputFormButton>

                                {inputIsLocked() ? (
                                    <>
                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Continue
                                        </InputFormSaveButton>
                                    </>
                                ) : (
                                    <>
                                        <InputFormButton disabled={isSubmitting}>
                                            Save
                                        </InputFormButton>

                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Save & Continue
                                        </InputFormSaveButton>
                                    </>
                                )}
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>}

        </LandUseInputsContainer>
    )
}

export default HorseTransport