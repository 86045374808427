import React from 'react';
import { HomeHeadingContainer, HomeLandingSection, HomeStartButtonWrapper, LandingPagePanel, LandingPagePanelText1, LandingPageSubPanel, LandingPageCardContainer, LandingPageCard } from './styles/Container.styled';
import { HomeStartButton } from './styles/Button.styled';
import { useNavigate } from 'react-router-dom'
import { LandingPageCardContainerImage, LandingPageLogo, WideLandingImage } from './styles/Img.styled';
import { url } from 'inspector';

type Props = {

}

const Home = (props: Props) => {

    window.onpopstate = () => {
        navigate('/');
    }

    const navigate = useNavigate();

    return (
        <div>
            {/* <HomeHeadingContainer>
                <h1>Stud Farm Carbon Calculator</h1>
            </HomeHeadingContainer> */}
            <LandingPagePanel style={{ backgroundImage: `url("/assets/stud-farm-4000+TINT.jpg")`, backgroundPosition: "center", backgroundSize: "cover" }}>

                <div className='d-flex justify-content-center align-items-center text-white'>
                    <div className=''>
                        <div>
                            <LandingPageLogo src='/assets/TBA_CarbonCalculator-logo.png' alt='calculator logo' />
                        </div>
                        <div>
                            <LandingPagePanelText1>Welcome to the TBA’s digital</LandingPagePanelText1> <br /><LandingPagePanelText1>Stud Farm Carbon Calculator</LandingPagePanelText1>
                        </div>

                        <HomeStartButtonWrapper>
                            <HomeStartButton onClick={() => navigate("/holdings")}>GET STARTED</HomeStartButton>
                        </HomeStartButtonWrapper>
                    </div>

                </div>

            </LandingPagePanel>

            <LandingPageSubPanel>
                <div>
                    We hope breeders find this tool beneficial in understanding their baseline carbon footprint and in modelling possible future scenarios to reduce their negative impact on the environment. A later release will also include anonymised benchmarking functionality, to compare the emissions of stud farms of a similar size and business emphasis.
                </div>
            </LandingPageSubPanel>

            <LandingPageCardContainer>
                <LandingPageCard className="card">
                    <LandingPageCardContainerImage className="card-img-top" height={300} src="/assets/A.SMY_TBAWhitsburyMS1608.jpg" alt="Card cap" />
                    <div className="card-body">
                        <h5 className="card-title" style={{ fontFamily: "AGaramondPro", fontWeight: "bolder" }}>Why?</h5>
                        <p className="card-text">The Climate Change Act (amended 2019) pledged to reduce all Greenhouse Gas (GHG) emissions to net zero by 2050 and if this is to be achieved, all individuals and businesses will have to progress towards carbon neutrality.  From 2024, all enterprises in EU member states will have to report annually on their carbon footprint.  The Stud Farm Carbon Calculator has been introduced to support British breeders in taking their first steps on this journey.</p>
                    </div>
                </LandingPageCard>
                <LandingPageCard className="card">
                    <LandingPageCardContainerImage className="card-img-top" src="/assets/A.SMY_2022AlneParkStud1456.jpg" alt="Card cap" />
                    <div className="card-body">
                        <h5 className="card-title" style={{ fontFamily: "AGaramondPro", fontWeight: "bolder" }}>How is it calculated?</h5>
                        <p className="card-text">The calculator uses the GHG Protocol PAS 2050 methodology and IPCC guidelines for evaluating scope 1, 2 and 3 emissions. As such, the numeric values underpinning the calculations in this tool will be updated annually, in line with the UK Government’s amendments to energy emissions factors.</p>
                    </div>
                </LandingPageCard>
            </LandingPageCardContainer>
        </div>
    )
}

export default Home