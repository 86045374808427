import { useField } from 'formik';
import React, { FormEvent, useEffect, useState } from 'react'
import { useAppSelector } from '../../hooks';
import { InputSubContainer, InputSubContainerRow } from '../styles/Container.styled';
import { ErrorText } from '../styles/Form.styled';
import { UtilityButton } from '../styles/Button.styled';
import tippy from 'tippy.js';

type Props = {
    index: number,
    errors: any,
    touched: any,
    deleteRecordHandler: (a: string) => void,
    inputIsLocked: () => boolean
}

const OtherLivestockRecord = (props: Props) => {

    // binding record properties using index
    const [livestockTypeField, livestockTypeMeta, livestockTypeHelpers] = useField(`otherLivestock[${props.index}].livestockType`);
    const [grazingTimeField, grazingTimeMeta, grazingTimeHelpers] = useField(`otherLivestock[${props.index}].grazingTime`);
    const [numberOfAnimalsField, numberOfAnimalsMeta, numberOfAnimalsHelpers] = useField(`otherLivestock[${props.index}].numberOfAnimals`);
    const [idField, idMeta, idHelpers] = useField(`otherLivestock[${props.index}].id`);

    const [showLivestockInfo, setShowLivestockInfo] = useState(false);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    useEffect(() => {
        if (errors.otherLivestock && errors.otherLivestock[props.index] && touched.otherLivestock && touched.otherLivestock[props.index] && errors.otherLivestock[props.index].livestockType && touched.otherLivestock[props.index].livestockType) {
            setShowLivestockInfo(true)
        }

        tippy('.oth-live-type-tooltip', {
            content: "Select the most compatible option for the type of livestock from the dropdown. We have simplified the categories available by weight and maturity (either adult if over 1 year, or juvenile if less than one year) and grouped together males and females.  If you have a commercial herd or flock use an agricultural emissions calculator to get a more accurate assessment of the other livestock emissions.",
        });
        
        tippy('.oth-live-number-tooltip', {
            content: "Enter the number of the type of livestock selected above present on the holding.",
        });
        
        tippy('.oth-live-grazing-time-tooltip', {
            content: "Enter the average time spent grazing on the holding.  Housed livestock is out of scope of this calculator and is not included in emissions assessment.",
        });

    }, [errors.otherLivestock, props.index, touched.otherLivestock])

    const onLivestockTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        livestockTypeHelpers.setValue(target.value);
    };
    const onLivestockTypeBlur = () => {
        livestockTypeHelpers.setTouched(true);
    };

    const onNumOfAnimalsInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        numberOfAnimalsHelpers.setValue(target.value);
    };
    const onNumOfAnimalsBlur = () => {
        numberOfAnimalsHelpers.setTouched(true);
    };

    const onGrazingTimeInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        grazingTimeHelpers.setValue(target.value);
    };
    const onGrazingTimeBlur = () => {
        grazingTimeHelpers.setTouched(true);
    };

    const toggleShowLivestockInfo = () => {
        setShowLivestockInfo(!showLivestockInfo);
    }

    return (
        <>
            <InputSubContainer>
                <InputSubContainerRow>
                    <div>
                        <label>Livestock type</label>
                    </div>

                    <div className='col-6'>
                        <select
                            className='form-control'
                            data-role='none'
                            value={livestockTypeField.value}
                            onChange={onLivestockTypeInput}
                            onBlur={onLivestockTypeBlur}
                            disabled={props.inputIsLocked()}
                        >
                            <option value="">--Please choose an option--</option>
                            {dropdowns?.filter(element => element.theme === "OtherLivestock").map(value => {
                                return <option key={value.key} value={value.key}>{value.description}</option>
                            })}
                        </select>
                        <ErrorText>{errors.otherLivestock && errors.otherLivestock[props.index] && touched.otherLivestock && touched.otherLivestock[props.index] && errors.otherLivestock[props.index].livestockType && touched.otherLivestock[props.index].livestockType && errors.otherLivestock[props.index].livestockType}</ErrorText>
                    </div>

                    <div>
                        <UtilityButton className='oth-liv-type-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        {showLivestockInfo ? (
                            <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleShowLivestockInfo} />) : (
                            <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleShowLivestockInfo} />
                        )}
                        <UtilityButton src="/icons/delete.png" alt='tooltip' onClick={() => props.deleteRecordHandler(idField.value)} />
                    </div>

                </InputSubContainerRow>

                {showLivestockInfo && (
                    <InputSubContainer>
                        <InputSubContainerRow>
                            <div>
                                <label>Number of Animals</label>
                            </div>

                            <div>
                                <input
                                    type="number"
                                    className='form-control'
                                    data-role='none'
                                    value={numberOfAnimalsField.value}
                                    onChange={onNumOfAnimalsInput}
                                    onBlur={onNumOfAnimalsBlur}
                                    disabled={props.inputIsLocked()}
                                />
                                <ErrorText>{errors.otherLivestock && errors.otherLivestock[props.index] && touched.otherLivestock && touched.otherLivestock[props.index] && errors.otherLivestock[props.index].numberOfAnimals && touched.otherLivestock[props.index].numberOfAnimals && errors.otherLivestock[props.index].numberOfAnimals}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='oth-liv-number-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        <InputSubContainerRow>
                            <div>
                                <label>Grazing time (weeks)</label>
                            </div>

                            <div>
                                <input
                                    type="number"
                                    className='form-control'
                                    data-role='none'
                                    value={grazingTimeField.value}
                                    onChange={onGrazingTimeInput}
                                    onBlur={onGrazingTimeBlur}
                                    disabled={props.inputIsLocked()}
                                />
                                <ErrorText>{errors.otherLivestock && errors.otherLivestock[props.index] && touched.otherLivestock && touched.otherLivestock[props.index] && errors.otherLivestock[props.index].grazingTime && touched.otherLivestock[props.index].grazingTime && errors.otherLivestock[props.index].grazingTime}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>

                    </InputSubContainer>
                )}

            </InputSubContainer>
        </>
    )
}

export default OtherLivestockRecord