import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { clearErrors, updateFootprint } from '../../features/footprint/footprintSlice'
import { InputContainer, InputFormContainer } from '../styles/Container.styled'
import { InputFormHeading, InputMainHeadingContainer } from '../styles/Input.styled'
import { InputForm } from '../styles/Form.styled'
import { AddMainRecordButton, StyledLink } from '../styles/Button.styled'
import Loader from '../layout/Loader'
import { MDBDataTable } from 'mdbreact'
import { deleteFootprint, getFootprints, reset } from '../../features/footprint/footprintSlice'

type Props = {}

type IFootprintsData = {
    columns: {
        label: string,
        field: string,
        sort: string
    }[],
    rows: {
        assessmentType: string,
        assessmentReference: string,
        holdingInformationReference: string,
        accountingPeriodEndYear: number,
        status: string
        actions: React.ReactNode
    }[]
}

const Footprints = (props: Props) => {

    const [selectedFootprintForDelete, setSelectedFootprintForDelete] = useState("");
    const [pulledData, setPulledData] = useState(false);
    const [currentPage, setCurrentPage] = useState(1);

    const dispatch = useAppDispatch()
    const navigate = useNavigate()

    const { id } = useParams();

    const { loading, errors, footprints, isDeleted, isUpdated, paginationMetaData } = useAppSelector(state => state.footprint)
    const { user } = useAppSelector(state => state.auth)

    useEffect(() => {

        if (!pulledData) {
            dispatch(getFootprints({ holdingId: id ?? "", PageNumber: currentPage }));
            setPulledData(true);
        }

        if (isUpdated) {
            toast.dismiss();
            toast.success("Footprint update successfully");
            dispatch(reset())
            dispatch(getFootprints({ holdingId: id ?? "", PageNumber: currentPage  }));
        }


        if (isDeleted) {
            toast.dismiss();
            toast.success("Footprint deleted successfully");
            dispatch(reset())
            dispatch(getFootprints({ holdingId: id ?? "", PageNumber: currentPage  }));
        }

        if (errors) {
            toast.error(errors["Message"])
            console.log(errors);
            dispatch(clearErrors())
        }

    }, [dispatch, errors, id, isDeleted, isUpdated, pulledData])

    const handlerNextPage = () => {
        if (paginationMetaData && (paginationMetaData?.currentPage < paginationMetaData?.totalPages)) {
            toast.dismiss();
            toast.info("Retrieving information...");
            dispatch(getFootprints({ holdingId: id ?? "", PageNumber: currentPage + 1 }));
        }
    }

    const handlerPrevPage = () => {
        if (paginationMetaData && (paginationMetaData?.currentPage > 1)) {
            toast.dismiss();
            toast.info("Retrieving information...");
            dispatch(getFootprints({ holdingId: id ?? "", PageNumber: currentPage - 1 }));
        }
    }

    const isAdmin = () => {
        return user?.userRoles === "Admin" || user?.roles?.includes("Admin");
    }

    const setFootprints = () => {
        const data: IFootprintsData = {
            columns: [
                {
                    label: 'Reference',
                    field: 'assessmentReference',
                    sort: 'asc'
                },
                {
                    label: 'Assessment Type',
                    field: 'assessmentType',
                    sort: 'asc'
                },
                {
                    label: 'Holding Reference',
                    field: 'holdingInformationReference',
                    sort: 'asc'
                },
                {
                    label: 'Accounting Period Year End',
                    field: 'accountingPeriodEndYear',
                    sort: 'asc'
                },
                {
                    label: 'Status',
                    field: 'status',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'asc'
                }

            ],
            rows: []
        }

        footprints && footprints.forEach(element => {
            data.rows.push({
                assessmentReference: element.assessmentReference,
                assessmentType: element.assessmentTypeKey,
                accountingPeriodEndYear: element.accountingPeriodEndYear,
                holdingInformationReference: element.holdingInformationReference,
                status: element.status,
                actions: <>
                    <div className="dropdown">
                        <button className="btn btn-secondary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            <i className="fa-solid fa-ellipsis"></i>
                        </button>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {(element.status !== "Submitted" || user?.userRoles === "Admin") && (
                                <li>
                                    <StyledLink to={`/holdings/footprint/${element.id}`} className="py-1 px-1 ml-2">
                                        <i className="fa-solid fa-pen-to-square mx-2 my-2" style={{ color: "#000" }}></i> Edit Footprint
                                    </StyledLink>
                                </li>
                            )}
                            <li>
                                <StyledLink to={`/holdings/footprint/${element.id}/duplicate`} className="py-1 px-1 ml-2">
                                    <i className="fa-solid fa-copy mx-2 my-2" style={{ color: "#000" }}></i> Duplicate
                                </StyledLink>
                            </li>
                            {element.status === "Submitted" ? (
                                <>
                                    <li>
                                        <StyledLink to={`/input/landUse`} className="py-1 px-1 ml-2" onClick={() => sessionStorage.setItem("footprintId", element.id)}>
                                            <i className="fa-solid fa-list mx-2 my-2" style={{ color: "#000" }}></i> View Data
                                        </StyledLink>
                                    </li>
                                    <li>
                                        <StyledLink to={`/results`} className="py-1 px-1 ml-2" onClick={() => sessionStorage.setItem("footprintId", element.id)}>
                                            <i className="fa-solid fa-square-poll-vertical mx-2 my-2" style={{ color: "#000" }}></i> View Results
                                        </StyledLink>
                                    </li>
                                </>
                            ) : (
                                <li>
                                    <StyledLink to={`/input/landUse`} className="py-1 px-1 ml-2" onClick={() => sessionStorage.setItem("footprintId", element.id)}>
                                        <i className="fa-solid fa-list mx-2 my-2" style={{ color: "#000" }}></i> Edit Data
                                    </StyledLink>
                                </li>
                            )}
                            {!element.isArchived ? (
                                <li>
                                    <StyledLink to={"#"} onClick={() => updateSelectedFootprintForDelete(element.id)} className="py-1 px-1 ml-2 border-none cursor-pointer" data-bs-toggle="modal" data-bs-target="#exampleModalCenter1">
                                        <i className="fa-solid fa-box-archive mx-2 my-2" style={{ color: "crimson" }}></i> Archive Footprint
                                    </StyledLink>
                                </li>
                            ) : (
                                <li>
                                    <StyledLink to={"#"} onClick={() => unArchiveFootprint(element.id, element.assessmentReference, element.accountingPeriodEndMonth, element.accountingPeriodEndYear)} className="py-1 px-1 ml-2 border-none cursor-pointer">
                                        <i className="fa-solid fa-folder-open mx-2 my-2" style={{ color: "green" }}></i> Unarchive Footprint
                                    </StyledLink>
                                </li>
                            )}
                            {isAdmin() && (
                                <li>
                                    <StyledLink to={"#"} onClick={() => updateSelectedFootprintForDelete(element.id)} className="py-1 px-1 ml-2 border-none cursor-pointer" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                                        <i className="fa-solid fa-trash mx-2 my-2" style={{ color: "crimson" }}></i> Delete Footprint
                                    </StyledLink>
                                </li>
                            )}
                        </ul>
                    </div>
                </>
                // <>
                //         <Link to={`footprint/${element.id}`} className="btn btn-primary py-1 px-2">
                //             <i className="fa-solid fa-pen-to-square" style={{ color: "#ffffff" }}></i>
                //         </Link>
                //         <button className='btn btn-danger py-1 px-2 ml-2' onClick={() => updateSelectedFootprintForDelete(element.id)}  data-bs-toggle="modal" data-bs-target="#exampleModalCenter" >
                //             <i className='fa fa-trash' />
                //         </button>
                //     </>
            })
        });

        return data;
    }

    const updateSelectedFootprintForDelete = (id: string) => {
        setSelectedFootprintForDelete(id);
    }

    const deleteSelectedFootprint = () => {
        if (selectedFootprintForDelete !== "") {
            toast.info("Deleting footprint...")
            dispatch(deleteFootprint({ id: selectedFootprintForDelete, permanentDelete: false }))
            dispatch(getFootprints({ holdingId: id ?? "", PageNumber: currentPage }));
        }
    }

    const permanentDeleteSelectedFootprint = () => {
        if (selectedFootprintForDelete !== "") {
            toast.info("Deleting footprint...")
            dispatch(deleteFootprint({ id: selectedFootprintForDelete, permanentDelete: true }))
            dispatch(getFootprints({ holdingId: id ?? "", PageNumber: currentPage }));
        }
    }

    const unArchiveFootprint = (id: string, reference: string, endMonth: number, endYear: number) => {
        if (id !== "") {
            toast.info("Unarchiving footprint...")
            dispatch(updateFootprint({ id, assessmentReference: reference, accountingPeriodEndMonth: endMonth, accountingPeriodEndYear: endYear, isArchived: false }))
        }
    }

    return (
        <>
            <InputContainer>
                <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>
                <InputFormContainer>
                    <InputFormHeading>{id ? "Footprint List" : "All Footprints"}</InputFormHeading>

                    <InputForm>
                        {/* <AddMainRecordButton src='/icons/plus.png' alt='Add main record' onClick={() => navigate("/holding/new")} /> */}

                        <div className='my-3'>
                            {loading ? <Loader /> : (
                                <MDBDataTable
                                    data={setFootprints()}
                                    borderless
                                    hover
                                    noBottomColumns
                                    striped
                                    displayEntries={false}
                                    paging={false}
                                />
                            )}
                        </div>
                    </InputForm>

                    {paginationMetaData && (
                        <>
                            <button className='nav-btn back-btn' disabled={paginationMetaData.currentPage === 1} onClick={handlerPrevPage}>Back</button>
                            <div className='pagination-info'>
                                <div>
                                    <div>{paginationMetaData.currentPage} / {paginationMetaData.totalPages}</div>
                                </div>
                            </div>
                            <button className='nav-btn next-btn' disabled={paginationMetaData.currentPage === paginationMetaData.totalPages} onClick={handlerNextPage}>Next</button>
                        </>
                    )}

                </InputFormContainer>
            </InputContainer>
            <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Are you sure?</h5>
                            <button type="button" className="btn btn-secondary close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>This action <strong>CANNOT</strong> be undone. This would permanently delete the footprint.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Back</button>
                            <button type="button" className="btn btn-danger" onClick={permanentDeleteSelectedFootprint} data-bs-dismiss="modal">Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="modal fade" id="exampleModalCenter1" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle1" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle1">Are you sure?</h5>
                            <button type="button" className="btn btn-secondary close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>This action <strong>CANNOT</strong> be undone unless you contact the site administrator.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Back</button>
                            <button type="button" className="btn btn-danger" onClick={deleteSelectedFootprint} data-bs-dismiss="modal">Yes, Archive</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Footprints


