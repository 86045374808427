import styled from "styled-components";

export const AccountingPeriodLabel = styled.label`
    margin: 0 2rem 0 2rem;

    @media (max-width: 500px) {
        margin: 0 0.5rem 0 0.5rem;
    }
`;

export const SummaryStat = styled.h5`
    font-weight: bold;
`;

export const SummaryGraphTitle = styled.h5`
    font-weight: bold;
`;

export const SummaryStatDescription = styled.p`
    margin-bottom: 0;
`;