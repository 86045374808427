import React, { FormEvent, useEffect, useState } from 'react';
import { FormikErrors, FormikTouched, useField } from "formik";
import { InputSubContainerRow, InputSubContainer } from '../../styles/Container.styled';
import { UtilityButton } from '../../styles/Button.styled';
import { ErrorText } from '../../styles/Form.styled';
import { IForagesAndStrawBedding } from '../Input.interface';
import { v4 as uuidv4 } from "uuid";
import { useAppSelector } from '../../../hooks';
import tippy from 'tippy.js';

type Props = {
    index: number,
    errors: any,
    touched: any,
    deleteRecordHandler: (a: string) => void,
    inputIsLocked: () => boolean
}

const ForageRecord = (props: Props) => {

    // binding record properties using index
    const [forageTypeField, forageTypeMeta, forageTypeHelpers] = useField(`foragesAndStrawBedding[${props.index}].forageType`);
    const [metricField, metricMeta, metricHelpers] = useField(`foragesAndStrawBedding[${props.index}].metric`);
    const [baleTypeField, baleTypeMeta, baleTypeHelpers] = useField(`foragesAndStrawBedding[${props.index}].baleType`);
    const [numOfAnnuallyPurchasedBaleField, numOfAnnuallyPurchasedBaleMeta, numOfAnnuallyPurchasedBaleHelpers] = useField(`foragesAndStrawBedding[${props.index}].numOfAnnuallyPurchasedBale`);
    const [totalTonnagePurchasedAnnuallyField, totalTonnagePurchasedAnnuallyMeta, totalTonnagePurchasedAnnuallyHelpers] = useField(`foragesAndStrawBedding[${props.index}].totalTonnagePurchasedAnnually`);
    const [balePlasticWasteDisposalField, balePlasticWasteDisposalMeta, balePlasticWasteDisposalHelpers] = useField(`foragesAndStrawBedding[${props.index}].balePlasticWasteDisposal`);
    const [estimatedDistanceFromDistributorField, estimatedDistanceFromDistributorMeta, estimatedDistanceFromDistributorHelpers] = useField(`foragesAndStrawBedding[${props.index}].estimatedDistanceFromDistributor`);
    const [idField, idMeta, idHelpers] = useField(`foragesAndStrawBedding[${props.index}].id`);

    const [showForageInfo, setShowForageInfo] = useState(false);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    useEffect(() => {
        if (errors.foragesAndStrawBedding && errors.foragesAndStrawBedding[props.index] && touched.foragesAndStrawBedding &&
            touched.foragesAndStrawBedding[props.index]) setShowForageInfo(true)

        tippy('.forage-type-tooltip', {
            content: "Select type if forage that is used on farm.  If more than one type is use, use the green plus to add extra lines.  Use the downward arrow to open up the detail to add in volumes purchased.",
        });

        tippy('.purchased-in-tooltip', {
            content: "Ideally enter the quantity purchased by weight as this is most accurate, however, if that is not known select bales and then select the most similar type of bales in the next drop down",
        });
        
        tippy('.purchased-volume-tooltip', {
            content: "Enter the total weigh purchased of the selected forage.",
        });
        
        tippy('.bale-waste-method-tooltip', {
            content: "This section aims to understand how any waste is disposed of, please select the one most similar to the approach used on the holding.",
        });

        tippy('.distributor-distance-tooltip', {
            content: "Select the estimated distance the holding is from the product distributor. This allows the tool to calculate the transport emissions associated with delivery of the product.",
        });
        
        tippy('.bale-type-tooltip', {
            content: "Select type of bale that most closely resembles the size of bale that you use on farm.",
        });

    }, [errors, props.index, touched, showForageInfo])

    const onForageTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        forageTypeHelpers.setValue(target.value);
    };
    const onForageTypeBlur = () => {
        forageTypeHelpers.setTouched(true);
    };

    const onMetricInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        metricHelpers.setValue(target.value);
    };
    const onMetricBlur = () => {
        metricHelpers.setTouched(true);
    };

    const onBaleTypeInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        baleTypeHelpers.setValue(target.value);
    };
    const onBaleTypeBlur = () => {
        baleTypeHelpers.setTouched(true);
    };

    const onNumOfAnnuallyPurchasedBaleInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        numOfAnnuallyPurchasedBaleHelpers.setValue(target.value);
    };
    const onNumOfAnnuallyPurchasedBaleBlur = () => {
        numOfAnnuallyPurchasedBaleHelpers.setTouched(true);
    };

    const onTotalTonnagePurchasedAnnuallyInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        totalTonnagePurchasedAnnuallyHelpers.setValue(target.value);
    };
    const onTotalTonnagePurchasedAnnuallyBlur = () => {
        totalTonnagePurchasedAnnuallyHelpers.setTouched(true);
    };

    const onBalePlasticWasteDisposalInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        balePlasticWasteDisposalHelpers.setValue(target.value);
    };
    const onBalePlasticWasteDisposalBlur = () => {
        balePlasticWasteDisposalHelpers.setTouched(true);
    };

    const onEstimatedDistanceFromDistributorInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        estimatedDistanceFromDistributorHelpers.setValue(target.value);
    };
    const onEstimatedDistanceFromDistributorBlur = () => {
        estimatedDistanceFromDistributorHelpers.setTouched(true);
    };

    const toggleForageInfo = () => {
        setShowForageInfo(!showForageInfo);
    }

    var [forages, meta, helpers] = useField("foragesAndStrawBedding")
    var existingForageTypeRecords = forages.value.filter((element: { id: any; }) => element.id !== idField.value).map((element: { forageType: any; }) => { return element.forageType });

    return (
        <InputSubContainer>

            <InputSubContainerRow>
                <div>
                    <label>Forage type</label>
                </div>

                <div>
                    <select
                        className='form-control'
                        value={forageTypeField.value}
                        onChange={onForageTypeInput}
                        onBlur={onForageTypeBlur}
                        data-role="none"
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "ForagesAndStraw" && !existingForageTypeRecords.includes(element.key)).map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText>{errors.foragesAndStrawBedding && errors.foragesAndStrawBedding[props.index] && touched.foragesAndStrawBedding && touched.foragesAndStrawBedding[props.index] && errors.foragesAndStrawBedding[props.index].supplementaryFeedType && touched.foragesAndStrawBedding[props.index].supplementaryFeedType && errors.foragesAndStrawBedding[props.index].supplementaryFeedType}</ErrorText>
                </div>

                <div>
                    <UtilityButton className='forage-type-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                    {showForageInfo ? (
                        <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleForageInfo} />) : (
                        <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleForageInfo} />
                    )}
                    <UtilityButton src="/icons/delete.png" alt='tooltip' onClick={() => props.deleteRecordHandler(idField.value)} />
                </div>
            </InputSubContainerRow>

            {showForageInfo && (
                <InputSubContainer>
                    <InputSubContainerRow>
                        <div>
                            <label>Purchased in</label>

                        </div>

                        <div>
                            <select
                                className='form-control'
                                data-role="none"
                                value={metricField.value}
                                onChange={onMetricInput}
                                onBlur={onMetricBlur}
                                disabled={props.inputIsLocked()}
                            >
                                <option value="">--Please choose an option--</option>
                                {dropdowns?.filter(element => element.theme === "Metric" && element.key !== "ApplicationRate").map(value => {
                                    return <option key={value.key} value={value.key}>{value.description}</option>
                                })}
                            </select>
                            <ErrorText>
                                {errors.foragesAndStrawBedding && errors.foragesAndStrawBedding[props.index] && touched.foragesAndStrawBedding &&
                                    touched.foragesAndStrawBedding[props.index] && errors.foragesAndStrawBedding[props.index].metric && touched.foragesAndStrawBedding[props.index].metric &&
                                    errors.foragesAndStrawBedding[props.index].metric}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton className='purchased-in-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow>

                    {metricField.value === "Bale" && (
                        <>
                            <InputSubContainerRow>
                                <div>
                                    <label>Types Of Bale</label>

                                </div>

                                <div>
                                    <select
                                        className='form-control'
                                        data-role="none"
                                        value={baleTypeField.value}
                                        onChange={onBaleTypeInput}
                                        onBlur={onBaleTypeBlur}
                                        disabled={props.inputIsLocked()}
                                    >
                                        <option value="">--Please choose an option--</option>
                                        {dropdowns?.filter(element => element.theme === "BaleType").map(value => {
                                            return <option key={value.key} value={value.key}>{value.description}</option>
                                        })}
                                    </select>
                                    <ErrorText>
                                        {errors.foragesAndStrawBedding && errors.foragesAndStrawBedding[props.index] && touched.foragesAndStrawBedding &&
                                            touched.foragesAndStrawBedding[props.index] && errors.foragesAndStrawBedding[props.index].baleType && touched.foragesAndStrawBedding[props.index].baleType &&
                                            errors.foragesAndStrawBedding[props.index].baleType}
                                    </ErrorText>
                                </div>

                                <div>
                                    <UtilityButton className="bale-type-tooltip" src="/icons/tooltip.png" alt='tooltip' />
                                </div>
                            </InputSubContainerRow>

                            <InputSubContainerRow>
                                <div>
                                    <label>Number of bales purchased annually</label>

                                </div>

                                <div>
                                    <input
                                        className='form-control'
                                        data-role="none"
                                        type='number'
                                        value={numOfAnnuallyPurchasedBaleField.value}
                                        onChange={onNumOfAnnuallyPurchasedBaleInput}
                                        onBlur={onNumOfAnnuallyPurchasedBaleBlur}
                                        disabled={props.inputIsLocked()}
                                    />
                                    <ErrorText>
                                        {errors.foragesAndStrawBedding && errors.foragesAndStrawBedding[props.index] && touched.foragesAndStrawBedding &&
                                            touched.foragesAndStrawBedding[props.index] && errors.foragesAndStrawBedding[props.index].numOfAnnuallyPurchasedBale && touched.foragesAndStrawBedding[props.index].numOfAnnuallyPurchasedBale &&
                                            errors.foragesAndStrawBedding[props.index].numOfAnnuallyPurchasedBale}
                                    </ErrorText>
                                </div>

                                {/* <div>
                                    <UtilityButton src="/icons/tooltip.png" alt='tooltip' />
                                </div> */}
                            </InputSubContainerRow>
                        </>
                    )}

                    {metricField.value === "Quantity" && (
                        <>
                            <InputSubContainerRow>
                                <div>
                                    <label>Total tonnage purchased annually (tonnes)</label>

                                </div>

                                <div>
                                    <input
                                        className='form-control'
                                        data-role="none"
                                        type="number"
                                        value={totalTonnagePurchasedAnnuallyField.value}
                                        onChange={onTotalTonnagePurchasedAnnuallyInput}
                                        onBlur={onTotalTonnagePurchasedAnnuallyBlur}
                                        disabled={props.inputIsLocked()}
                                    />
                                    <ErrorText>
                                        {errors.foragesAndStrawBedding && errors.foragesAndStrawBedding[props.index] && touched.foragesAndStrawBedding &&
                                            touched.foragesAndStrawBedding[props.index] && errors.foragesAndStrawBedding[props.index].totalTonnagePurchasedAnnually && touched.foragesAndStrawBedding[props.index].totalTonnagePurchasedAnnually &&
                                            errors.foragesAndStrawBedding[props.index].totalTonnagePurchasedAnnually}
                                    </ErrorText>
                                </div>

                                <div>
                                    <UtilityButton className='purchased-volume-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                                </div>
                            </InputSubContainerRow>
                        </>
                    )}

                    <InputSubContainerRow>
                        <div>
                            <label>Bale plastic waste disposal</label>

                        </div>

                        <div>
                            <select
                                className='form-control'
                                data-role="none"
                                value={balePlasticWasteDisposalField.value}
                                onChange={onBalePlasticWasteDisposalInput}
                                onBlur={onBalePlasticWasteDisposalBlur}
                                disabled={props.inputIsLocked()}
                            >
                                <option value="">--Please choose an option--</option>
                                {dropdowns?.filter(element => element.theme === "PackagingDisposalOption").map(value => {
                                    return <option key={value.key} value={value.key}>{value.description}</option>
                                })}

                            </select>
                            <ErrorText>
                                {errors.foragesAndStrawBedding && errors.foragesAndStrawBedding[props.index] && touched.foragesAndStrawBedding &&
                                    touched.foragesAndStrawBedding[props.index] && errors.foragesAndStrawBedding[props.index].balePlasticWasteDisposal && touched.foragesAndStrawBedding[props.index].balePlasticWasteDisposal &&
                                    errors.foragesAndStrawBedding[props.index].balePlasticWasteDisposal}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton className='bale-waste-method-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow>


                    <InputSubContainerRow>
                        <div>
                            <label>Estimated distance from distributor (km)</label>

                        </div>

                        <div>
                            <select
                                className='form-control'
                                data-role="none"
                                value={estimatedDistanceFromDistributorField.value}
                                onChange={onEstimatedDistanceFromDistributorInput}
                                onBlur={onEstimatedDistanceFromDistributorBlur}
                                disabled={props.inputIsLocked()}
                            >
                                <option value="">--Please choose an option--</option>
                                {dropdowns?.filter(element => element.theme === "TransportDistance").map(value => {
                                    return <option key={value.key} value={value.key}>{value.description}</option>
                                })}
                            </select>
                            <ErrorText>
                                {errors.foragesAndStrawBedding && errors.foragesAndStrawBedding[props.index] && touched.foragesAndStrawBedding &&
                                    touched.foragesAndStrawBedding[props.index] && errors.foragesAndStrawBedding[props.index].estimatedDistanceFromDistributor && touched.foragesAndStrawBedding[props.index].estimatedDistanceFromDistributor &&
                                    errors.foragesAndStrawBedding[props.index].estimatedDistanceFromDistributor}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton className='distributor-distance-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow>
                </InputSubContainer>
            )}

        </InputSubContainer>
    )
}

export default ForageRecord