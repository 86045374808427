import React, { useEffect, useState } from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../styles/Container.styled';
import { FieldArray, Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../styles/Button.styled';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from './Input';
import { otherLivestockValidationSchema } from './ValidationSchemas';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { toast } from 'react-toastify';
import { clearErrors, getFootprintById, getLivestockDataById, putLivestockDataById, reset } from '../../features/footprint/footprintSlice';
import { getDropdownValues } from '../../features/operations/operationsSlice';
import { ILivestockData } from '../../features/footprint/footprintSlice.interfaces';
import { v4 as uuidv4 } from "uuid";
import OtherLivestockRecord from './OtherLivestockRecord';
import Loader from '../layout/Loader';

type Props = {}

const OtherLivestock = (props: Props) => {
    const [pulledData, setPulledData] = useState(false);
    const [nextPage, setNextPage] = useState(false);

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();
    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, livestock, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);


    useEffect(() => {
        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getLivestockDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Livestock details saved successfully");
            dispatch(reset())
            if (nextPage) {
                navigate("/input/manureManagement");
            }
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }

        setActiveWideStep(2);
        setActiveMobileStep(2);

        dispatch(getDropdownValues({ section: "OtherLivestockInput" }))
    }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const parseDataIntoForm = (livestock: ILivestockData[]) => {
        var otherLivestock = livestock.map(element => {

            return {
                id: uuidv4(),
                livestockType: element.livestockType,
                numberOfAnimals: element.numberOfAnimals,
                grazingTime: element.grazingTime
            }
        })
        return { otherLivestock }
    }

    return (
        <div>
            <InputFormHeading>Other livestock</InputFormHeading>
            {loading ? <Loader /> : <Formik
                initialValues={parseDataIntoForm(livestock ?? [])}
                validationSchema={otherLivestockValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 400);

                    dispatch(putLivestockDataById(values.otherLivestock ?? []))
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <InputForm onSubmit={handleSubmit}>

                        <InputFormDescription>
                            Only include animals that are used for grazing management or other activities associated with the stud farm. Exclude commercial livestock.<br />

                            Select a category from the dropdown list and input the average number of animals in that category and the average number of weeks those animals spent on the site in the reporting year. Round values to the nearest whole week.
                        </InputFormDescription>

                        <FieldArray name="otherLivestock">
                            {({ form, ...fieldArrayHelpers }) => {
                                const onAddClick = () => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    fieldArrayHelpers.push({
                                        id: uuidv4(),
                                        livestockType: "",
                                        grazingTime: 0,
                                        numberOfAnimals: 0
                                    });
                                };

                                const deleteRecordHandler = (id: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    form.setFieldValue(
                                        "otherLivestock",
                                        form.values.otherLivestock.filter((record: { id: string; }) => record.id !== id)
                                    );
                                }

                                return (
                                    <>
                                        <AddMainRecordButton src='/icons/plus.png' alt='Add main record' onClick={onAddClick} />
                                        {form.values.otherLivestock.map((record: ILivestockData, index: number) => (
                                            <OtherLivestockRecord
                                                index={index}
                                                key={index}
                                                deleteRecordHandler={deleteRecordHandler}
                                                errors={errors}
                                                touched={touched}
                                                inputIsLocked={inputIsLocked}
                                            />
                                        ))}
                                    </>
                                );
                            }}
                        </FieldArray>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton type="button" onClick={() => navigate("/input/horses")}>
                                    Back
                                </InputFormButton>

                                {inputIsLocked() ? (
                                    <>
                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Continue
                                        </InputFormSaveButton>
                                    </>
                                ) : (
                                    <>
                                        <InputFormButton disabled={isSubmitting}>
                                            Save
                                        </InputFormButton>

                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Save & Continue
                                        </InputFormSaveButton>
                                    </>
                                )}
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>}
        </div>
    )
}

export default OtherLivestock