import React, { useEffect, useState } from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../../styles/Container.styled';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { UtilityButton } from '../../styles/Button.styled';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { clearErrors, getElectricityAndFuelDataById, getFootprintById, putContractorsFuelDetailsById, reset } from '../../../features/footprint/footprintSlice';
import { toast } from 'react-toastify';
import Loader from '../../layout/Loader';
import tippy from 'tippy.js';

type Props = {}

interface IContractorsFuelErrors {
    largeTractor?: string,
    smallTractor?: string,
    mediumTractor?: string
}

const ContractorsFuel = (props: Props) => {
    const [pulledData, setPulledData] = useState(false)
    const [nextPage, setNextPage] = useState(false)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();

    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, electricityAndFuel, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {
        setActiveWideStep(5);
        setActiveMobileStep(4);

        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getElectricityAndFuelDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Contractors' fuel saved successfully");
            dispatch(reset())
            if (nextPage) navigate("/input/purchasedFuel");
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }


    }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const parseDataIntoForm = (contractorsFuelDetails: { contractorActivityType: string, annualHours: number }[]) => {
        var smallTractor = contractorsFuelDetails.find(x => x.contractorActivityType === "SmallTractor");
        var mediumTractor = contractorsFuelDetails.find(x => x.contractorActivityType === "MediumTractor");
        var largeTractor = contractorsFuelDetails.find(x => x.contractorActivityType === "LargeTractor");

        var defaultValues = {
            smallTractor: 0,
            mediumTractor: 0,
            largeTractor: 0,
        }

        if (!(
            smallTractor?.annualHours ||
            mediumTractor?.annualHours ||
            largeTractor?.annualHours
        )) {
            return defaultValues
        } else {
            return {
                smallTractor: smallTractor?.annualHours,
                mediumTractor: mediumTractor?.annualHours,
                largeTractor: largeTractor?.annualHours
            }
        }

    }

    tippy('.small-tractor-tooltip', {
        content: "Small Tractor (90hp, 65kW) - enter the number of hours’ worth of activities this size tractor conducted during the assessment year. This is to only include activities conducted by contractors using their own fuel, not on farm fuel sources. The tool will calculate the fuel use based on the hours entered.",
    });

    tippy('.medium-tractor-tooltip', {
        content: "Medium Tractor (120hp, 90kW) enter the number of hours’ worth of activities this size tractor conducted during the assessment year. This is to only include activities conducted by contractors using their own fuel, not on farm fuel sources. The tool will calculate the fuel use based on the hours entered.",
    });

    tippy('.large-tractor-tooltip', {
        content: " Large Tractor (160 hp, 120kW) - enter the number of hours’ worth of activities this size tractor conducted during the assessment year. This is to only include activities conducted by contractors using their own fuel, not on farm fuel sources. The tool will calculate the fuel use based on the hours entered.",
    });

    return (
        <div>
            <InputFormHeading>Electricity and fuel - contractors' fuel</InputFormHeading>
            {loading ? <Loader /> : <Formik
                initialValues={parseDataIntoForm(electricityAndFuel?.contractorsFuel?.contractorsFuelDetails ?? [])}
                validate={values => {
                    const errors: IContractorsFuelErrors = {};

                    if (!values.largeTractor && values.largeTractor !== 0) {
                        errors.largeTractor = 'Required';
                    }
                    if (!values.smallTractor && values.smallTractor !== 0) {
                        errors.smallTractor = 'Required';
                    }
                    if (!values.mediumTractor && values.mediumTractor !== 0) {
                        errors.mediumTractor = 'Required';
                    }


                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 400);
                    
                    dispatch(putContractorsFuelDetailsById({
                        smallTractor: values.smallTractor ?? 0, 
                        mediumTractor: values.mediumTractor ?? 0,
                        largeTractor: values.largeTractor ?? 0
                    }))

                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <InputForm onSubmit={handleSubmit}>

                        <InputFormDescription>
                            Please use this section to provide contractors’ vehicle operator hours for work performed on the stud using their own fuel (i.e. please omit contractors’ hours where they are using fuel from the site which will have been recorded in the previous section).  This will give an indication of contractors’ fuel consumption to perform tasks such as topping, hedge cutting, hay making, reseeding, harrowing, fertiliser application etc. See the tutorial for this page by clicking <a href='https://www.youtube.com/watch?v=tsuMpUMtmH4&list=PLxMZB77n3_Or44Hw2dGQ6LGR8Js3GSJ1I&index=5' target='_blank' rel="noreferrer">here</a>
                        </InputFormDescription>

                        <table data-role="table" data-mode="reflow" className="ui-table ui-responsive ui-table-reflow my-custom-breakpoint my-4">
                            <thead>
                                <tr>
                                    <th data-priority="persist">Activity</th>
                                    <th data-priority="1">Annual time spent doing activity (hours)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Small tractor – (hay making, hedge cutting, mowing)
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="smallTractor"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.smallTractor}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.smallTractor && touched.smallTractor && errors.smallTractor}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='small-tractor-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Medium tractor – (reseeding, hay making, hedge cutting)
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="mediumTractor"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.mediumTractor}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.mediumTractor && touched.mediumTractor && errors.mediumTractor}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='medium-tractor-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Large tractor
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="largeTractor"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.largeTractor}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.largeTractor && touched.largeTractor && errors.largeTractor}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='large-tractor-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton type='button' onClick={() => navigate("/input/fuel")}>
                                    Back
                                </InputFormButton>

                                {inputIsLocked() ? (
                                    <>
                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Continue
                                        </InputFormSaveButton>
                                    </>
                                ) : (
                                    <>
                                        <InputFormButton disabled={isSubmitting}>
                                            Save
                                        </InputFormButton>

                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Save & Continue
                                        </InputFormSaveButton>
                                    </>
                                )}
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>}
        </div>
    )
}

export default ContractorsFuel