import React, { FormEvent } from 'react'
import { InputSubContainerRow, InputSubContainer } from '../styles/Container.styled'
import { UtilityButton } from '../styles/Button.styled'
import { useField } from 'formik'
import { ErrorText } from '../styles/Form.styled'
import { useAppSelector } from '../../hooks'

type Props = {
    horsesOnSiteRecordIndex: number,
    housingManagementIndex: number,
    horsesOnSiteRecordId: string,
    errors: any,
    touched: any,
    deleteHousingManagementHandler: (a: string, b: string) => void,
    inputIsLocked: () => boolean
}

const HousingManagement = (props: Props) => {

    const [managementTypeField, managementTypeMeta, managementTypeHelpers] = useField(`horses[${props.horsesOnSiteRecordIndex}].housingManagement[${props.housingManagementIndex}].managementType`);
    const [percentageOfTimeHousedField, percentageOfTimeHousedMeta, percentageOfTimeHousedHelpers] = useField(`horses[${props.horsesOnSiteRecordIndex}].housingManagement[${props.housingManagementIndex}].percentageOfTimeHoused`);
    const [idField, idMeta, idHelpers] = useField(`horses[${props.horsesOnSiteRecordIndex}].housingManagement[${props.housingManagementIndex}].id`);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    const onManagementTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        managementTypeHelpers.setValue(target.value);
    };
    const onManagementTypeBlur = () => {
        managementTypeHelpers.setTouched(true);
    };

    const onPercentageOfTimeHousedInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        percentageOfTimeHousedHelpers.setValue(target.value);
    };
    const onPercentageOfTimeHousedBlur = () => {
        percentageOfTimeHousedHelpers.setTouched(true);
    };

    const [housingManagementField, housingManagementMeta, housingManagementHelpers] = useField(`horses[${props.horsesOnSiteRecordIndex}].housingManagement`);
    var existingHousingManagementRecords = housingManagementField.value.filter((element: { id: any; }) => element.id !== idField.value).map((element: { managementType: any; }) =>  { return element.managementType });


    return (
        <InputSubContainer>
            <InputSubContainerRow>
                <div>
                    <label>Management type</label>
                </div>

                <div>
                    <select
                        className='form-control'
                        value={managementTypeField.value}
                        onChange={onManagementTypeInput}
                        onBlur={onManagementTypeBlur}
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "HousingManagementType" && !existingHousingManagementRecords.includes(element.key)).map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText className='text-center'>
                        {errors.horses && errors.horses[props.horsesOnSiteRecordIndex] && touched.horses && touched.horses[props.horsesOnSiteRecordIndex]
                            && errors.horses[props.horsesOnSiteRecordIndex].housingManagement && touched.horses[props.horsesOnSiteRecordIndex].housingManagement
                            && errors.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex] && touched.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex]
                            && errors.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex].managementType && touched.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex].managementType
                            && errors.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex].managementType}
                    </ErrorText>
                </div>

                <div>
                    <UtilityButton src="/icons/delete.png" alt='tooltip' onClick={() => props.deleteHousingManagementHandler(props.horsesOnSiteRecordId, idField.value)} />
                </div>
            </InputSubContainerRow>

            <InputSubContainerRow>
                <div>
                    <label>Percentage of time in management type (%)</label>
                </div>

                <div>
                    <input
                        className='form-control'
                        type="number"
                        value={percentageOfTimeHousedField.value}
                        onChange={onPercentageOfTimeHousedInput}
                        onBlur={onPercentageOfTimeHousedBlur}
                        disabled={props.inputIsLocked()} />
                    <ErrorText className='text-center'>
                        {errors.horses && errors.horses[props.horsesOnSiteRecordIndex] && touched.horses && touched.horses[props.horsesOnSiteRecordIndex]
                            && errors.horses[props.horsesOnSiteRecordIndex].housingManagement && touched.horses[props.horsesOnSiteRecordIndex].housingManagement
                            && errors.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex] && touched.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex]
                            && errors.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex].percentageOfTimeHoused && touched.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex].percentageOfTimeHoused
                            && errors.horses[props.horsesOnSiteRecordIndex].housingManagement[props.housingManagementIndex].percentageOfTimeHoused}
                    </ErrorText>
                </div>
            </InputSubContainerRow>
        </InputSubContainer>
    )
}

export default HousingManagement