import React, { FormEvent, useEffect } from 'react'
import { InputSubContainerRow, InputSubContainer } from '../../styles/Container.styled'
import { UtilityButton } from '../../styles/Button.styled'
import { useField } from 'formik'
import { ErrorText } from '../../styles/Form.styled'
import { useAppSelector } from '../../../hooks'
import tippy from 'tippy.js'

type Props = {
    landUseRecordIndex: number,
    fertiliserIndex: number,
    landRecordId: string,
    errors: any,
    touched: any,
    deleteOrganicMaterialHandler: (a: string, b: string) => void,
    inputIsLocked: () => boolean
}

const OrganicMaterial = (props: Props) => {

    const [materialTypeField, productNameMeta, materialTypeHelpers] = useField(`landUse[${props.landUseRecordIndex}].organicMaterialsApplied[${props.fertiliserIndex}].materialType`);
    const [applicationMethodField, applicationMethodMeta, applicationMethodHelpers] = useField(`landUse[${props.landUseRecordIndex}].organicMaterialsApplied[${props.fertiliserIndex}].applicationMethod`);
    const [metricField, metricMeta, metricHelpers] = useField(`landUse[${props.landUseRecordIndex}].organicMaterialsApplied[${props.fertiliserIndex}].metric`);
    const [volumeAppliedField, volumeAppliedMeta, volumeAppliedHelpers] = useField(`landUse[${props.landUseRecordIndex}].organicMaterialsApplied[${props.fertiliserIndex}].totalVolumeApplied`);
    const [avgRateField, avgRateMeta, avgRateHelpers] = useField(`landUse[${props.landUseRecordIndex}].organicMaterialsApplied[${props.fertiliserIndex}].averageApplicationRate`);
    const [idField, idMeta, idHelpers] = useField(`landUse[${props.landUseRecordIndex}].organicMaterialsApplied[${props.fertiliserIndex}].id`);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    const onMaterialTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        materialTypeHelpers.setValue(target.value);
    };
    const onMaterialTypeBlur = () => {
        materialTypeHelpers.setTouched(true);
    };

    const onApplicationMethodInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        applicationMethodHelpers.setValue(target.value);
    };
    const onApplicationMethodBlur = () => {
        applicationMethodHelpers.setTouched(true);
    };

    const onMetricInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        metricHelpers.setValue(target.value);
    };
    const onMetricBlur = () => {
        metricHelpers.setTouched(true);
    };

    const onVolumeAppliedInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        volumeAppliedHelpers.setValue(target.value);
    };
    const onVolumeAppliedBlur = () => {
        volumeAppliedHelpers.setTouched(true);
    };

    const onAvgRateInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        avgRateHelpers.setValue(target.value);
    };
    const onAvgRateBlur = () => {
        avgRateHelpers.setTouched(true);
    };

    tippy('.land-use-org-mat-inputs-tooltip', {
        content: "Select the type of material and enter the quantity applied either by entering the total volume or application rate of the product. Any product is selected, and not applied on an annual basis, enter the average volume/application rate per year e.g. if 5 tonnes is applied once every 5 years enter 1 tonne.",
    });
    
    return (
        <InputSubContainer>

            <InputSubContainerRow>
                <div>
                    <label>Type of Material</label>
                    <select
                        className='form-control'
                        value={materialTypeField.value}
                        onChange={onMaterialTypeInput}
                        onBlur={onMaterialTypeBlur}
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "OrganicMaterialType").map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex]
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].materialType && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].materialType
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].materialType}
                    </ErrorText>
                </div>
                <div className='col-5'>
                    <label>Application method</label>
                    <select
                        className='form-control'
                        value={applicationMethodField.value}
                        onChange={onApplicationMethodInput}
                        onBlur={onApplicationMethodBlur}
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "ApplicationMethod").map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex]
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].applicationMethod && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].applicationMethod
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].applicationMethod}
                    </ErrorText>
                </div>
                <div className="d-flex">
                    <UtilityButton className='land-use-org-mat-inputs-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                    <UtilityButton src="/icons/delete.png" alt='delete' onClick={() => props.deleteOrganicMaterialHandler(props.landRecordId, idField.value)} />
                </div>
            </InputSubContainerRow>
            <InputSubContainerRow>

                <div>
                    <label>Metric</label>
                    <select
                        className='form-control'
                        value={metricField.value}
                        onChange={onMetricInput}
                        onBlur={onMetricBlur}
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "Metric" && element.key !== "Bale").map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex]
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].metric && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].metric
                            && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].metric}
                    </ErrorText>
                </div>

                {metricField.value === 'Quantity' && (
                    <div>
                        <label>Total Volume Applied(m<sup>3</sup>)</label>
                        <input
                            className='form-control'
                            type="number"
                            value={volumeAppliedField.value}
                            onChange={onVolumeAppliedInput}
                            onBlur={onVolumeAppliedBlur}
                            disabled={props.inputIsLocked()} />
                        <ErrorText className='text-center'>
                            {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                                && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied
                                && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex]
                                && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].totalVolumeApplied && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].totalVolumeApplied
                                && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].totalVolumeApplied}
                        </ErrorText>
                    </div>
                )}

                {metricField.value === 'ApplicationRate' && (
                    <div>
                        <label>Average Application Rate(t/ha)</label>
                        <input
                            className='form-control'
                            type="number"
                            value={avgRateField.value}
                            onChange={onAvgRateInput}
                            onBlur={onAvgRateBlur}
                            disabled={props.inputIsLocked()} />
                        <ErrorText className='text-center'>
                            {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                                && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied
                                && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex]
                                && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].averageApplicationRate && touched.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].averageApplicationRate
                                && errors.landUse[props.landUseRecordIndex].organicMaterialsApplied[props.fertiliserIndex].averageApplicationRate}
                        </ErrorText>
                    </div>
                )}
            </InputSubContainerRow>


        </InputSubContainer>
    )
}

export default OrganicMaterial