import React, { useEffect, useState } from 'react'
import { DownloadReportButtonContainer, HomeHeadingContainer, InputFormContainer, InputSubContainer, InputSubContainerRow, ResultsContainer, ResultsHeadingContainer, ResultsMainSummaryValueWrapper, ResultsSectionContainer } from './styles/Container.styled'
import { useNavigate } from 'react-router-dom'
import { useAppDispatch, useAppSelector } from '../hooks'
import Loader from './layout/Loader'
import { getFootprintById, reset, submitFootprint } from '../features/footprint/footprintSlice'
import ApexCharts from 'apexcharts'
import Chart from "react-apexcharts";
import { toast } from 'react-toastify';
import { DownloadReportButton, ResultsViewDataButton, StyledLink, ToBenchmarkingButton } from './styles/Button.styled'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import 'canvg'
import html2canvas from 'html2canvas'

type Props = {}

const Results = (props: Props) => {

    const [pulledData, setPulledData] = useState(false)
    const [nextPage, setNextPage] = useState(false)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { loading, footprint, success } = useAppSelector(state => state.footprint);

    useEffect(() => {

        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Operation complete");
            dispatch(getFootprintById({ id }))
            dispatch(reset());
        }

    }, [dispatch, navigate, nextPage, pulledData, success, footprint])


    const options = {
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
            },
        },
        dataLabels: {
            enabled: false
        },
        stroke: {
            show: true,
            width: 2,
            colors: ['transparent']
        },
        xaxis: {
            categories: [
                'Fertiliser production',
                'Feed & bedding',
                'Enteric methane',
                'Manure management',
                'Other livestock',
                'Nitrogen application',
                'Organic materials',
                'Energy use',
                'Transport of horses',
                'Inputs transport'
            ],
        },
        yaxis: {
            title: {
                text: 'Emissions'
            }
        },
        fill: {
            opacity: 1
        },
    }

    const generateSeries = () => {
        var co2e = []

        co2e.push(footprint?.result?.fullResult.inputsManufacturedFertilisers.cO2E_Emissions.toFixed(0) ?? 0)
        co2e.push(((footprint?.result?.fullResult.inputsPurchasedForage.cO2E_Emissions ?? 0) + (footprint?.result?.fullResult.inputsPurchasedFeed.cO2E_Emissions ?? 0) + (footprint?.result?.fullResult.inputsBeeding.cO2E_Emissions ?? 0)).toFixed(0))
        co2e.push(footprint?.result?.fullResult.onFarmEntericMethaneHorses.cO2E_Emissions.toFixed(0) ?? 0)
        co2e.push(footprint?.result?.fullResult.onFarmManureManagementHorses.cO2E_Emissions.toFixed(0) ?? 0)
        co2e.push(footprint?.result?.fullResult.onFarmOtherLivestock.cO2E_Emissions.toFixed(0) ?? 0)
        co2e.push(((footprint?.result?.fullResult.onFarmDirectManufacturedNitrogen.cO2E_Emissions ?? 0) + (footprint?.result?.fullResult.onFarmIndirectManufacturedNitrogen.cO2E_Emissions ?? 0)).toFixed(0))
        co2e.push(footprint?.result?.fullResult.onFarmOrganicMaterials.cO2E_Emissions ?? 0)
        co2e.push(((footprint?.result?.fullResult.energyElectricity.cO2E_Emissions ?? 0) + (footprint?.result?.fullResult.energyFuel.cO2E_Emissions ?? 0) + (footprint?.result?.fullResult.energyContractors.cO2E_Emissions ?? 0)).toFixed(0))
        co2e.push(footprint?.result?.fullResult.energyTransportHorses.cO2E_Emissions.toFixed(0) ?? 0)
        co2e.push(footprint?.result?.fullResult.energyTransportRawMaterials.cO2E_Emissions.toFixed(0) ?? 0)

        return co2e;
    }

    const series = [{
        name: 'kgCO<sub>2</sub>e/year',
        data: generateSeries().map(element => Number(element)),
    }]

    const submitToBenchmarking = () => {
        toast.dismiss()
        toast.info("Adding this to benchmarking...", {
            autoClose: false
        })
        dispatch(submitFootprint({ id: footprint?.id ?? "", status: "Submitted" }))
    }

    const generateReport = () => {
        const doc = new jsPDF();

        var posY = 10;

        doc.text("TBA Stud Farm Carbon Calculator", 10, posY);
        var img = new Image()
        img.src = '/assets/TBA_logo_landscape.png'
        doc.addImage(img, 'png', 160, 5, 40, 10)


        posY = posY + 10;

        doc.text(`${footprint?.assessmentReference} Results - ${footprint?.id}`, 10, posY);
        posY = posY + 10;
        

        doc.setFontSize(14);
        doc.text("Main Summary", 10, posY);
        posY = posY + 10;

        doc.setFontSize(11);
        doc.text("Horse numbers & weights", 10, posY);
        posY = posY + 5;
        doc.setFontSize(8);
        doc.text(`${footprint?.result?.descriptiveStatisticsResult.totalHorsesDay} total horse days`, 10, posY);
        posY = posY + 5;
        doc.text(`${footprint?.result?.descriptiveStatisticsResult.averageNumberHorsesPerDay} average number of horses per day`, 10, posY);
        posY = posY + 5;
        doc.text(`${footprint?.result?.descriptiveStatisticsResult.grazedArea} total grazed area(ha)`, 10, posY);
        posY = posY + 10;

        doc.setFontSize(11);
        doc.text("Total emissions", 10, posY);
        posY = posY + 5;
        doc.setFontSize(8);
        doc.text(`${footprint?.result?.descriptiveStatisticsResult.totalStudFarmCO2E_Emissions.toFixed(0)} stud farm emissions per year(kgCO2e)`, 10, posY);
        posY = posY + 5;
        doc.text(`${footprint?.result?.descriptiveStatisticsResult.totalStudFarmCO2E_EmissionsPerHorseDay.toFixed(0)} emissions per horse day(kgCO2e/Horse day)`, 10, posY);
        posY = posY + 5;
        doc.text(`${footprint?.result?.descriptiveStatisticsResult.totalStudFarmCO2E_EmissionsPerGrazedHa.toFixed(0)} emissions per grazed hectare(kgCO2e/ha)`, 10, posY);
        posY = posY + 10;

        doc.setFontSize(14);
        doc.text("Detail Breakdown", 10, posY);
        posY = posY + 10;

        // doc.setFontSize(11);
        // doc.text("Emissions (kgCO2e/year)", 10, posY);
        // posY = posY + 10;

        // //Get svg markup as string
        // var svg = document.getElementById('chart-container')?.firstElementChild?.firstElementChild?.innerHTML;
        // console.log(svg);


        // doc.addSvgAsImage(svg ?? "", 10, posY, 300, 500);



        doc.setFontSize(11);
        doc.text("Total emissions at stud farm level", 10, posY);
        posY = posY + 5;
        doc.setFontSize(8);
        doc.text(`${footprint?.result?.fullResult.totalStudFarmEmissions.n2O_Emissions.toFixed(0)} kgN2O/year`, 10, posY);
        posY = posY + 5;
        doc.text(`${footprint?.result?.fullResult.totalStudFarmEmissions.cH4_Emissions.toFixed(0)} kgCH4/year`, 10, posY);
        posY = posY + 5;
        doc.text(`${footprint?.result?.fullResult.totalStudFarmEmissions.cO2_Emissions.toFixed(0)} kgCO2/year`, 10, posY);
        posY = posY + 5;
        doc.text(`${footprint?.result?.fullResult.totalStudFarmEmissions.cO2E_Emissions.toFixed(0)} kgCO2e/year`, 10, posY);
        posY = posY + 10;


        (doc as any).autoTable({
            html: '#inputs-embedded-emissions',
            startY: posY,
            theme: 'plain'
        })

        posY = posY + 40;

        (doc as any).autoTable({
            html: '#on-farm-processes',
            startY: posY,
            theme: 'plain'
        })

        posY = posY + 55;

        (doc as any).autoTable({
            html: '#energy-consumption',
            startY: posY,
            theme: 'plain'
        })

        html2canvas(document.getElementById("chart-container") as HTMLElement).then(function (canvas) {
            doc.addPage();
            var img1 = canvas.toDataURL("image/png")
            doc.addImage(img1, 'PNG', 10, 20, 190, 100);
            doc.save("a4.pdf");
        });

    }

    return <>
        {loading ? <Loader /> : <ResultsContainer>
            <ResultsHeadingContainer>
                <h1>Stud Farm Carbon Calculator</h1>
            </ResultsHeadingContainer>

            {!loading && (
                <>
                    <div><h3>Assessment Reference: {footprint?.assessmentReference}</h3></div>
                    <div><h4>Footprint ID: {footprint?.id}</h4></div>
                    <div><p> See the tutorial for this page by clicking <a href='https://www.youtube.com/watch?v=n4EwVkYRWCY&list=PLxMZB77n3_Or44Hw2dGQ6LGR8Js3GSJ1I&index=7' target='_blank' rel="noreferrer">here</a></p></div>
                </>
            )}

            <div className='d-flex justify-content-between my-4'>
                <div>
                    <ResultsViewDataButton onClick={(e) => navigate("/input/landUse")}><i className="fa-solid fa-list mx-2 my-2" style={{ color: "#000" }}></i> View Data</ResultsViewDataButton>
                </div>

                <DownloadReportButtonContainer >
                    <DownloadReportButton src='/icons/download.svg' alt='Download Report' onClick={generateReport} />
                </DownloadReportButtonContainer>


                {footprint?.status !== "Submitted" ? (
                    <ToBenchmarkingButton data-bs-toggle="modal" data-bs-target="#exampleModal">Add to Benchmarking</ToBenchmarkingButton>
                ) : (
                    // <ToBenchmarkingButton onClick={() => navigate("/benchmarking")}>Go to Benchmarking</ToBenchmarkingButton>
                    <></>
                )}
            </div>


            <div><h4>Main summary</h4></div>

            <ResultsSectionContainer className='text-center'>
                <InputSubContainerRow>
                    <div><h5>Horse numbers & weights</h5></div>
                </InputSubContainerRow>
                <InputSubContainerRow>
                    <div>
                        <ResultsMainSummaryValueWrapper>{footprint?.result?.descriptiveStatisticsResult.totalHorsesDay}</ResultsMainSummaryValueWrapper>
                        <div>Total horse days</div>
                    </div>
                    <div>
                        <ResultsMainSummaryValueWrapper>{footprint?.result?.descriptiveStatisticsResult.averageNumberHorsesPerDay}</ResultsMainSummaryValueWrapper>
                        <div>Avg number of horses per day</div>
                    </div>
                    <div>
                        <ResultsMainSummaryValueWrapper>{footprint?.result?.descriptiveStatisticsResult.grazedArea}</ResultsMainSummaryValueWrapper>
                        <div>Total grazed area<br />(ha)</div>
                    </div>
                </InputSubContainerRow>
            </ResultsSectionContainer>

            <ResultsSectionContainer className='text-center'>
                <InputSubContainerRow>
                    <div><h5>Total emissions</h5></div>
                </InputSubContainerRow>
                <InputSubContainerRow>
                    <div>
                        <ResultsMainSummaryValueWrapper>
                            {footprint?.result?.descriptiveStatisticsResult.totalStudFarmCO2E_Emissions.toFixed(0)}
                        </ResultsMainSummaryValueWrapper>
                        <div>Stud farm emissions per year<br />(kgCO2e)</div>
                    </div>
                    <div>
                        <ResultsMainSummaryValueWrapper>{footprint?.result?.descriptiveStatisticsResult.totalStudFarmCO2E_EmissionsPerHorseDay.toFixed(0)}</ResultsMainSummaryValueWrapper>
                        <div>Emissions per horse day<br /> (kgCO2e/Horse day)</div>
                    </div>
                    <div>
                        <ResultsMainSummaryValueWrapper>{footprint?.result?.descriptiveStatisticsResult.totalStudFarmCO2E_EmissionsPerGrazedHa.toFixed(0)}</ResultsMainSummaryValueWrapper>
                        <div>Emissions per grazed hectare<br /> (kgCO2e/ha)</div>
                    </div>
                </InputSubContainerRow>
            </ResultsSectionContainer>

            <div className='mt-5'><h4>Detail Breakdown</h4></div>

            <ResultsSectionContainer className='text-center'>
                <InputSubContainerRow>
                    <div><h5>kgCO<sub>2</sub>e/year</h5></div>
                </InputSubContainerRow>
                <InputSubContainerRow>
                    <div id='chart-container' className='w-100'>
                        <Chart
                            options={options}
                            series={series}
                            type="bar"
                        />
                    </div>
                </InputSubContainerRow>
            </ResultsSectionContainer>

            <ResultsSectionContainer className='text-center'>
                <InputSubContainerRow>
                    <div className='mb-3'><h5>Total emissions at stud farm level</h5></div>
                </InputSubContainerRow>
                <InputSubContainerRow>
                    <div>
                        <ResultsMainSummaryValueWrapper>
                            {footprint?.result?.fullResult.totalStudFarmEmissions.n2O_Emissions.toFixed(0)}
                        </ResultsMainSummaryValueWrapper>
                        <div>kgN<sub>2</sub>O/year</div>
                    </div>
                    <div>
                        <ResultsMainSummaryValueWrapper>
                            {footprint?.result?.fullResult.totalStudFarmEmissions.cH4_Emissions.toFixed(0)}
                        </ResultsMainSummaryValueWrapper>
                        <div>kgCH<sub>4</sub>/year</div>
                    </div>
                    <div>
                        <ResultsMainSummaryValueWrapper>
                            {footprint?.result?.fullResult.totalStudFarmEmissions.cO2_Emissions.toFixed(0)}
                        </ResultsMainSummaryValueWrapper>
                        <div>kgCO<sub>2</sub>/year</div>
                    </div>
                    <div>
                        <ResultsMainSummaryValueWrapper>
                            {footprint?.result?.fullResult.totalStudFarmEmissions.cO2E_Emissions.toFixed(0)}
                        </ResultsMainSummaryValueWrapper>
                        <div>kgCO<sub>2</sub>e/year</div>
                    </div>
                </InputSubContainerRow>
            </ResultsSectionContainer>

            <ResultsSectionContainer className='text-center'>
                <table id='inputs-embedded-emissions' className='table'>
                    <thead>
                        <tr>
                            <th>Inputs (embedded emissions)</th>
                            <th>kgN<sub>2</sub>O/year</th>
                            <th>kgCH<sub>4</sub>/year</th>
                            <th>kgCO<sub>2</sub>/year</th>
                            <th>kgCO<sub>2</sub>e/year</th>
                            {/* <th>% total emissions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Manufactured fertiliser - embedded emissions</td>
                            <td>{footprint?.result?.fullResult.inputsManufacturedFertilisers.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.inputsManufacturedFertilisers.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.inputsManufacturedFertilisers.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.inputsManufacturedFertilisers.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Purchased forage - embedded emissions</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{footprint?.result?.fullResult.inputsPurchasedForage.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Purchased feed - embedded emissions</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{footprint?.result?.fullResult.inputsPurchasedFeed.cO2E_Emissions.toFixed(2)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Bedding</td>
                            <td>-</td>
                            <td>-</td>
                            <td>-</td>
                            <td>{footprint?.result?.fullResult.inputsBeeding.cO2E_Emissions.toFixed(2)}</td>
                            {/* <td></td> */}
                        </tr>
                    </tbody>
                </table>
            </ResultsSectionContainer>

            <ResultsSectionContainer className='text-center'>
                <table id='on-farm-processes' className='table'>
                    <thead>
                        <tr>
                            <th>On farm processes</th>
                            <th>kgN<sub>2</sub>O/year</th>
                            <th>kgCH<sub>4</sub>/year</th>
                            <th>kgCO<sub>2</sub>/year</th>
                            <th>kgCO<sub>2</sub>e/year</th>
                            {/* <th>% total emissions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Enteric methane</td>
                            <td>{footprint?.result?.fullResult.onFarmEntericMethaneHorses.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmEntericMethaneHorses.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmEntericMethaneHorses.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmEntericMethaneHorses.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Manure management - horses</td>
                            <td>{footprint?.result?.fullResult.onFarmManureManagementHorses.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmManureManagementHorses.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmManureManagementHorses.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmManureManagementHorses.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Other livestock</td>
                            <td>{footprint?.result?.fullResult.onFarmOtherLivestock.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmOtherLivestock.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmOtherLivestock.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmOtherLivestock.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Application of manufactured nitrogen - direct</td>
                            <td>{footprint?.result?.fullResult.onFarmDirectManufacturedNitrogen.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmDirectManufacturedNitrogen.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmDirectManufacturedNitrogen.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmDirectManufacturedNitrogen.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Application of manufactured nitrogen - indirect</td>
                            <td>{footprint?.result?.fullResult.onFarmIndirectManufacturedNitrogen.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmIndirectManufacturedNitrogen.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmIndirectManufacturedNitrogen.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmIndirectManufacturedNitrogen.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Application of organic materials</td>
                            <td>{footprint?.result?.fullResult.onFarmOrganicMaterials.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmOrganicMaterials.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmOrganicMaterials.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.onFarmOrganicMaterials.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                    </tbody>
                </table>
            </ResultsSectionContainer>

            <ResultsSectionContainer className='text-center'>
                <table id='energy-consumption' className='table'>
                    <thead>
                        <tr>
                            <th>Energy consumption</th>
                            <th>kgN<sub>2</sub>O/year</th>
                            <th>kgCH<sub>4</sub>/year</th>
                            <th>kgCO<sub>2</sub>/year</th>
                            <th>kgCO<sub>2</sub>e/year</th>
                            {/* <th>% total emissions</th> */}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>Fuel</td>
                            <td>{footprint?.result?.fullResult.energyFuel.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyFuel.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyFuel.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyFuel.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Electricity</td>
                            <td>{footprint?.result?.fullResult.energyElectricity.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyElectricity.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyElectricity.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyElectricity.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Contractor’s fuel</td>
                            <td>{footprint?.result?.fullResult.energyContractors.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyContractors.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyContractors.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyContractors.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Transport of horses</td>
                            <td>{footprint?.result?.fullResult.energyTransportHorses.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyTransportHorses.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyTransportHorses.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyTransportHorses.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Transport of raw materials</td>
                            <td>{footprint?.result?.fullResult.energyTransportRawMaterials.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyTransportRawMaterials.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyTransportRawMaterials.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyTransportRawMaterials.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                        <tr>
                            <td>Energy waste</td>
                            <td>{footprint?.result?.fullResult.energyWaste.n2O_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyWaste.cH4_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyWaste.cO2_Emissions.toFixed(0)}</td>
                            <td>{footprint?.result?.fullResult.energyWaste.cO2E_Emissions.toFixed(0)}</td>
                            {/* <td></td> */}
                        </tr>
                    </tbody>
                </table>
            </ResultsSectionContainer>
        </ResultsContainer>}
        <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div className="modal-body">
                        By clicking "Yes", I declare that the information I have inputted is complete and accurate to the best of my knowledge and I am ready to submit this data and make no further changes to receive a benchmarking assessment.
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={submitToBenchmarking}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </>

}

export default Results