import React from 'react'
import { LoaderContainer } from '../styles/Container.styled'
import { LoaderElement } from '../styles/Span.styled'

type Props = {}

const Loader = (props: Props) => {
  return (
    <LoaderContainer>
        <LoaderElement />
    </LoaderContainer>
  )
}

export default Loader