import React, { FormEvent, useEffect, useState } from 'react';
import { FormikErrors, FormikTouched, useField } from "formik";
import { InputSubContainerRow, InputSubContainer } from '../styles/Container.styled';
import { UtilityButton } from '../styles/Button.styled';
import { ErrorText } from '../styles/Form.styled';
import { ILandUse } from './Input.interface';
import { Select } from '../styles/Input.styled';
import HousingManagement from './HousingManagement';
import { useAppSelector } from '../../hooks';
import tippy from 'tippy.js';

type Props = {
    index: number,
    errors: any,
    touched: any,
    addHousingManagementHandler: (a: string) => void,
    deleteRecordHandler: (a: string) => void,
    deleteHousingManagementHandler: (a: string, b: string) => void,
    inputIsLocked: () => boolean
}



const HorsesOnSiteRecord = (props: Props) => {

    // binding record properties using index
    const [animalTypeField, animalTypeMeta, animalTypeHelpers] = useField(`horses[${props.index}].animalType`);
    const [numOfAnimalsField, numOfAnimalsMeta, numOfAnimalsHelpers] = useField(`horses[${props.index}].numOfAnimals`);
    const [avgTimeSpentOnSiteField, avgTimeSpentOnSiteMeta, avgTimeSpentOnSiteHelpers] = useField(`horses[${props.index}].avgTimeSpentOnSite`);
    const [housingManagementField, housingManagementMeta, housingManagementHelpers] = useField(`horses[${props.index}].housingManagement`);
    const [referenceField, referenceMeta, referenceHelpers] = useField(`horses[${props.index}].reference`);
    const [idField, idMeta, idHelpers] = useField(`horses[${props.index}].id`);

    const [showHorsesOnSiteInfo, setShowHorsesOnSiteInfo] = useState(false);
    const [showHousingManagement, setShowHousingManagement] = useState(true);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    useEffect(() => {
        if (errors.horses && errors.horses[props.index] && touched.horses && touched.horses[props.index] && errors.horses[props.index].numOfAnimals && touched.horses[props.index].numOfAnimals) {
            setShowHorsesOnSiteInfo(true)
        }
        if (errors.horses && errors.horses[props.index] && touched.horses && touched.horses[props.index] && errors.horses[props.index].avgTimeSpentOnSite && touched.horses[props.index].avgTimeSpentOnSite) {
            setShowHorsesOnSiteInfo(true)
        }
        if (errors.horses && errors.horses[props.index] && touched.horses && touched.horses[props.index] && errors.horses[props.index].housingManagement && touched.horses[props.index].housingManagement) {
            setShowHorsesOnSiteInfo(true)
            setShowHousingManagement(true)
        }

        tippy('.horse-type-tooltip', {
            content: "Select the most compatible option for the type of horse from the dropdown.",
        });
        
        tippy('.horse-number-tooltip', {
            content: "Enter the total number of this type of horse that are present for the duration set out in time spent on site.",
        });

        tippy('.horse-time-tooltip', {
            content: "Chose from the dropdown list the average amount of time this group of horses spend on the holding",
        });
        
        tippy('.horse-housing-manage-tooltip', {
            content: "Select the most compatible option from the dropdown and enter the percentage of time the horses are housed in this management type. This allows the calculator to estimate what proportion of manure and urine is deposited in the stable or on yards compared to in the pasture.",
        });
        
        tippy('.horse-reference-tooltip', {
            content: "Use a clear name to reference this horse type.",
        });

    }, [errors.horses, props.index, touched.horses, showHorsesOnSiteInfo])

    const onAnimalTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        animalTypeHelpers.setValue(target.value);
    };
    const onAnimalTypeBlur = () => {
        animalTypeHelpers.setTouched(true);
    };

    const onNumOfAnimalsInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        numOfAnimalsHelpers.setValue(target.value);
    };
    const onNumOfAnimalsBlur = () => {
        numOfAnimalsHelpers.setTouched(true);
    };

    const onAvgTimeSpentOnSiteInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        avgTimeSpentOnSiteHelpers.setValue(target.value);
    };
    const onAvgTimeSpentOnSiteBlur = () => {
        avgTimeSpentOnSiteHelpers.setTouched(true);
    };

    const onReferenceInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        referenceHelpers.setValue(target.value);
    };
    const onReferenceBlur = () => {
        referenceHelpers.setTouched(true);
    };

    const toggleHorsesOnSiteInfo = () => {
        setShowHorsesOnSiteInfo(!showHorsesOnSiteInfo);
    }
    const toggleShowHousingManagement = () => {
        setShowHousingManagement(!showHousingManagement);
    }

    return (
        <>
            <InputSubContainer>
                <InputSubContainerRow>
                    <div>
                        <label>Type of horse</label>
                    </div>

                    <div className='col-6'>
                        <select
                            className='form-control'
                            data-role='none'
                            value={animalTypeField.value}
                            onChange={onAnimalTypeInput}
                            onBlur={onAnimalTypeBlur}
                            disabled={props.inputIsLocked()}
                        >
                            <option value="">--Please choose an option--</option>
                            {dropdowns?.filter(element => element.theme === "HorseType").map(value => {
                                return <option key={value.key} value={value.key}>{value.description}</option>
                            })}
                        </select>
                        <ErrorText>{errors.horses && errors.horses[props.index] && touched.horses && touched.horses[props.index] && errors.horses[props.index].animalType && touched.horses[props.index].animalType && errors.horses[props.index].animalType}</ErrorText>
                    </div>

                    <div>
                        <UtilityButton className='horse-type-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        {showHorsesOnSiteInfo ? (
                            <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleHorsesOnSiteInfo} />) : (
                            <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleHorsesOnSiteInfo} />
                        )}
                        <UtilityButton src="/icons/delete.png" alt='tooltip' onClick={() => props.deleteRecordHandler(idField.value)} />
                    </div>

                </InputSubContainerRow>

                {showHorsesOnSiteInfo && (
                    <InputSubContainer>
                        <InputSubContainerRow>
                            <div>
                                <label>Number of Animals</label>
                            </div>

                            <div>
                                <input
                                    type="number"
                                    className='form-control'
                                    data-role='none'
                                    value={numOfAnimalsField.value}
                                    onChange={onNumOfAnimalsInput}
                                    onBlur={onNumOfAnimalsBlur}
                                    disabled={props.inputIsLocked()}
                                />
                                <ErrorText>{errors.horses && errors.horses[props.index] && touched.horses && touched.horses[props.index] && errors.horses[props.index].numOfAnimals && touched.horses[props.index].numOfAnimals && errors.horses[props.index].numOfAnimals}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='horse-number-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        <InputSubContainerRow>
                            <div>
                                <label>Average time spent on site</label>
                            </div>

                            <div>
                                <select
                                    className='form-control'
                                    data-role='none'
                                    value={avgTimeSpentOnSiteField.value}
                                    onChange={onAvgTimeSpentOnSiteInput}
                                    onBlur={onAvgTimeSpentOnSiteBlur}
                                    disabled={props.inputIsLocked()}
                                >
                                    <option value="">--Please choose an option--</option>
                                    {dropdowns?.filter(element => element.theme === "DurationOfStayCategory").map(value => {
                                        return <option key={value.key} value={value.key}>{value.description}</option>
                                    })}
                                </select>
                                <ErrorText>{errors.horses && errors.horses[props.index] && touched.horses && touched.horses[props.index] && errors.horses[props.index].avgTimeSpentOnSite && touched.horses[props.index].avgTimeSpentOnSite && errors.horses[props.index].avgTimeSpentOnSite}</ErrorText>
                            </div>

                            <div>
                                <UtilityButton className='horse-time-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                            </div>

                        </InputSubContainerRow>
                        <InputSubContainer>
                            <InputSubContainerRow>
                                <div>
                                    <label>Housing management</label>
                                </div>

                                <div>
                                    <UtilityButton className='horse-housing-manage-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                                    {showHousingManagement ? (
                                        <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleShowHousingManagement} />) : (
                                        <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleShowHousingManagement} />
                                    )}
                                    <UtilityButton src="/icons/plus.png" alt='tooltip' onClick={() => props.addHousingManagementHandler(idField.value)} />
                                </div>
                            </InputSubContainerRow>
                            {showHousingManagement && housingManagementField.value.map((_: any, index: number) => {
                                return <HousingManagement 
                                            key={_.id} 
                                            horsesOnSiteRecordIndex={props.index} 
                                            housingManagementIndex={index} 
                                            horsesOnSiteRecordId={idField.value} 
                                            errors={props.errors} touched={props.touched} 
                                            deleteHousingManagementHandler={props.deleteHousingManagementHandler}
                                            inputIsLocked={props.inputIsLocked} />
                            })}
                        </InputSubContainer>


                    </InputSubContainer>
                )}

                <InputSubContainerRow>
                    <div>
                        <label>Reference</label>
                    </div>

                    <div>
                        <input
                            className='form-control'
                            data-role='none'
                            type='text'
                            value={referenceField.value}
                            onInput={onReferenceInput}
                            onBlur={onReferenceBlur}
                            disabled={props.inputIsLocked()} />
                        <ErrorText>{errors.horses && errors.horses[props.index] && touched.horses && touched.horses[props.index] && errors.horses[props.index].reference && touched.horses[props.index].reference && errors.horses[props.index].reference}</ErrorText>
                    </div>

                    <div>
                        <UtilityButton className='horse-reference-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                    </div>
                </InputSubContainerRow>



            </InputSubContainer>
        </>
    )
}

export default HorsesOnSiteRecord