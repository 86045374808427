import React, { useEffect, useState } from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../../styles/Container.styled';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { UtilityButton } from '../../styles/Button.styled';
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { toast } from 'react-toastify';
import { clearErrors, getElectricityAndFuelDataById, getFootprintById, putFuelDetailsById, reset } from '../../../features/footprint/footprintSlice';
import Loader from '../../layout/Loader';
import tippy from 'tippy.js';

type Props = {}

interface IFuelErrors {
    redDiesel?: string,
    dieselOther?: string,
    dieselHorseTransport?: string,
    petrol?: string,
    lpg?: string,
    heatingOil?: string,
}

const Fuel = (props: Props) => {
    const [pulledData, setPulledData] = useState(false)
    const [nextPage, setNextPage] = useState(false)

    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();

    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, electricityAndFuel, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {
        setActiveWideStep(5);
        setActiveMobileStep(4);

        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getElectricityAndFuelDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Fuel saved successfully");
            dispatch(reset())
            if (nextPage) navigate("/input/contractorsFuel");
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors());
        }

    }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])

    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const parseDataIntoForm = (fuelDetails: { fuelType: string, annualUsage: number }[]) => {
        var redDiesel = fuelDetails.find(x => x.fuelType === "RedDiesel");
        var whiteDieselHT = fuelDetails.find(x => x.fuelType === "WhiteDieselHorseTransport");
        var whiteDieselOther = fuelDetails.find(x => x.fuelType === "WhiteDieselOther");
        var petrol = fuelDetails.find(x => x.fuelType === "Petrol");
        var lpg = fuelDetails.find(x => x.fuelType === "LPG");
        var heatingOil = fuelDetails.find(x => x.fuelType === "HeatingOil");

        var defaultValues = {
            redDiesel: 0,
            dieselOther: 0,
            lpg: 0,
            dieselHorseTransport: 0,
            petrol: 0,
            heatingOil: 0

        }

        if (!(
            redDiesel?.annualUsage ||
            whiteDieselHT?.annualUsage ||
            lpg?.annualUsage ||
            whiteDieselOther?.annualUsage ||
            petrol?.annualUsage ||
            heatingOil?.annualUsage
        )) {
            return defaultValues
        } else {
            return {
                redDiesel: redDiesel?.annualUsage,
                dieselOther: whiteDieselOther?.annualUsage,
                lpg: lpg?.annualUsage,
                dieselHorseTransport: whiteDieselHT?.annualUsage,
                petrol: petrol?.annualUsage,
                heatingOil: heatingOil?.annualUsage
            }
        }

    }

    tippy('.red-diesel-tooltip', {
        content: "Enter total known fuel usage for farm in litres for the one year reporting period – use receipts to support accurate data entry",
    });

    tippy('.diesel-other-tooltip', {
        content: "Enter total known fuel usage for farm in litres for the one year reporting period – use receipts to support accurate data entry",
    });

    tippy('.diesel-horse-tooltip', {
        content: "Enter total known fuel usage for farm in litres for the one year reporting period – use receipts to support accurate data entry",
    });

    tippy('.petrol-tooltip', {
        content: "Enter total known fuel usage for farm in litres for the one year reporting period – use receipts to support accurate data entry",
    });

    tippy('.lpg-tooltip', {
        content: "Enter total known fuel usage for farm in litres for the one year reporting period – use receipts to support accurate data entry",
    });

    tippy('.heating-oil-tooltip', {
        content: "Enter total known fuel usage for farm in litres for the one year reporting period – use receipts to support accurate data entry",
    });


    return (
        <div>
            <InputFormHeading>Electricity and fuel - fuel</InputFormHeading>
            {loading ? <Loader /> : <Formik
                initialValues={parseDataIntoForm(electricityAndFuel?.fuel?.fuelDetails ?? [])}
                validate={values => {
                    const errors: IFuelErrors = {};

                    if (!values.redDiesel && values.redDiesel !== 0) {
                        errors.redDiesel = 'Required';
                    }

                    if (!values.dieselOther && values.dieselOther !== 0) {
                        errors.dieselOther = 'Required';
                    }
                    if (!values.dieselHorseTransport && values.dieselHorseTransport !== 0) {
                        errors.dieselHorseTransport = 'Required';
                    }
                    if (!values.petrol && values.petrol !== 0) {
                        errors.petrol = 'Required';
                    }
                    if (!values.lpg && values.lpg !== 0) {
                        errors.lpg = 'Required';
                    }
                    if (!values.heatingOil && values.heatingOil !== 0) {
                        errors.heatingOil = 'Required';
                    }

                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        // alert(JSON.stringify(values, null, 2));
                        setSubmitting(false);
                    }, 400);
                    // call dispatch
                    dispatch(putFuelDetailsById({
                        redDiesel: values.redDiesel ?? 0,
                        dieselOther: values.dieselOther ?? 0,
                        lpg: values.lpg ?? 0,
                        dieselHorseTransport: values.dieselHorseTransport ?? 0,
                        petrol: values.petrol ?? 0,
                        heatingOil: values.heatingOil ?? 0
                    }))
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <InputForm onSubmit={handleSubmit}>

                        <InputFormDescription>
                            We are looking to capture the total fuel that is used to manage and operate the farm including all mowing and paddock maintenance, use of contractors to complete actions such as hedge cutting or making of forage, and transport of horses to and from the farm. To help enter the data we provide four different tabs.  A total fuel usage tab, a contractors tab, a fuel use by vehicles tab and a horse transport tab.  Use the total fuel usage tab to capture the know fuel usage on site (e.g. filling up a diesel tank and then using for farm activities). Where contractors are used if you know the fuel usage you can capture on this tab, but where you don’t the next tab allows you to enter the average number of hours they used a small medium or large tractor for and then the calculator will estimate fuel usage based on that time.  Where you have company vehicles that are not filled up from the tank on site use the third tab to capture the mileage those vehicles complete in a year.  Where you transport horses with your own vehicle and know the fuel usage you can capture here, but if you use a hired box or don’t know the fuel usage then use the third tab to record fuel usage from horse transport.<br />

                            You only need to capture the fuel once, so if you can enter all fuel in the total diesel tab you don’t need to complete the subsequent ones. See the tutorial for this page by clicking <a href='https://www.youtube.com/watch?v=tsuMpUMtmH4&list=PLxMZB77n3_Or44Hw2dGQ6LGR8Js3GSJ1I&index=5' target='_blank' rel="noreferrer">here</a>
                        </InputFormDescription>

                        <table data-role="table" data-mode="reflow" className="ui-table ui-responsive ui-table-reflow my-custom-breakpoint my-4">
                            <thead>
                                <tr>
                                    <th data-priority="persist">Fuel type</th>
                                    <th data-priority="1">Annual Usage (L)</th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Red Diesel
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="redDiesel"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.redDiesel}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.redDiesel && touched.redDiesel && errors.redDiesel}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='red-diesel-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Diesel (Other)
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="dieselOther"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dieselOther}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.dieselOther && touched.dieselOther && errors.dieselOther}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='diesel-other-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Diesel (Horse transport)
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="dieselHorseTransport"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.dieselHorseTransport}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.dieselHorseTransport && touched.dieselHorseTransport && errors.dieselHorseTransport}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='diesel-horse-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Petrol
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="petrol"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.petrol}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.petrol && touched.petrol && errors.petrol}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='petrol-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            LPG
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="lpg"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.lpg}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.lpg && touched.lpg && errors.lpg}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='lpg-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                                <tr>
                                    <th className='align-middle'>
                                        <label>
                                            Heating Oil
                                        </label>
                                    </th>
                                    <td>
                                        <div>
                                            <input
                                                name="heatingOil"
                                                type='number'
                                                onChange={handleChange}
                                                onBlur={handleBlur}
                                                value={values.heatingOil}
                                                className='form-control'
                                                disabled={inputIsLocked()}
                                            />
                                            <ErrorText>{errors.heatingOil && touched.heatingOil && errors.heatingOil}</ErrorText>
                                        </div>
                                    </td>
                                    <td className='align-middle'>
                                        <UtilityButton className='heating-oil-tooltip' src="/icons/tooltip.png" alt='Tooltip' />
                                    </td>
                                </tr>
                            </tbody>
                        </table>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton type='button' onClick={() => navigate("/input/electricity")}>
                                    Back
                                </InputFormButton>

                                {inputIsLocked() ? (
                                    <>
                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Continue
                                        </InputFormSaveButton>
                                    </>
                                ) : (
                                    <>
                                        <InputFormButton disabled={isSubmitting}>
                                            Save
                                        </InputFormButton>

                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Save & Continue
                                        </InputFormSaveButton>
                                    </>
                                )}
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>}
        </div>
    )
}

export default Fuel