import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IOperationsData, IOperationsState } from "./operationsSlice.interfaces";

axios.defaults.withCredentials = true;

const initialState: IOperationsState = {
    loading: false,
}

export const getDropdownValues = createAsyncThunk(
    "operations/dropdowns/get",
    async (props: IOperationsData, { rejectWithValue }) => {
        try {
            const { section, theme } = props;
            const { data } = await axios.get('/api/admin/variables/dropdown-values', { params: { Section: section, Theme: theme } });
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

const operationsSlice = createSlice({
    name: "operations",
    initialState,
    reducers: {
        clearErrors(state) {
            state.errors = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(getDropdownValues.pending, (state, action) => {
            state.loading = true;
        })
        .addCase(getDropdownValues.fulfilled, (state, action) => {
            state.loading = false;
            state.dropdowns = action.payload;
        })
        .addCase(getDropdownValues.rejected, (state, action) => {
            state.loading = false;
            state.dropdowns = undefined;
            state.errors = action.payload;
        })

        
    },
})

export const { clearErrors } = operationsSlice.actions;
export default operationsSlice.reducer;
