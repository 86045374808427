import React from 'react'
import { ActiveProfileButton, InActiveProfileButton } from '../styles/Button.styled'

type Props = {
    section: number,
    activeSection: number,
    text: string,
    setActiveSection: (a: number) => void
}

const ProfIleSectionButton = (props: Props) => {
  return (
    <>
        {props.section === props.activeSection ? (
            <ActiveProfileButton>{props.text}</ActiveProfileButton>
        ) : (
            <InActiveProfileButton onClick={() => props.setActiveSection(props.section)} >{props.text}</InActiveProfileButton>
        )}
    </>
  )
}

export default ProfIleSectionButton