import React, { useEffect } from 'react';
import { InputContainer, InputSection, InputFormContainer, SingleInputContainer } from '../styles/Container.styled';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, InputMainHeadingContainer, Select } from '../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../styles/Button.styled';
import { AccountingPeriodLabel } from '../styles/Label.styled';
import { useNavigate } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { getDropdownValues } from '../../features/operations/operationsSlice';
import Loader from '../layout/Loader';
import { toast } from 'react-toastify';
import { createHolding, reset, clearErrors } from '../../features/footprint/holdingSlice';
import { IHoldingValues } from '../../features/footprint/holdingSlice.interfaces';
import { IHoldingInformationErrors } from './Footprint.interfaces';
import HoldingForm from './HoldingForm';

type Props = {}

const NewHolding = (props: Props) => {

    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { loading, dropdowns } = useAppSelector(state => state.operations)
    const { success, errors } = useAppSelector(state => state.holding)

    useEffect(() => {
        dispatch(getDropdownValues({ section: "HoldingInformationInput" }))

        if (success) {
            toast.dismiss();
            toast.success("Holding created successfully")
            dispatch(reset());
            navigate("/holdings");
            
        }

        if (errors) {
            toast.error("Something went wrong");
            // console.log(errors);
            dispatch(clearErrors())
        }

    }, [dispatch, errors, navigate, success])

    const submitHandler = (values: IHoldingValues) => {
        toast.dismiss();
        toast.info("Creating holding...", {
            autoClose: false
        });
        dispatch(createHolding(values))
    }


    return (
        <InputContainer>
            <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>
            <InputFormContainer>
                <InputFormHeading>Create Holding</InputFormHeading>

                {loading ? <Loader /> : <HoldingForm initialValues={undefined} submitHandler={submitHandler} dropdowns={dropdowns} submitText='Create' />}

            </InputFormContainer>
        </InputContainer>
    )
}

export default NewHolding