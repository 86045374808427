import React, { useEffect, useState } from 'react';
import { Formik, FieldArray } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../../styles/Button.styled';
import LandUseRecord from './LandUseRecord';
import { LandUseInputsContainer } from '../../styles/Container.styled';
import { v4 as uuidv4 } from "uuid";
import { landUseValidationSchema } from '../ValidationSchemas';
import { ILandUse } from '../Input.interface';
import { useNavigate } from 'react-router';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getDropdownValues } from '../../../features/operations/operationsSlice';
import { toast } from 'react-toastify';
import { clearErrors, getFootprintById, getLandUseDataById, putLandUseDataById, reset } from '../../../features/footprint/footprintSlice';
import { ILandUseData } from '../../../features/footprint/footprintSlice.interfaces';
import Loader from '../../layout/Loader';

type Props = {}

const LandUse = (props: Props) => {
    const [pulledData, setPulledData] = useState(false)
    const [nextPage, setNextPage] = useState(false)


    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const { setActiveWideStep, setActiveMobileStep } = useActiveStepHandler();
    const { loading: dropdownLoading, dropdowns } = useAppSelector(state => state.operations);
    const { loading, landUse, success, errors, footprint } = useAppSelector(state => state.footprint);
    const { user } = useAppSelector(state => state.auth);

    useEffect(() => {
        const id = sessionStorage.getItem("footprintId") ?? ''
        if (!pulledData) {
            dispatch(getLandUseDataById({ id }))
            dispatch(getFootprintById({ id }))
            setPulledData(true)
        }

        if (success) {
            toast.dismiss();
            toast.success("Land use saved successfully");
            dispatch(reset())
            if (nextPage) navigate("/input/horses");
        }

        if (errors) {
            toast.error(errors["Message"]);
            // console.log(errors)
            dispatch(clearErrors())
        }

        setActiveWideStep(1);
        setActiveMobileStep(1);

        dispatch(getDropdownValues({ section: "LandUseInput" }))

    }, [dispatch, errors, navigate, nextPage, pulledData, setActiveMobileStep, setActiveWideStep, success])


    const inputIsLocked = () => {
        return footprint?.status === "Submitted" && user?.userRoles !== "Admin";
    }

    const dataIsLoading = () => {
        return loading || dropdownLoading;
    }

    const parseDataIntoForm = (landUseDetails: ILandUseData[]) => {
        var landUse = landUseDetails.map(element => {
            // console.log(element?.grassland?.manufacturedFertilisers)
            var parsedManufacturedFertApplied = element?.grassland?.manufacturedFertilisers.map(fert => {
                return {
                    product: fert.type,
                    productName: fert.name,
                    nitrogenContentPercentage: Number(fert.nitrogenPercentage) * 100,
                    phosphateContentPercentage: fert.phosphatePercentage * 100,
                    potassiumContentPercentage: fert.potassiumPercentage * 100,
                    metric: fert.metric,
                    totalTonnageProduced: fert.totalTonnagePurchased,
                    applicationRateUnit: fert.applicationRateUnit ?? "",
                    averageApplicationRate: fert.averageApplicationRate,
                    id: uuidv4()
                }
            }) ?? []

            // console.log(parsedManufacturedFertApplied)

            var parsedOrganicMatApplied = element?.grassland?.organicMaterials.map(mat => {
                return {
                    materialType: mat.materialType,
                    applicationMethod: mat.applicationMethod,
                    metric: mat.metric,
                    totalVolumeApplied: mat.totalVolumeApplied,
                    averageApplicationRate: mat.averageApplicationRate,
                    id: uuidv4()
                }
            }) ?? []

            var parsedForage = element?.grassland?.forage.map(forage => {
                return {
                    forageType: forage.forageType,
                    metric: forage.metric,
                    totalTonnageProduced: forage.totalTonnage,
                    balePlasticWasteDisposalMethod: forage.packagingDisposalMethod,
                    baleType: forage.baleType ?? "",
                    numberOfBales: forage.numberOfBales,
                    id: uuidv4()
                }
            }) ?? []


            if (element.landType === "Hedgerows") {
                return {
                    id: uuidv4(),
                    landType: element.landType,
                    landArea: 0,
                    totalHedgerowLength: element?.hedgerow?.length,
                    averageHedgerowWidth: element?.hedgerow?.width,
                    averageHedgerowHeight: element?.hedgerow?.height,
                    hedgerowAge: element?.hedgerow?.age,
                    grasslandGrazingMethod: "",
                    woodlandAge: "",
                    woodlandType: "",
                    manufacturedFertiliserApplied: false,
                    manufacturedFertilisersApplied: [],
                    organicMaterialApplied: false,
                    organicMaterialsApplied: [],
                    isGrassHarvested: false,
                    forageHarvested: [],
                    reference: element.reference
                }
            }

            if (element.landType === "WoodlandAndShelterBelts") {
                return {
                    id: uuidv4(),
                    landType: element.landType,
                    landArea: element.totalLandArea,
                    totalHedgerowLength: 0,
                    averageHedgerowWidth: 0,
                    averageHedgerowHeight: 0,
                    hedgerowAge: "",
                    grasslandGrazingMethod: "",
                    woodlandAge: element?.woodland?.age,
                    woodlandType: element?.woodland?.woodlandType,
                    manufacturedFertiliserApplied: false,
                    manufacturedFertilisersApplied: [],
                    organicMaterialApplied: false,
                    organicMaterialsApplied: [],
                    isGrassHarvested: false,
                    forageHarvested: [],
                    reference: element.reference
                }
            }

            return {
                id: uuidv4(),
                landType: element.landType,
                landArea: element.totalLandArea,
                totalHedgerowLength: 0,
                averageHedgerowWidth: 0,
                averageHedgerowHeight: 0,
                grasslandGrazingMethod: element?.grassland?.grazingPattern ?? "",
                woodlandAge: "",
                woodlandType: "",
                hedgerowAge: "",
                manufacturedFertiliserApplied: element?.grassland?.manufacturedFertiliserApplied ?? false,
                manufacturedFertilisersApplied: parsedManufacturedFertApplied,
                organicMaterialApplied: element?.grassland?.organicMaterialApplied ?? false,
                organicMaterialsApplied: parsedOrganicMatApplied,
                isGrassHarvested: element?.grassland?.isGrassHarvested ?? false,
                forageHarvested: parsedForage,
                reference: element.reference
            }
        })

        return { landUse }
    }

    // console.log(parseDataIntoForm(landUse?.landUseDetails ?? []))

    return (
        <LandUseInputsContainer>
            <InputFormHeading>Land use, habitats & vegetation</InputFormHeading>

            {loading ? <Loader /> : <Formik
                initialValues={parseDataIntoForm(landUse?.landUseDetails ?? [])}
                validationSchema={landUseValidationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    // alert(JSON.stringify(values, null, 2));
                    setTimeout(() => {
                        setSubmitting(false);
                    }, 400);
                    // call dispatch
                    var landUseDetails: ILandUseData[] = values.landUse.map(element => {
                        var parsedManufacturedFertApplied = element?.manufacturedFertilisersApplied.map(fert => {
                            return {
                                type: fert.product,
                                name: fert.productName,
                                nitrogenPercentage: fert.nitrogenContentPercentage / 100,
                                phosphatePercentage: fert.phosphateContentPercentage / 100,
                                potassiumPercentage: fert.potassiumContentPercentage / 100,
                                metric: fert.metric,
                                totalTonnagePurchased: fert.totalTonnageProduced,
                                applicationRateUnit: fert.applicationRateUnit,
                                averageApplicationRate: fert.averageApplicationRate,
                            }
                        }) ?? []

                        var parsedOrganicMatApplied = element?.organicMaterialsApplied.map(mat => {
                            return {
                                materialType: mat.materialType,
                                applicationMethod: mat.applicationMethod,
                                metric: mat.metric,
                                totalVolumeApplied: mat.totalVolumeApplied,
                                averageApplicationRate: mat.averageApplicationRate,
                            }
                        }) ?? []

                        var parsedForage = element?.forageHarvested.map(forage => {
                            return {
                                forageType: forage.forageType,
                                metric: forage.metric,
                                totalTonnage: forage.totalTonnageProduced,
                                packagingDisposalMethod: forage.balePlasticWasteDisposalMethod,
                                baleType: forage?.baleType ?? "",
                                numberOfBales: forage.numberOfBales,
                                id: uuidv4()
                            }
                        }) ?? []

                        if (element.landType === "Hedgerows") {
                            return {
                                landType: element.landType,
                                reference: element.reference,
                                totalLandArea: 0,
                                hedgerow: {
                                    age: element.hedgerowAge ?? "",
                                    length: element.totalHedgerowLength ?? 0,
                                    width: element.averageHedgerowWidth ?? 0,
                                    height: element.averageHedgerowHeight ?? 0
                                },
                            }
                        }

                        if (element.landType === "WoodlandAndShelterBelts") {
                            return {
                                landType: element.landType,
                                totalLandArea: element.landArea,
                                woodland: {
                                    age: element?.woodlandAge ?? "",
                                    woodlandType: element?.woodlandType ?? ""
                                },
                                reference: element.reference
                            }
                        }

                        if (["BuildingsAndRoads", "ArtificialSurfaces", "WetlandsOrRivers", "GrassMargins"].includes(element.landType)) {
                            return {
                                landType: element.landType,
                                totalLandArea: element.landArea,
                                reference: element.reference
                            }
                        }

                        return {
                            landType: element.landType,
                            totalLandArea: element.landArea,
                            reference: element.reference,
                            grassland: {
                                grazingPattern: element.grasslandGrazingMethod,
                                manufacturedFertiliserApplied: element.manufacturedFertiliserApplied,
                                manufacturedFertilisers: parsedManufacturedFertApplied,
                                organicMaterialApplied: element.organicMaterialApplied,
                                organicMaterials: parsedOrganicMatApplied,
                                isGrassHarvested: element.isGrassHarvested,
                                forage: parsedForage
                            }
                        }
                    })

                    dispatch(putLandUseDataById({
                        landUseDetails
                    }))
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                }) => (
                    <InputForm onSubmit={handleSubmit}>

                        <InputFormDescription>
                            Please select a land type and enter a reference for your own identification purposes.  Click on the downward arrow to record the information relative to that block of land.  It is recommended that all land of the same type and similar management scenario is grouped together for streamlined data input purposes.  For example, if you have two paddocks that received the same fertiliser applications (type and rate), then calculate the totals and input as a single data entry.  Please click on the green + symbol to add additional land types. See the tutorial for this page by clicking <a href='https://www.youtube.com/watch?v=kxkylD-HR0A&list=PLxMZB77n3_Or44Hw2dGQ6LGR8Js3GSJ1I&index=1' target='_blank' rel="noreferrer">here</a>
                        </InputFormDescription>

                        <FieldArray name="landUse">
                            {({ form, ...fieldArrayHelpers }) => {
                                const onAddClick = () => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }

                                    fieldArrayHelpers.push({
                                        id: uuidv4(),
                                        landType: "",
                                        landArea: 0,
                                        totalHedgerowLength: 0,
                                        averageHedgerowWidth: 0,
                                        averageHedgerowHeight: 0,
                                        grasslandGrazingMethod: '',
                                        woodlandAge: '',
                                        woodlandType: '',
                                        hedgerowAge: '',
                                        manufacturedFertiliserApplied: false,
                                        manufacturedFertilisersApplied: [],
                                        organicMaterialApplied: false,
                                        organicMaterialsApplied: [],
                                        isGrassHarvested: false,
                                        forageHarvested: [],
                                        reference: ''
                                    });
                                };

                                const deleteForageHarvestedHandler = (id: string, forageId: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }

                                    let landUse = form.values.landUse;
                                    let recordIndex = landUse.findIndex((element: { id: string, forageHarvested: object }) => element.id === id)
                                    if (recordIndex === -1) return;
                                    let record = landUse[recordIndex]

                                    record.forageHarvested = record.forageHarvested.filter((element: { id: string; }) => element.id !== forageId)

                                    landUse[recordIndex] = record;

                                    form.setFieldValue(
                                        "landUse",
                                        landUse
                                    );
                                }

                                const addForageHarvestedHandler = (id: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    let landUse = form.values.landUse;
                                    let recordIndex = landUse.findIndex((element: { id: string, forageHarvested: any[] }) => element.id === id)
                                    if (recordIndex === -1) return;
                                    let record = landUse[recordIndex]


                                    record.forageHarvested.push({
                                        id: uuidv4(),
                                        forageType: '',
                                        metric: '',
                                        totalTonnageProduced: 0,
                                        balePlasticWasteDisposalMethod: '',
                                        baleType: '',
                                        numberOfBales: 0,
                                    })

                                    landUse[recordIndex] = record;

                                    form.setFieldValue(
                                        "landUse",
                                        landUse
                                    );
                                }

                                const deleteRecordHandler = (id: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    form.setFieldValue(
                                        "landUse",
                                        form.values.landUse.filter((record: { id: string; }) => record.id !== id)
                                    );
                                }

                                const addManufacturedFertilizerHandler = (id: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    let landUse = form.values.landUse
                                    let recordIndex = landUse.findIndex((element: { id: string, manufacturedFertilisersApplied: any[] }) => element.id === id)
                                    if (recordIndex === -1) return;
                                    let record = landUse[recordIndex]

                                    record.manufacturedFertilisersApplied.push({
                                        id: uuidv4(),
                                        product: "",
                                        productName: "",
                                        nitrogenContentPercentage: 0,
                                        phosphateContentPercentage: 0,
                                        potassiumContentPercentage: 0,
                                        metric: '',
                                        totalTonnageProduced: 0,
                                        applicationRateUnit: '',
                                        averageApplicationRate: 0
                                    });

                                    landUse[recordIndex] = record;

                                    form.setFieldValue(
                                        "landUse",
                                        landUse
                                    );
                                }

                                const deleteManufacturedFertilizerHandler = (id: string, fertiliserId: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    let landUse = form.values.landUse
                                    let recordIndex = landUse.findIndex((element: { id: string, manufacturedFertilisersApplied: any[] }) => element.id === id)
                                    if (recordIndex === -1) return;
                                    let record = landUse[recordIndex]

                                    record.manufacturedFertilisersApplied = record.manufacturedFertilisersApplied.filter((element: { id: string; }) => element.id !== fertiliserId)
                                    landUse[recordIndex] = record;

                                    form.setFieldValue(
                                        "landUse",
                                        landUse
                                    );
                                }

                                const updateManufacturedFertiliserHandler = (id: string, fertiliserId: string, fertiliserKey: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    let landUse = form.values.landUse
                                    let recordIndex = landUse.findIndex((element: { id: string, manufacturedFertilisersApplied: any[] }) => element.id === id)
                                    if (recordIndex === -1) return;
                                    let record = landUse[recordIndex]

                                    // get values from dropdown element
                                    var element = dropdowns?.find(x => x.key === fertiliserKey)

                                    var fertRecord = record.manufacturedFertilisersApplied.find((element: { id: string; }) => element.id === fertiliserId)
                                    var fertRecordIndex = record.manufacturedFertilisersApplied.findIndex((element: { id: string; }) => element.id === fertiliserId)

                                    if (fertiliserKey !== "OtherManufacturedFertiliser") {
                                        fertRecord.nitrogenContentPercentage = (element?.nutrients?.n ?? 0) * 100
                                        fertRecord.phosphateContentPercentage = (element?.nutrients?.p2O5 ?? 0) * 100
                                        fertRecord.potassiumContentPercentage = (element?.nutrients?.k2O ?? 0) * 100
                                    }

                                    record.manufacturedFertilisersApplied[fertRecordIndex] = fertRecord

                                    landUse[recordIndex] = record;

                                    form.setFieldValue(
                                        "landUse",
                                        landUse
                                    );
                                }

                                const addOrganicMaterialHandler = (id: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    let landUse = form.values.landUse
                                    let recordIndex = landUse.findIndex((element: { id: string, organicMaterialsApplied: any[] }) => element.id === id)
                                    if (recordIndex === -1) return;
                                    let record = landUse[recordIndex]

                                    record.organicMaterialsApplied.push({
                                        id: uuidv4(),
                                        materialType: '',
                                        applicationMethod: '',
                                        metric: '',
                                        totalVolumeApplied: 0,
                                        averageApplicationRate: '',
                                    });

                                    landUse[recordIndex] = record;

                                    form.setFieldValue(
                                        "landUse",
                                        landUse
                                    );
                                }

                                const deleteOrganicMaterialHandler = (id: string, fertiliserId: string) => {
                                    if (inputIsLocked()) {
                                        toast.error("This footprint has been submitted and locked so no further changes can be made here.");
                                        return;
                                    }
                                    let landUse = form.values.landUse
                                    let recordIndex = landUse.findIndex((element: { id: string, organicMaterialsApplied: any[] }) => element.id === id)
                                    if (recordIndex === -1) return;
                                    let record = landUse[recordIndex]

                                    record.organicMaterialsApplied = record.organicMaterialsApplied.filter((element: { id: string; }) => element.id !== fertiliserId)
                                    landUse[recordIndex] = record;

                                    form.setFieldValue(
                                        "landUse",
                                        landUse
                                    );
                                }

                                return (
                                    <>
                                        <AddMainRecordButton src='/icons/plus.png' alt='Add main record' onClick={onAddClick} />

                                        {form.values.landUse.map((record: ILandUse['landUse'], index: number) => (
                                            <LandUseRecord
                                                index={index}
                                                key={index}
                                                deleteRecordHandler={deleteRecordHandler}
                                                errors={errors}
                                                touched={touched}
                                                deleteForageHarvestedHandler={deleteForageHarvestedHandler}
                                                addForageHarvestedHandler={addForageHarvestedHandler}
                                                addManufacturedFertilizerHandler={addManufacturedFertilizerHandler}
                                                deleteManufacturedFertilizerHandler={deleteManufacturedFertilizerHandler}
                                                addOrganicMaterialHandler={addOrganicMaterialHandler}
                                                deleteOrganicMaterialHandler={deleteOrganicMaterialHandler}
                                                updateManufacturedFertiliserHandler={updateManufacturedFertiliserHandler}
                                                inputIsLocked={inputIsLocked}
                                            />
                                        ))}

                                        {form.values.landUse.length === 0 &&
                                            <div>
                                                There are no land use records available. Click on the green "+" to add one.
                                            </div>
                                        }
                                    </>
                                );
                            }}
                        </FieldArray>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton type="button" onClick={() => navigate("/holdings")}>
                                    Back
                                </InputFormButton>

                                {inputIsLocked() ? (
                                    <>
                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Continue
                                        </InputFormSaveButton>
                                    </>
                                ) : (
                                    <>
                                        <InputFormButton disabled={isSubmitting}>
                                            Save
                                        </InputFormButton>

                                        <InputFormSaveButton type="submit" disabled={isSubmitting} onClick={() => setNextPage(true)}>
                                            Save & Continue
                                        </InputFormSaveButton>
                                    </>
                                )}
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>}

        </LandUseInputsContainer>
    )
}

export default LandUse