import styled, { keyframes } from "styled-components";


export const fadeInFwd = keyframes`
    0% {
        -webkit-transform: translateZ(-80px);
                transform: translateZ(-80px);
        opacity: 0;
    }
    100% {
        -webkit-transform: translateZ(0);
                transform: translateZ(0);
        opacity: 1;
    }
`;

export const HomeHeadingContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 5%;
`;

export const HomeLandingSection = styled.div`
    max-height: 50rem;

    @media (max-width: 992px) {
        
    }

`

export const ResultsHeadingContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
    margin-top: 1.5%;
`;

export const HomeStartButtonWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 1rem;
    margin-top: 4%;
`;

export const AppContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    display:flex;
    flex-direction:column;
    justify-content: space-between;
`;

export const RouteContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 100%;
    
`;

export const InputContainer = styled.div`
    width: 95%;
`;

export const SummaryStatRow = styled.div`
    display: flex;
    justify-content: space-between;

    @media (max-width: 992px) {
        flex-direction: column;
        justify-content: center !important;
    }
`;

export const InputFormContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    min-height: 40rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 992px) {
        padding: 0.8rem;
        margin-bottom: 0.8rem;
    }
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    
`;

export const SummaryStatContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    width: 15rem;
    text-align: center;

    @media (max-width: 992px) {
        padding: 0.8rem;
        margin-bottom: 0.8rem;
        width: 100%;
    }
    
`;

export const SummaryGraphContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1rem;
    margin-bottom: 1.5rem;
    width: 49%;
    text-align: center;

    @media (max-width: 992px) {
        padding: 0.8rem;
        margin-bottom: 0.8rem;
        width: 100%;
    }
    
`;

export const ProfilePersonalDataContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 992px) {
        padding: 0.8rem;
        margin-bottom: 0.8rem;
    }
    // animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    
`;

export const InputSection = styled.div`
    display: grid;
    grid-template-columns: 5fr 2fr;
    gap: 20px;
    margin-top: 2rem;

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
    }
`;

export const InputOutletContainer = styled.div`
    width: 100%;
    padding: 0 4rem 0 4rem;
    margin-bottom: 5rem;

    @media (max-width: 992px) {
        padding: 0 1.5rem 0 1.5rem;
    }
`;

export const DownloadReportButtonContainer = styled.div`
    background: #fff;
    border: solid 1px #000;
    padding: 1rem;
    border-radius: 5px;
`;

export const SingleInputContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: revert;
`;

const scaleInCenter = keyframes`
    0% {
        -webkit-transform: scale(0);
        transform: scale(0);
        opacity: 1;
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
        opacity: 1;
    }
`;

export const InputSubContainer = styled.div`
    padding: 6px;
    background: #FFFFFF;
    border: 1px solid #000000;
    border-radius: 10px;
    margin-top: 5px;
    margin-bottom: 5px;
    -webkit-animation: ${scaleInCenter} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
    animation: ${scaleInCenter} 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
`;

export const InputSubContainerRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 6px 0 10px 0;
`;

export const LandUseInputsContainer = styled.div`
    position: relative;
`;

export const AuthInputContainer = styled.div`
    position: relative;
`;

export const SideBarContainer = styled.div`
    max-width: 1100px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

export const SideBarNavElements = styled.div`

    ul {
        display: flex;
        justify-content: space-between;
        list-style-type: none;

        li {
            &:not(:last-child) {
                margin-right: 60px;
            }

        }

        a {
            font-size: 16px;
            font-weight: 400;
            color: #2f234f;
            text-decoration: none;

            &.active {
                color: #574c4c;
                font-weight: 500;
                position: relative;

                &:after {
                    content: '';
                    position: absolute;
                    bottom: -4px;
                    left: 0;
                    width: 100%;
                    height: 2px;
                    background-color: #574c4c;
                }
            }
        }
    }

    @media (max-width: 992px) {
        position: absolute;
        right: 0;
        top: 60px;
        background-color: #fef7e5;
        width: 0px;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;

        &.active {
            width: 270px;
        }

        ul {
            display: flex;
            flex-direction: column;

            li {
                margin-right: unset;
                margin-top: 22px;
            }
        }

    }


`;

export const LoaderContainer = styled.div`
    position: relative;
    margin-top: 5rem;
    width: 100%;
    min-height: 100%;
    display: flex;
    justify-content: center;
`;


export const MobileTableContainer = styled.div`
    display: none !important;
    padding-bottom: 4rem;

    @media (max-width: 992px) {
        display: block !important;
    }
`;

export const WideTableContainer = styled.div`
    display: none !important;
    padding-bottom: 4rem;

    @media (min-width: 992px) {
        display: block !important;
    }
`;

export const ResultsContainer = styled.div`
    width: 95%
`;

export const ResultsSectionContainer = styled.div`
    background: #FFFFFF;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    min-height: 12rem;
    padding: 1.5rem;
    margin-bottom: 1.5rem;

    @media (max-width: 992px) {
        padding: 0.8rem;
        margin-bottom: 0.8rem;
    }
    
`;

export const ResultsMainSummaryValueWrapper = styled.div`
    font-size: 30px;
    font-weight: medium;
`;



export const LandingPagePanel = styled.div`
    width: 100%;
    height: 700px;
    text-align: center;
    display: grid;
    grid-template-columns: 1fr;
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const LandingPagePanelText1 = styled.div`
    font-size: 30px;
`;


export const LandingPageSubPanel = styled.div`
    background: #d9082a;
    padding: 6% 10%;
    color: white;
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;

export const LandingPageCardContainer = styled.div`
    padding: 5%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    
    @media (max-width: 992px) {
        grid-template-columns: 1fr;
    }
`;

export const LandingPageCard = styled.div`
    height: 525px;
    
    border: none;
`;

export const ProfileGrid = styled.div`
    margin-top: 40px;
    display: grid;
    grid-template-columns: 0.8fr 1.2fr;
    gap: 20px;

    @media (max-width: 992px) {
        grid-template-columns: 1fr;
    }
`;

export const ProfileSectionButtonWrapper = styled.div`
    
    @media (max-width: 992px) {
        display: flex;
        justify-content: space-between;
    }
`;

export const ProfileInfoContainer = styled.div`
    display: flex;
    min-height: 150px;
    height: 100%;
    padding: 10px;
    flex-direction: column;
    justify-content: space-between;
    font-size: 30px !important;
`;

export const SummaryFilterOptionsContainer = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 9fr 1fr;
    padding: 3rem;

    @media (max-width: 768px) {
        grid-template-columns: 1fr;
    }

    
`;

export const SummaryFertTable = styled.table`
    table-layout: fixed;
    width: 100%;
`;