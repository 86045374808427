import { Link } from "react-router-dom";
import styled from "styled-components";

export const HomeStartButton = styled.button`
    background: #3aaa35;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 0;
    padding 0.5rem 3.5rem;
    color: #dedc03;
    min-width: 180px;
    font-weight: 750;

    &:hover {
    transform: scale(0.98);
    }
`;

export const ResultsViewDataButton = styled.button`
    background: none;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 2px solid black;
    padding 0.5rem 3.5rem;
    color: black;
    min-width: 180px;
    font-weight: 750;

    &:hover {
    transform: scale(0.98);
    }
`;

export const UtilityButton = styled.img`
    margin-left: 0.2rem;
    height: 26px;
    width: 26px;
    cursor: pointer;
`;

export const DownloadReportButton = styled.img`
    margin-left: 0.2rem;
    height: 35px;
    width: 35px;
    cursor: pointer;
`;

export const AddMainRecordButton = styled.img`
    height: 50px;
    width: 50px;
    position: absolute;
    top: -70px;
    right: 0;
    cursor: pointer;
`;

export const AuthButton = styled.button`
    outline: none;
    border: none;
    margin: 8px 0;
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: #3aaa35 !important;
    color: #dedc03;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    width: 100%;
    border-radius: 0.5rem;
    text-transform: uppercase;

    &:hover {
        transform: scale(0.98);
`;

export const ProfileDeleteButton = styled.button`
    outline: none;
    border: none;
    margin: 8px 0;
    display: block;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-left: 1.25rem;
    padding-right: 1.25rem;
    background-color: crimson !important;
    color: #fff;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-weight: 500;
    width: 100%;
    border-radius: 0.5rem;
    text-transform: uppercase;

    &:hover {
        transform: scale(0.98);
`;

export const StyledLink = styled(Link)`
    color: black !important;
    text-decoration: none !important;
    margin-left: 1em;
    margin-right: 1em;
    &:hover {
        transform: scale(0.98);
        color: black;
    }
`;

export const ToBenchmarkingButton = styled.button`
    background: #3aaa35;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 0;
    padding 0.5rem;
    color: #dedc03;
    min-width: 180px;
    font-weight: 750;

    &:hover {
    transform: scale(0.98);
    }
`;

export const InActiveProfileButton = styled.button`
    background: transparent;
    border-radius: 5px;
    border: 1px solid #1fb6e8;
    padding 2rem 3.5rem;
    color: #000;
    min-width: 100%;
    font-weight: 750;
    margin 10px 0;

    &:hover {
    transform: scale(0.98);
    }
    @media (max-width: 992px) {
        padding 0.25rem 2rem;
        min-height: 70px;
    }
    @media (max-width: 500px) {
        padding 0.25rem 0.5rem;
    }
`;

export const ActiveProfileButton = styled.button`
    background: #1fb6e8;
    border-radius: 5px;
    border: 0;
    padding 2rem 3.5rem;
    color: #fff;
    min-width: 100%;
    font-weight: 750;
    margin 10px 0;

    &:hover {
    transform: scale(0.98);
    }

    @media (max-width: 992px) {
        padding 0.25rem 2rem;
        min-height: 70px;
    }
    @media (max-width: 500px) {
        padding 0.25rem 0.5rem;
    }
`;

export const AdminSummaryFilterButton = styled.button`
    background: #3aaa35;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 0;
    padding 0.5rem;
    margin: 1.5rem;
    color: #dedc03;
    min-width: 180px;
    font-weight: 750;

    &:hover {
    transform: scale(0.98);
    }
`;

export const AdminSummaryPaginationButton = styled.button`
    background: #3aaa35;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    border: 0;
    padding 0.5rem;
    margin: 1.5rem;
    color: #dedc03;
    min-width: 180px;
    font-weight: 750;

    &:hover {
    transform: scale(0.98);
    }
`;


