import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IUserActionProps, IUsersProps, IUserState } from "./userSlice.interfaces";
import { IAuthData } from "./authSlice.interfaces";
import { stat } from "fs";

axios.defaults.withCredentials = true;

const initialState: IUserState = {
    loading: false,
}


export const allUsers = createAsyncThunk(
    "user/admin/allUsers",
    async (props: IUsersProps, { rejectWithValue }) => {
        try {
            const { PageNumber } = props
            const config = {
                params: {
                    PageNumber
                }
            }
            const { data } = await axios.get("/api/admin/users", config);
            // console.log(data);
            return data;
            
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const deleteUser = createAsyncThunk(
    "user/admin/deleteUser",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { userId } = props
            const { data } = await axios.delete(`/api/admin/users/${userId}`);
            return data;
            
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const changeUserLockStatus = createAsyncThunk(
    "user/admin/changeLockStatus",
    async (props: IUserActionProps, { rejectWithValue }) => {
        try {
            const { id, lockStatus } = props
            var body = {
                LockUser: !lockStatus,
                UnlockUser: lockStatus
            }
            const config = {
                headers: {
                    "Content-Type": "application/json"
                },
                param: {
                    id
                }
            };
            const { data } = await axios.put(`/api/admin/users?id=${id}`, body, config);
            return data;
            
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const adminGetUser = createAsyncThunk(
    "user/admin/getUser",
    async (props: IUserActionProps, { rejectWithValue }) => {
        try {
            const { id } = props
            const { data } = await axios.get(`/api/admin/users/${id}`);
            return data;
            
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getAccount = createAsyncThunk(
    "user/getAccount",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`/api/identity/account/management`);
            return data;
            
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const deletePersonalData = createAsyncThunk(
    "user/deletePersonalData",
    async (props: IUserActionProps, { rejectWithValue }) => {
        try {
            const { password } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post(`/api/identity/account/management/delete-personal-data`, { Password: password, AcceptDelete: true }, config);
            return data;
            
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const changeUserEmail = createAsyncThunk(
    "user/changeEmail",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { email } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.put('/api/identity/account/management', { EmailAddress: email }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);


export const downloadUserData = createAsyncThunk(
    "user/downloadUserData",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get(`/api/identity/account/management/download-personal-data`);
            return data;
            
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const revokeAdminRights = createAsyncThunk(
    "user/revokeAdmin",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { userId } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                },
                data: {
                    RoleName: 'Admin'
                }
            };
            const { data } = await axios.delete(`/api/admin/users/${userId}/roles`, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const grantAdminRights = createAsyncThunk(
    "user/grantAdmin",
    async (props: IAuthData, { rejectWithValue }) => {
        try {
            const { userId } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post(`/api/admin/users/${userId}/roles`, { RoleName: 'Admin' }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data.message);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        reset(state) {
            state.isDeleted = undefined;
            state.success = undefined;
            state.isUpdated = undefined;
        },
        resetUserDataDownload(state) {
            state.userData = undefined
        },
        clearErrors(state) {
            state.errors = undefined;
        },
        clearMessage(state) {
            state.message = undefined;
        }
    },
    extraReducers(builder) {
        builder
            .addCase(allUsers.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(allUsers.fulfilled, (state, action) => {
                state.loading = false;
                state.users = action.payload.data;
                state.paginationMetaData = action.payload.paginationMetaData;
            })
            .addCase(allUsers.rejected, (state, action) => {
                state.loading = false;
                state.users = undefined;
                state.paginationMetaData = undefined;
                state.errors = action.payload;
            })

            .addCase(deleteUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteUser.fulfilled, (state, action) => {
                state.loading = false;
                state.isDeleted = true;
            })
            .addCase(deleteUser.rejected, (state, action) => {
                state.loading = false;
                state.users = undefined;
                state.errors = action.payload;
            })

            .addCase(changeUserLockStatus.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(changeUserLockStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(changeUserLockStatus.rejected, (state, action) => {
                state.loading = false;
                state.users = undefined;
                state.errors = action.payload;
            })

            .addCase(adminGetUser.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(adminGetUser.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(adminGetUser.rejected, (state, action) => {
                state.loading = false;
                state.user = undefined;
                state.errors = action.payload;
            })

            .addCase(changeUserEmail.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(changeUserEmail.fulfilled, (state, action) => {
                state.loading = false;
                state.message = "Please check your inbox for the next steps";
            })
            .addCase(changeUserEmail.rejected, (state, action) => {
                state.loading = false;
                state.message = undefined;
                state.errors = action.payload;
            })


            .addCase(getAccount.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAccount.fulfilled, (state, action) => {
                state.loading = false;
                state.user = action.payload;
            })
            .addCase(getAccount.rejected, (state, action) => {
                state.loading = false;
                state.user = undefined;
                state.errors = action.payload;
            })

            .addCase(deletePersonalData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deletePersonalData.fulfilled, (state, action) => {
                state.loading = false;
                state.isDeleted = true;
            })
            .addCase(deletePersonalData.rejected, (state, action) => {
                state.loading = false;
                state.isDeleted = undefined;
                state.errors = action.payload;
            })

            .addCase(downloadUserData.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(downloadUserData.fulfilled, (state, action) => {
                state.loading = false;
                state.userData = action.payload;
            })
            .addCase(downloadUserData.rejected, (state, action) => {
                state.loading = false;
                state.userData = undefined;
                state.errors = action.payload;
            })
            .addCase(revokeAdminRights.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(revokeAdminRights.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.userData = action.payload;
            })
            .addCase(revokeAdminRights.rejected, (state, action) => {
                state.loading = false;
                state.userData = undefined;
                state.errors = action.payload;
            })
            .addCase(grantAdminRights.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(grantAdminRights.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.userData = action.payload;
            })
            .addCase(grantAdminRights.rejected, (state, action) => {
                state.loading = false;
                state.userData = undefined;
                state.errors = action.payload;
            })
    },
})

export const { clearErrors, clearMessage, reset, resetUserDataDownload } = userSlice.actions;
export default userSlice.reducer;