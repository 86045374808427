import React, { useEffect, useState } from 'react'
import { AuthInputContainer, InputContainer, InputFormContainer, ProfileGrid, ProfileInfoContainer, ProfilePersonalDataContainer, ProfileSectionButtonWrapper } from '../styles/Container.styled'
import { AuthInput, InputFormHeading, InputMainHeadingContainer, ProfileInput } from '../styles/Input.styled'
import Loader from '../layout/Loader'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { clearErrors, adminGetUser, getAccount, downloadUserData, resetUserDataDownload, changeUserEmail, deletePersonalData, reset } from '../../features/auth/userSlice'
import ProfIleSectionButton from './ProfIleSectionButton'
import { AuthFormTitle, ProfileForm, StyledAuthFormLink } from '../styles/Form.styled'
import { AuthSpan, AuthSvg } from '../styles/Span.styled'
import { AuthButton, ProfileDeleteButton } from '../styles/Button.styled'
import { logoutUser } from '../../features/auth/authSlice'

type Props = {}

const Profile = (props: Props) => {

    const [pulledData, setPulledData] = useState(false);
    const [activeSection, setActiveSection] = useState(1);
    const [newEmail, setNewEmail] = useState("");
    const [deleteAccountPassword, setDeleteAccountPassword] = useState("");
    const [newPassword, setNewPassword] = useState("");

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { users, loading: userLoading, user: userProfile, userData, message, isDeleted } = useAppSelector(state => state.user)
    const { user, loading, errors } = useAppSelector(state => state.auth)

    useEffect(() => {
        if (!pulledData) {
            dispatch(getAccount());
            setPulledData(true);
        }

        if (message) {
            navigate("/");
            toast.dismiss();
            toast.success(message);
        }

        if (isDeleted) {
            toast.dismiss();
            toast.success("Data deleted successfully");
            dispatch(reset())
            dispatch(logoutUser())
            navigate("/")
        }

        if (userData) {
            export2json(userData);
            toast.dismiss();
            toast.success("Personal data downloaded successfully");
            dispatch(resetUserDataDownload());
        }

        if (typeof errors === 'string') {
            toast.dismiss();
            toast.error(errors);
            dispatch(clearErrors());
        }else if (errors) {
            toast.dismiss();
            if (Array.isArray(errors)) {
                errors.forEach(element => {
                    toast.error(element.description);
                }) 
            }
            dispatch(clearErrors());
        }

    }, [dispatch, errors, message, navigate, pulledData, user?.id, userData, isDeleted])


    const changeEmailHandler = () => {
        toast.info("Changing email...");
        dispatch(changeUserEmail({ email: newEmail }));
    }

    const deleteAccountHandler = () => {
        if (deleteAccountPassword === "") {
            toast.error("You must enter the correct password to complete this action");
            return;
        }

        toast.info("Deleting account...");
        dispatch(deletePersonalData({ password: deleteAccountPassword }));
    }

    const downloadPersonalDataHandler = () => {
        toast.info("Downloading user data...");
        dispatch(downloadUserData());
    }

    function export2json(data: any) {

        const a = document.createElement("a");
        a.href = URL.createObjectURL(new Blob([JSON.stringify(data, null, 2)], {
            type: "text/json"
        }));
        a.setAttribute("download", "personal-data.json");
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    }

    return (
        <>
            <InputContainer>
                <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>
                <InputFormContainer>
                    <InputFormHeading>My Profile</InputFormHeading>

                    <ProfileGrid>
                        <ProfileSectionButtonWrapper>
                            <div>
                                <ProfIleSectionButton section={1} activeSection={activeSection} setActiveSection={setActiveSection} text="View Profile" />
                            </div>
                            <div><ProfIleSectionButton section={2} activeSection={activeSection} setActiveSection={setActiveSection} text="Change Email" /></div>
                            {/* <div><ProfIleSectionButton section={3} activeSection={activeSection} setActiveSection={setActiveSection} text="Change Password" /></div> */}
                            <div><ProfIleSectionButton section={4} activeSection={activeSection} setActiveSection={setActiveSection} text="Personal Data" /></div>

                        </ProfileSectionButtonWrapper>
                        {loading ? <Loader /> : (
                            <div>
                                <ProfileInfoContainer>
                                    {activeSection === 1 && (
                                        <>
                                            <div>
                                                <h5>Email: {userProfile?.email}</h5>
                                            </div>
                                            <div>
                                                <h5>Membership Number: {userProfile?.membershipNumber}</h5>
                                            </div>
                                            <div>
                                                <h5>Role: {userProfile?.userRoles}</h5>
                                            </div>
                                            {/* <div>
                                                <h5>Lock Status: {String(userProfile?.isLockedOut)}</h5>
                                            </div> */}
                                        </>
                                    )}
                                    {activeSection === 2 && (
                                        <>
                                            <ProfileForm>
                                                <AuthFormTitle>Change your email address</AuthFormTitle>
                                                <AuthInputContainer>
                                                    <ProfileInput placeholder="Enter email" type="email" value={newEmail} onChange={(e) => setNewEmail(e.target.value)} />
                                                </AuthInputContainer>

                                                <AuthButton type="submit" onClick={changeEmailHandler}>
                                                    SUBMIT
                                                </AuthButton>

                                            </ProfileForm>
                                        </>
                                    )}
                                    {/* {activeSection === 3 && (
                                        <>
                                            <ProfileForm>
                                                <AuthFormTitle>Change your account password</AuthFormTitle>
                                                <AuthInputContainer>
                                                    <ProfileInput placeholder="Enter current password" type="email" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                    <ProfileInput placeholder="Enter new password" type="email" value={newPassword} onChange={(e) => setNewPassword(e.target.value)} />
                                                </AuthInputContainer>

                                                <AuthButton type="submit" onClick={changeEmailHandler}>
                                                    SUBMIT
                                                </AuthButton>

                                            </ProfileForm>
                                        </>
                                    )} */}
                                    {activeSection === 4 && (
                                        <>
                                            <ProfilePersonalDataContainer>
                                                <AuthFormTitle>Personal Data</AuthFormTitle>

                                                <div>When you register as a user of our Site and Services, we ask for personal information that will be used to activate your account.</div>

                                                <AuthButton onClick={downloadPersonalDataHandler}>
                                                    DOWNLOAD PERSONAL DATA
                                                </AuthButton>
                                                <ProfileDeleteButton type="submit" data-bs-toggle="modal" data-bs-target="#exampleModal">
                                                    DELETE ACCOUNT & DATA
                                                </ProfileDeleteButton>

                                            </ProfilePersonalDataContainer>
                                        </>
                                    )}
                                </ProfileInfoContainer>
                            </div>
                        )}
                    </ProfileGrid>
                </InputFormContainer>
            </InputContainer>
            <div className="modal fade" id="exampleModal" tabIndex={-1} aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title" id="exampleModalLabel">Confirmation</h5>
                        <button type="button" className="btn btn-danger" data-bs-dismiss="modal" aria-label="Close">X</button>
                    </div>
                    <div className="modal-body">
                       This action is permanent and CANNOT be undone. Enter your password below to proceed:

                       <input
                        className='form-control'
                        type='password'
                        value={deleteAccountPassword}
                        onChange={(e) => setDeleteAccountPassword(e.target.value)} />
                    </div>
                    <div className="modal-footer">
                        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={deleteAccountHandler}>Yes</button>
                    </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Profile