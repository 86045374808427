import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { Link, useNavigate, useSearchParams } from 'react-router-dom'
import { toast } from 'react-toastify';
import { AuthForm, AuthFormLink, AuthFormTitle, StyledAuthFormLink } from '../styles/Form.styled';
import { AuthInput } from '../styles/Input.styled';
import { AuthButton } from '../styles/Button.styled';
import { AuthSpan, AuthSvg } from '../styles/Span.styled';
import { AuthInputContainer } from '../styles/Container.styled';
import { clearErrors, confirmEmail, loginUser } from '../../features/auth/authSlice'
import Loader from '../layout/Loader';

type Props = {}

const ConfirmEmail = (props: Props) => {
    const [confirmEmailRequested, setConfirmedEmailRequested] = useState(false);
    const [searchParams] = useSearchParams();

    const { loading, confirmedEmail, errors } = useAppSelector((state) => state.auth);

    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    useEffect(() => {
        // if (isAuthenticated) {
        //     navigate("/");
        //     toast.dismiss();
        //     toast.success("Login successful");
        // }

        if (!confirmEmailRequested) {
            dispatch(confirmEmail({ userId: searchParams.get("userId") ?? '', token: searchParams.get("token") ?? '' }))
            setConfirmedEmailRequested(true);
        }

        if (typeof errors === 'string') {
            toast.dismiss();
            toast.error(errors);
            dispatch(clearErrors());
        }
    }, [confirmEmailRequested, dispatch, errors, navigate, searchParams])



    return (
        <>
            <div>
                <AuthForm>
                    <AuthFormTitle>Confirm email</AuthFormTitle>
                    {loading ? <Loader /> :
                        <>
                            {confirmedEmail ? (
                                <>Thanks for confirming your email. Click <StyledAuthFormLink to={'/login'}>here</StyledAuthFormLink> to login</>
                            ) : (
                                <>Error: The link is invalid</>
                            )}

                        </>
                    }
                </AuthForm>

            </div >
        </>
    )
}

export default ConfirmEmail