import React, { useEffect, useState } from 'react'
import { InputContainer, InputFormContainer, InputOutletContainer, InputSection, MobileTableContainer, WideTableContainer } from '../styles/Container.styled'
import { InputFormHeading, InputMainHeadingContainer } from '../styles/Input.styled'
import { MDBDataTable } from 'mdbreact';
import { useAppDispatch, useAppSelector } from '../../hooks';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import Loader from '../layout/Loader';
import { clearErrors, allUsers, reset, deleteUser, changeUserLockStatus, revokeAdminRights, grantAdminRights } from '../../features/auth/userSlice';
import { AddMainRecordButton, StyledLink } from '../styles/Button.styled';
import { InputForm } from '../styles/Form.styled';
import { IUser } from '../../features/auth/authSlice.interfaces';

type Props = {}

type IUserWideData = {
    columns: {
        label: string,
        field: string,
        sort: string
    }[],
    rows: {
        email: string,
        membershipNumber: string,
        role: string,
        isLockedOut: string,
        actions: React.ReactNode
    }[]
}

type IUserMobileData = {
    columns: {
        label: string,
        field: string,
        sort: string
    }[],
    rows: {
        email: string,
        membershipNumber: string,
        role: string,
        actions: React.ReactNode
    }[]
}

const Users = (props: Props) => {

    const [selectedUserForDelete, setSelectedUserForDelete] = useState("");
    const [selectedUserEmailForDelete, setSelectedUserEmailForDelete] = useState("");
    const [selectedUserForLockChange, setSelectedUserForLockChange] = useState("");
    const [selectedUserForLockChangeStatus, setSelectedUserForLockChangeStatus] = useState(false);
    const [selectedUserEmailForLockChange, setSelectedUserEmailForLockChange] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [pulledData, setPulledData] = useState(false);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const { user } = useAppSelector(state => state.auth);
    const { loading, users, errors, isDeleted, isUpdated, paginationMetaData, success } = useAppSelector(state => state.user);

    useEffect(() => {
        if (!pulledData) {
            dispatch(allUsers({ PageNumber: currentPage }));
            setPulledData(true);
        }

        if (success) {
            toast.dismiss();
            toast.success("User modified successfully");
            dispatch(reset())
            dispatch(allUsers({ PageNumber: currentPage }));
        }

        if (isDeleted) {
            toast.dismiss();
            toast.success("User deleted successfully");
            dispatch(reset())
            dispatch(allUsers({ PageNumber: currentPage }));
        }

        if (isUpdated) {
            toast.dismiss();
            toast.success("User updated successfully");
            dispatch(reset())
            dispatch(allUsers({ PageNumber: currentPage }));
        }

        if (paginationMetaData) {
            setCurrentPage(paginationMetaData.currentPage)
        }

        if (errors) {
            toast.error(String(errors))
            dispatch(clearErrors)
        }

    }, [dispatch, errors, isDeleted, isUpdated, pulledData, paginationMetaData, success])

    const isAdmin = (user: IUser) => {
        return user?.userRoles === "Admin" || user?.roles?.includes("Admin");
    }

    const handlerNextPage = () => {
        if (paginationMetaData && (paginationMetaData?.currentPage < paginationMetaData?.totalPages)) {
            toast.dismiss();
            toast.info("Retrieving information...");
            dispatch(allUsers({ PageNumber: currentPage + 1 }));
        }
    }

    const handlerPrevPage = () => {
        if (paginationMetaData && (paginationMetaData?.currentPage > 1)) {
            toast.dismiss();
            toast.info("Retrieving information...");
            dispatch(allUsers({ PageNumber: currentPage - 1 }));
        }
    }

    const revokeAdminHandler = (id: string) => {
        toast.info("Revoking admin rights...");
        dispatch(revokeAdminRights({ userId: id }));
    }

    const grantAdminHandler = (id: string) => {
        toast.info("Grant admin rights...");
        dispatch(grantAdminRights({ userId: id }));
    }

    const Actions = (props: { element: IUser }) => {
        return (
            <>
                <div className="dropdown">
                    <button className="btn btn-secondary" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                        <i className="fa-solid fa-ellipsis"></i>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        {/* <li>
                            <StyledLink to={`#`} className="py-1 px-1 ml-2">
                                <i className="fa-solid fa-pen-to-square mx-2 my-2" style={{ color: "#000" }}></i>Edit User
                            </StyledLink>
                        </li> */}
                        <li>
                            <StyledLink to={`/holdings/${props.element.id}`} className="py-1 px-1 ml-2">
                                <i className="fa-solid fa-list mx-2 my-2" style={{ color: "#000" }}></i>User Holdings
                            </StyledLink>
                        </li>
                        {!isAdmin(props.element) && (
                            <>
                                {props.element.isLockedOut ? (
                                    <li>
                                        <StyledLink to={`#`} onClick={() => changeSelectedUserLockStatus(props.element.id, props.element.isLockedOut)} className="py-1 px-1 ml-2">
                                            <i className="fa-solid fa-lock-open mx-2 my-2" style={{ color: "#000" }}></i>Unlock User
                                        </StyledLink>
                                    </li>
                                ) : (
                                    <li>
                                        <StyledLink to={`#`} onClick={() => changeSelectedUserLockStatus(props.element.id, props.element.isLockedOut)} className="py-1 px-1 ml-2">
                                            <i className="fa-solid fa-lock mx-2 my-2" style={{ color: "#000" }}></i>Lock User
                                        </StyledLink>
                                    </li>
                                )}
                            </>
                        )}

                        {!isAdmin(props.element) && (
                            <li>
                                <StyledLink to={"#"} onClick={() => updateSelectedUserForDelete(props.element.id, props.element.email)} className="py-1 px-1 ml-2 border-none cursor-pointer" data-bs-toggle="modal" data-bs-target="#exampleModalCenter">
                                    <i className="fa-solid fa-trash mx-2 my-2" style={{ color: "crimson" }}></i> Delete User
                                </StyledLink>
                            </li>
                        )}

                        {isAdmin(props.element) ? (
                            <li>
                                <StyledLink to={"#"} onClick={() => revokeAdminHandler(props.element.id)} className="py-1 px-1 ml-2 border-none cursor-pointer">
                                    <i className="fa-solid fa-user-minus mx-2 my-2" style={{ color: "crimson" }}></i> Revoke Admin
                                </StyledLink>
                            </li>
                        ) : (
                            <li>
                                <StyledLink to={"#"} onClick={() => grantAdminHandler(props.element.id)} className="py-1 px-1 ml-2 border-none cursor-pointer">
                                    <i className="fa-solid fa-user-plus mx-2 my-2" style={{ color: "blue" }}></i> Grant Admin
                                </StyledLink>
                            </li>
                        )}
                    </ul >
                </div >
            </>
        )
    }

    const setWideUsers = () => {
        const data: IUserWideData = {
            columns: [
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc'
                },
                {
                    label: 'Membership Number',
                    field: 'membershipNumber',
                    sort: 'asc'
                },
                {
                    label: 'Role',
                    field: 'role',
                    sort: 'asc'
                },
                {
                    label: 'Lock Status',
                    field: 'isLockedOut',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'asc'
                }

            ],
            rows: []
        }

        users && users.forEach(element => {
            data.rows.push({
                isLockedOut: String(element.isLockedOut),
                email: element.email,
                role: element.roles.join(", "),
                membershipNumber: element.membershipNumber,
                actions: <Actions element={element} />
            })
        });

        return data;
    }

    const setMobileUsers = () => {
        const data: IUserMobileData = {
            columns: [
                {
                    label: 'Email',
                    field: 'email',
                    sort: 'asc'
                },
                {
                    label: 'Membership Number',
                    field: 'membershipNumber',
                    sort: 'asc'
                },
                {
                    label: 'Role',
                    field: 'role',
                    sort: 'asc'
                },
                {
                    label: 'Actions',
                    field: 'actions',
                    sort: 'asc'
                }

            ],
            rows: []
        }

        users && users.forEach(element => {
            data.rows.push({
                email: element.email,
                role: element.roles.toString(),
                membershipNumber: element.membershipNumber,
                actions: <Actions element={element} />
            })
        });

        return data;
    }

    const updateSelectedUserForDelete = (id: string, email: string) => {
        setSelectedUserForDelete(id);
        setSelectedUserEmailForDelete(email);
    }


    const deleteSelectedUser = () => {
        if (selectedUserForDelete !== "") {
            toast.info("Deleting user...")
            dispatch(deleteUser({ userId: selectedUserForDelete }))
        }
    }

    const changeSelectedUserLockStatus = (id: string, lockStatus: boolean) => {
        if (lockStatus) toast.info("Unlocking user...")
        else toast.info("Locking user...")
        dispatch(changeUserLockStatus({ id, lockStatus }))
    }

    return (
        <>
            <InputContainer>
                <InputMainHeadingContainer><h1>Stud Farm Carbon Calculator</h1></InputMainHeadingContainer>
                <InputFormContainer>
                    <InputFormHeading>Users List</InputFormHeading>

                    <InputForm>

                        <div className='my-3'>
                            {loading ? <Loader /> : (
                                <>
                                    <WideTableContainer>
                                        <MDBDataTable
                                            data={setWideUsers()}
                                            borderless
                                            hover
                                            noBottomColumns
                                            striped
                                            displayEntries={false}
                                            paging={false}
                                        />
                                    </WideTableContainer>
                                    <MobileTableContainer>
                                        <MDBDataTable
                                            data={setMobileUsers()}
                                            borderless
                                            hover
                                            noBottomColumns
                                            striped
                                            displayEntries={false}
                                            paging={false}
                                        />
                                    </MobileTableContainer>
                                </>
                            )}
                        </div>
                    </InputForm>

                    {paginationMetaData && (
                        <>
                            <button className='nav-btn back-btn' disabled={paginationMetaData.currentPage === 1} onClick={handlerPrevPage}>Back</button>
                            <div className='pagination-info'>
                                <div>
                                    <div>{paginationMetaData.currentPage} / {paginationMetaData.totalPages}</div>
                                </div>
                            </div>
                            <button className='nav-btn next-btn' disabled={paginationMetaData.currentPage === paginationMetaData.totalPages} onClick={handlerNextPage}>Next</button>
                        </>
                    )}


                </InputFormContainer>
            </InputContainer>
            <div className="modal fade" id="exampleModalCenter" tabIndex={-1} role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" id="exampleModalLongTitle">Are you sure?</h5>
                            <button type="button" className="btn btn-secondary close" data-bs-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p>This action <strong>CANNOT</strong> be undone. This would permanently delete the user - {selectedUserEmailForDelete}.</p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-outline-secondary" data-bs-dismiss="modal">Back</button>
                            <button type="button" className="btn btn-danger" onClick={deleteSelectedUser} data-bs-dismiss="modal">Yes, Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Users