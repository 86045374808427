import React, { useEffect } from 'react';
import { Formik } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, InputMainHeadingContainer, Select } from '../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../styles/Button.styled';
import { AccountingPeriodLabel } from '../styles/Label.styled';
import { IHoldingValues } from '../../features/footprint/holdingSlice.interfaces';
import { IHoldingInformationErrors } from './Footprint.interfaces';
import { IDropdownValue } from '../../features/operations/operationsSlice.interfaces';
import { useNavigate } from 'react-router-dom';
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css'; // optional for styling

type Props = {
    initialValues?: IHoldingValues,
    submitHandler: (val: IHoldingValues) => void,
    dropdowns?: IDropdownValue[]
    submitText: string
}

const initialValues = {
    region: '',
    systemDescription: '',
    reference: ''
}

const HoldingForm = (props: Props) => {

    const navigate = useNavigate();

    const dropdowns = props.dropdowns;

    tippy('#holding-reference-tip', {
        content: "The name of the holding (stud farm) you are inputting data for. This should be a clear and memorable name – e.g. region and stud farm name.",
    });
    tippy('#holding-region-tip', {
        content: "Select the geographical region that the holding is in. This is  used to estimate average rainfall, and also for benchmarking. The map of regional aggregated weather data can be found here https://www.metoffice.gov.uk/research/climate/maps-and-data/about/districts-map.",
    });
    tippy('#holding-desc-tip', {
        content: "System description is used in the benchmarking part of the calculator to enable you to compare your results to your peers. Use option that most closely resembles your system and those that you would consider to be similar.",
    });

    return (
        <div>
            <Formik
                initialValues={props.initialValues ? { ...props.initialValues, systemDescription: props.initialValues.systemDescriptionKey, region: props.initialValues.regionKey } : initialValues}
                validate={values => {
                    const errors: IHoldingInformationErrors = {};
                    if (!values.region) {
                        errors.region = 'Required';
                    }
                    if (!values.systemDescription) {
                        errors.systemDescription = 'Required';
                    }
                    if (!values.reference) {
                        errors.reference = 'Required';
                    }
                    return errors;
                }}
                onSubmit={(values, { setSubmitting }) => {
                    setTimeout(() => {
                        // console.log(values)
                        props.submitHandler(values)
                        setSubmitting(false);
                    }, 400);
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (

                    <InputForm onSubmit={handleSubmit}>

                        <InputFormDescription>
                            Please provide details about your holding (stud farm) including name and region.  If you have more than one holding as part of your enterprise set up separate sheets for each one. <br />

                            Select the description that most closely resembles your enterprise.  This will be used for benchmarking purposes to enable your data to be compare to others of a similar type.<br />

                            Your data will only be used in accordance with the TBA terms and conditions available <a href=''>HERE</a>.<br />

                            Note – Benchmarking functionality is not currently available – it will be released in the spring once there are sufficient users of the calculator to enable benchmarking to occur.<br />

                        </InputFormDescription>

                        <StandardInputGroup>
                            <label>
                                Reference
                            </label>

                            <div className='d-flex align-items-center'>
                                <div className='mx-2'>
                                    <Input
                                        name="reference"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.reference}
                                        className='form-control'
                                        data-role='none'
                                    />
                                    <ErrorText>{errors.reference && touched.reference && errors.reference}</ErrorText>
                                </div>
                                <UtilityButton id='holding-reference-tip' src="/icons/tooltip.png" alt='Tooltip' />
                            </div>
                        </StandardInputGroup>

                        <StandardInputGroup>
                            <label>
                                Region
                            </label>

                            <div className='d-flex align-items-center'>
                                <div className='mx-2'>
                                    <Select
                                        name="region"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.region}
                                        className='form-control'
                                        data-role='none'
                                    >
                                        <option value="">--Please choose an option--</option>
                                        {dropdowns?.filter(element => element.theme === "RegionList").map(value => {
                                            return <option key={value.key} value={value.key}>{value.description}</option>
                                        })}
                                    </Select>
                                    <ErrorText>{errors.region && touched.region && errors.region}</ErrorText>
                                </div>
                                <UtilityButton id='holding-region-tip' src="/icons/tooltip.png" alt='Tooltip' />
                            </div>
                        </StandardInputGroup>

                        <StandardInputGroup>
                            <label>
                                Brief description of system
                            </label>

                            <div className='d-flex align-items-center'>
                                <div className='mx-2'>
                                    <Select
                                        name="systemDescription"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.systemDescription}
                                        className='form-control'
                                        data-role='none'
                                    >
                                        <option value="">--Please choose an option--</option>
                                        {dropdowns?.filter(element => element.theme === "FarmType").map(value => {
                                            return <option key={value.key} value={value.key}>{value.description}</option>
                                        })}
                                    </Select>
                                    <ErrorText>{errors.systemDescription && touched.systemDescription && errors.systemDescription}</ErrorText>
                                </div>
                                <UtilityButton id='holding-desc-tip' src="/icons/tooltip.png" alt='Tooltip' />
                            </div>
                        </StandardInputGroup>

                        <div className='d-flex w-100 justify-content-center'>
                            <InputFormButtonsContainer>
                                <InputFormButton onClick={() => navigate("/holdings")}>
                                    Back
                                </InputFormButton>

                                <InputFormSaveButton type="submit" disabled={isSubmitting}>
                                    {props.submitText}
                                </InputFormSaveButton>
                            </InputFormButtonsContainer>
                        </div>
                    </InputForm>
                )}
            </Formik>

        </div>
    )
}

export default HoldingForm