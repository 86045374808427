import styled from 'styled-components'
import { fadeInFwd } from './Container.styled';


export const WideLandingImage = styled.img`
    width: 100%;
    height: 100%;
`;

export const LandingPageCardContainerImage = styled.img`
    height: 300px;
    object-fit: cover;
`;

export const LandingPageLogo = styled.img`
    width: 300px;
    height: 300px;
    margin-bottom: 30px;
    animation: ${fadeInFwd} 1s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
`;