import React, { FormEvent, useEffect } from 'react'
import { InputSubContainerRow, InputSubContainer } from '../../styles/Container.styled'
import { UtilityButton } from '../../styles/Button.styled'
import { useField } from 'formik'
import { ErrorText } from '../../styles/Form.styled'
import { useAppSelector } from '../../../hooks'
import tippy from 'tippy.js'

type Props = {
    landUseRecordIndex: number,
    fertiliserIndex: number,
    landRecordId: string,
    errors: any,
    touched: any,
    deleteManufacturedFertilizerHandler: (a: string, b: string) => void
    updateManufacturedFertiliserHandler: (id: string, fertiliserId: string, fertiliserKey: string) => void,
    inputIsLocked: () => boolean
}

const ManufacturedFertiliser = (props: Props) => {

    const [productField, productMeta, productHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].product`);
    const [productNameField, productNameMeta, productNameHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].productName`);
    const [nitrogenContentField, nitrogenContentMeta, nitrogenContentHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].nitrogenContentPercentage`);
    const [phosphateContentField, phosphateContentMeta, phosphateContentHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].phosphateContentPercentage`);
    const [potassiumContentField, potassiumContentMeta, potassiumContentHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].potassiumContentPercentage`);
    const [metricField, metricMeta, metricHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].metric`);
    const [tonnageField, tonnageMeta, tonnageHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].totalTonnageProduced`);
    const [rateUnitField, rateUnitMeta, rateUnitHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].applicationRateUnit`);
    const [avgRateField, avgRateMeta, avgRateHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].averageApplicationRate`);
    const [idField, idMeta, idHelpers] = useField(`landUse[${props.landUseRecordIndex}].manufacturedFertilisersApplied[${props.fertiliserIndex}].id`);


    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    useEffect(() => {
        if (productField.value) props.updateManufacturedFertiliserHandler(props.landRecordId, idField.value, productField.value)


    }, [productField.value])

    const onProductNameInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        productNameHelpers.setValue(target.value);
    };
    const onProductNameBlur = () => {
        productNameHelpers.setTouched(true);
    };

    const onProductInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        productHelpers.setValue(target.value);
    };
    const onProductBlur = () => {
        productHelpers.setTouched(true);
    };

    const onNitrogenContentInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        nitrogenContentHelpers.setValue(target.value);
    };
    const onNitrogenContentBlur = () => {
        nitrogenContentHelpers.setTouched(true);
    };

    const onPhosphateContentInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        phosphateContentHelpers.setValue(target.value);
    };
    const onPhosphateContentBlur = () => {
        phosphateContentHelpers.setTouched(true);
    };

    const onPotassiumContentInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        potassiumContentHelpers.setValue(target.value);
    };
    const onPotassiumContentBlur = () => {
        potassiumContentHelpers.setTouched(true);
    };

    const onMetricInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        metricHelpers.setValue(target.value);
    };
    const onMetricBlur = () => {
        metricHelpers.setTouched(true);
    };

    const onTonnageInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        tonnageHelpers.setValue(target.value);
    };
    const onTonnageBlur = () => {
        tonnageHelpers.setTouched(true);
    };

    const onRateUnitInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        rateUnitHelpers.setValue(target.value);
    };
    const onRateUnitBlur = () => {
        rateUnitHelpers.setTouched(true);
    };

    const onAvgRateInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        avgRateHelpers.setValue(target.value);
    };
    const onAvgRateBlur = () => {
        avgRateHelpers.setTouched(true);
    };

    return (
        <InputSubContainer>

            <InputSubContainerRow>
                <div>
                    <label>Product</label>
                    <select
                        value={productField.value}
                        onChange={onProductInput}
                        onBlur={onProductBlur}
                        className='form-control'
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "ManufacturedFertiliser").map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].product && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].product
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].product}
                    </ErrorText>
                </div>

                {productField.value === "OtherManufacturedFertiliser" && (
                    <div>
                        <label>Name of product</label>
                        <input
                            type="text"
                            value={productNameField.value}
                            onChange={onProductNameInput}
                            onBlur={onProductNameBlur}
                            className='form-control'
                            disabled={props.inputIsLocked()}
                        />
                        <ErrorText className='text-center'>
                            {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex]
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].productName && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].productName
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].productName}
                        </ErrorText>
                    </div>
                )}

                <div className="d-flex">
                    <UtilityButton className='land-use-manu-fert-inputs-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                    <UtilityButton src="/icons/delete.png" alt='delete' onClick={() => props.deleteManufacturedFertilizerHandler(props.landRecordId, idField.value)} />
                </div>
            </InputSubContainerRow>
            <InputSubContainerRow>
                <div>
                    <label>Nitrogen content(%)</label>
                    <input
                        type="number"
                        value={nitrogenContentField.value}
                        onChange={onNitrogenContentInput}
                        className='form-control'
                        onBlur={onNitrogenContentBlur}
                        disabled={props.inputIsLocked()} />
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].nitrogenContentPercentage && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].nitrogenContentPercentage
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].nitrogenContentPercentage}
                    </ErrorText>
                </div>
                <div>
                    <label>Phosphate content(%)</label>
                    <input
                        type="number"
                        value={phosphateContentField.value}
                        onChange={onPhosphateContentInput}
                        className='form-control'
                        onBlur={onPhosphateContentBlur}
                        disabled={props.inputIsLocked()} />
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].phosphateContentPercentage && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].phosphateContentPercentage
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].phosphateContentPercentage}
                    </ErrorText>
                </div>
                <div>
                    <label>Potassium content(%)</label>
                    <input
                        type="number"
                        value={potassiumContentField.value}
                        onChange={onPotassiumContentInput}
                        className='form-control'
                        onBlur={onPotassiumContentBlur}
                        disabled={props.inputIsLocked()} />
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].potassiumContentPercentage && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].potassiumContentPercentage
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].potassiumContentPercentage}
                    </ErrorText>
                </div>
            </InputSubContainerRow>
            <InputSubContainerRow>
                <div>
                    <label>Metric</label>
                    <select
                        value={metricField.value}
                        className='form-control'
                        onChange={onMetricInput}
                        onBlur={onMetricBlur}
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "Metric" && element.key !== "Bale").map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex]
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].metric && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].metric
                            && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].metric}
                    </ErrorText>
                </div>

                {metricField.value === 'Quantity' && (
                    <div>
                        <label>Total tonnage purchased (t)</label>
                        <input
                            type="number"
                            value={tonnageField.value}
                            className='form-control'
                            onChange={onTonnageInput}
                            onBlur={onTonnageBlur}
                            disabled={props.inputIsLocked()} />
                        <ErrorText className='text-center'>
                            {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex]
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].totalTonnageProduced && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].totalTonnageProduced
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].totalTonnageProduced}
                        </ErrorText>
                    </div>
                )}
            </InputSubContainerRow>

            {metricField.value === 'ApplicationRate' && (
                <InputSubContainerRow>
                    <div>
                        <label>Application rate units</label>
                        <select
                            value={rateUnitField.value}
                            onChange={onRateUnitInput}
                            className='form-control'
                            onBlur={onRateUnitBlur}
                            disabled={props.inputIsLocked()}
                        >
                            <option value="">--Please choose an option--</option>
                            {dropdowns?.filter(element => element.theme === "ApplicationRateUnits").map(value => {
                                return <option key={value.key} value={value.key}>{value.description}</option>
                            })}
                        </select>
                        <ErrorText className='text-center'>
                            {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex]
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].applicationRateUnit && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].applicationRateUnit
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].applicationRateUnit}
                        </ErrorText>
                    </div>
                    <div>
                        <label>Average application rate</label>
                        <input
                            type="number"
                            value={avgRateField.value}
                            className='form-control'
                            onChange={onAvgRateInput}
                            onBlur={onAvgRateBlur}
                            disabled={props.inputIsLocked()} />
                        <ErrorText className='text-center'>
                            {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex] && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex]
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].averageApplicationRate && touched.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].averageApplicationRate
                                && errors.landUse[props.landUseRecordIndex].manufacturedFertilisersApplied[props.fertiliserIndex].averageApplicationRate}
                        </ErrorText>
                    </div>
                </InputSubContainerRow>
            )}


        </InputSubContainer>
    )
}

export default ManufacturedFertiliser