import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../hooks';
import { useNavigate, Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { registerUser, clearErrors, resendConfirmationEmail } from '../../features/auth/authSlice';
import { AuthForm, AuthFormLink, AuthFormTitle, StyledAuthFormLink } from '../styles/Form.styled';
import { AuthInput } from '../styles/Input.styled';
import { AuthButton } from '../styles/Button.styled';
import { AuthSpan, AuthSvg } from '../styles/Span.styled';
import { AuthInputContainer } from '../styles/Container.styled';

type Props = {}

const Register = (props: Props) => {

    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [membershipNumber, setMembershipNumber] = useState("");
    const [acceptTerms, setAcceptTerms] = useState(false);

    const { success, user, loading, errors, message } = useAppSelector((state) => state.auth);

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        if (message) {
            navigate("/");
            toast.dismiss();
            toast.success(message);
        }
        
        // handle and display errors
        if (errors) {
            toast.dismiss();
            console.log(errors)
            if (!Array.isArray(errors)) {
                for (const key in errors) {
                    var messages = errors[key as keyof typeof errors] as any;

                    messages.forEach((message: string) => {
                        toast.error(message);
                    })
                }
            } else {
                errors.forEach(error => {
                    toast.error(error.description);
                })
            }
            dispatch(clearErrors());
        }
    }, [dispatch, errors, navigate, message])

    const togglePassword = () => {
        var x = document.getElementById("password") as HTMLInputElement | null;
        if (x != null) {
            if (x.type === "password") {
                x.type = "text";
            } else {
                x.type = "password";
            }
        }
    }

    const submitHandler = (e: React.FormEvent) => {
        e.preventDefault();

        toast.info("Creating account...", {
            autoClose: false,
        });
        dispatch(registerUser({ email, password, acceptTerms, membershipNumber }));
    }

    return (
        <>
            <div>
                <AuthForm>
                    <AuthFormTitle>Create your account</AuthFormTitle>
                    <AuthInputContainer>
                        <AuthInput placeholder="Enter email" data-role="none" type="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                        <AuthSpan>
                            <AuthSvg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M16 12a4 4 0 10-8 0 4 4 0 008 0zm0 0v1.5a2.5 2.5 0 005 0V12a9 9 0 10-9 9m4.5-1.206a8.959 8.959 0 01-4.5 1.207" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                            </AuthSvg>
                        </AuthSpan>
                    </AuthInputContainer>
                    <AuthInputContainer>
                        <AuthInput placeholder="Enter membership number" data-role="none" type="text" value={membershipNumber} onChange={(e) => setMembershipNumber(e.target.value)} />
                        <AuthSpan>
                        </AuthSpan>
                    </AuthInputContainer>
                    <AuthInputContainer>
                        <AuthInput placeholder="Enter password" data-role="none" type="password" value={password} onChange={(e) => setPassword(e.target.value)} id='password' />
                        <AuthSpan onClick={togglePassword} style={{ cursor: "pointer" }}>
                            <AuthSvg stroke="currentColor" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                                <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z" strokeWidth="2" strokeLinejoin="round" strokeLinecap="round"></path>
                            </AuthSvg>
                        </AuthSpan>
                    </AuthInputContainer>
                    <div className='d-flex justify-content-between align-items-center'>
                        <p className='m-0'><small>I accept the <a href='/documents/DraftTBATermsAndConditions.pdf' download target="_blank" rel="noreferrer"> terms & conditions </a></small></p>
                        <input type="checkbox" checked={acceptTerms} onChange={(e) => setAcceptTerms(e.target.checked)} />
                    </div>
                    <AuthButton type="submit" onClick={submitHandler}>
                        Sign up
                    </AuthButton>

                    <AuthFormLink>
                        Already have an account?
                        <StyledAuthFormLink to={'/login'}>  Sign in</StyledAuthFormLink>
                    </AuthFormLink>
                </AuthForm>
            </div>
        </>
    )
}

export default Register