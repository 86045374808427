import React, { FormEvent, useEffect } from 'react'
import { InputSubContainerRow, InputSubContainer } from '../../styles/Container.styled'
import { UtilityButton } from '../../styles/Button.styled'
import { useField } from 'formik'
import { ErrorText } from '../../styles/Form.styled'
import { useAppSelector } from '../../../hooks'
import tippy from 'tippy.js'

type Props = {
    landUseRecordIndex: number,
    forageIndex: number,
    landRecordId: string,
    errors: any,
    touched: any,
    deleteForageHarvestedHandler: (a: string, b: string) => void,
    inputIsLocked: () => boolean
}

const ForageHarvested = (props: Props) => {
    const [idField, idMeta, idHelpers] = useField(`landUse[${props.landUseRecordIndex}].forageHarvested[${props.forageIndex}].id`);
    const [forageTypeField, forageTypeMeta, forageTypeHelpers] = useField(`landUse[${props.landUseRecordIndex}].forageHarvested[${props.forageIndex}].forageType`);
    const [metricField, metricMeta, metricHelpers] = useField(`landUse[${props.landUseRecordIndex}].forageHarvested[${props.forageIndex}].metric`);
    const [totalTonnageProducedField, totalTonnageProducedMeta, totalTonnageProducedHelpers] = useField(`landUse[${props.landUseRecordIndex}].forageHarvested[${props.forageIndex}].totalTonnageProduced`);
    const [balePlasticWasteDisposalMethodField, balePlasticWasteDisposalMethodMeta, balePlasticWasteDisposalMethodHelpers] = useField(`landUse[${props.landUseRecordIndex}].forageHarvested[${props.forageIndex}].balePlasticWasteDisposalMethod`);
    const [baleTypeField, baleTypeMeta, baleTypeHelpers] = useField(`landUse[${props.landUseRecordIndex}].forageHarvested[${props.forageIndex}].baleType`);
    const [numberOfBalesField, numberOfBalesMeta, numberOfBalesHelpers] = useField(`landUse[${props.landUseRecordIndex}].forageHarvested[${props.forageIndex}].numberOfBales`);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);
    const { footprint } = useAppSelector(state => state.footprint);



    const onForageTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        forageTypeHelpers.setValue(target.value);
    };
    const onForageTypeBlur = () => {
        forageTypeHelpers.setTouched(true);
    };

    const onMetricInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        metricHelpers.setValue(target.value);
    };
    const onMetricBlur = () => {
        metricHelpers.setTouched(true);
    };

    const onTotalTonnageInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        totalTonnageProducedHelpers.setValue(target.value);
    };
    const onTotalTonnageBlur = () => {
        totalTonnageProducedHelpers.setTouched(true);
    };

    const onPlasticWasteDisposalInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        balePlasticWasteDisposalMethodHelpers.setValue(target.value);
    };
    const onPlasticWasteDisposalBlur = () => {
        balePlasticWasteDisposalMethodHelpers.setTouched(true);
    };

    const onBaleTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        baleTypeHelpers.setValue(target.value);
    };
    const onBaleTypeBlur = () => {
        baleTypeHelpers.setTouched(true);
    };

    const onNumberOfBalesInput = (e: FormEvent) => {
        let target = e.target as HTMLInputElement
        numberOfBalesHelpers.setValue(target.value);
    };
    const onNumberOfBalesBlur = () => {
        numberOfBalesHelpers.setTouched(true);
    };
    

    return (
        <InputSubContainer>
            <InputSubContainerRow>
                <div>
                    <label>Forage Type</label>
                    <select
                        className='form-control'
                        value={forageTypeField.value}
                        onChange={onForageTypeInput}
                        onBlur={onForageTypeBlur}
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        <option value="HayMeadow">Meadow Hay</option>
                        <option value="HaySeed">Seed Hay</option>
                        <option value="Haylage">Haylage</option>
                    </select>
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].forageHarvested && touched.landUse[props.landUseRecordIndex].forageHarvested
                            && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex] && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex]
                            && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].forageType && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].forageType
                            && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].forageType}
                    </ErrorText>
                </div>
                <div>
                    <label>Bale plastic waste disposal method</label>
                    <select
                        className='form-control'
                        value={balePlasticWasteDisposalMethodField.value}
                        onChange={onPlasticWasteDisposalInput}
                        onBlur={onPlasticWasteDisposalBlur}
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "PackagingDisposalOption").map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].forageHarvested && touched.landUse[props.landUseRecordIndex].forageHarvested
                            && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex] && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex]
                            && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].balePlasticWasteDisposalMethod && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].balePlasticWasteDisposalMethod
                            && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].balePlasticWasteDisposalMethod}
                    </ErrorText>
                </div>
                <div className="d-flex">
                    <UtilityButton className='land-use-forage-inputs-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                    <UtilityButton src="/icons/delete.png" alt='delete' onClick={() => props.deleteForageHarvestedHandler(props.landRecordId, idField.value)} />
                </div>
            </InputSubContainerRow>
            <InputSubContainerRow>
                <div>
                    <label>Yield metric</label>
                    <select
                        className='form-control'
                        value={metricField.value}
                        onChange={onMetricInput}
                        onBlur={onMetricBlur}
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "Metric" && element.key !== "ApplicationRate").map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText className='text-center'>
                        {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                            && errors.landUse[props.landUseRecordIndex].forageHarvested && touched.landUse[props.landUseRecordIndex].forageHarvested
                            && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex] && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex]
                            && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].metric && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].metric
                            && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].metric}
                    </ErrorText>
                </div>
                {metricField.value === 'Quantity' && (
                    <div>
                        <label>Tonnage produced on-site (t)</label>
                        <input
                            className='form-control'
                            type="number"
                            value={totalTonnageProducedField.value}
                            onChange={onTotalTonnageInput}
                            onBlur={onTotalTonnageBlur}
                            disabled={props.inputIsLocked()}
                        />
                        <ErrorText className='text-center'>
                            {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                                && errors.landUse[props.landUseRecordIndex].forageHarvested && touched.landUse[props.landUseRecordIndex].forageHarvested
                                && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex] && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex]
                                && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].totalTonnageProduced && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].totalTonnageProduced
                                && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].totalTonnageProduced}
                        </ErrorText>
                    </div>
                )}
                {metricField.value === 'Bale' && (
                    <>
                        <div>
                            <label>Type of Bales</label>
                            <select
                                className='form-control'
                                value={baleTypeField.value}
                                onChange={onBaleTypeInput}
                                onBlur={onBaleTypeBlur}
                                disabled={props.inputIsLocked()}
                            >
                                <option value="">--Please choose an option--</option>
                                {dropdowns?.filter(element => element.theme === "BaleType").map(value => {
                                    return <option key={value.key} value={value.key}>{value.description}</option>
                                })}
                            </select>
                            <ErrorText className='text-center'>
                                {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                                    && errors.landUse[props.landUseRecordIndex].forageHarvested && touched.landUse[props.landUseRecordIndex].forageHarvested
                                    && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex] && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex]
                                    && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].baleType && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].baleType
                                    && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].baleType}
                            </ErrorText>
                        </div>
                    </>
                )}


            </InputSubContainerRow>
            {metricField.value === 'Bale' && (
                <InputSubContainerRow>
                    <div>
                        <label>Number of bales produced on site</label>
                        <input
                            className='form-control'
                            type="number"
                            value={numberOfBalesField.value}
                            onChange={onNumberOfBalesInput}
                            onBlur={onNumberOfBalesBlur}
                            disabled={props.inputIsLocked()}
                        />
                        <ErrorText className='text-center'>
                            {errors.landUse && errors.landUse[props.landUseRecordIndex] && touched.landUse && touched.landUse[props.landUseRecordIndex]
                                && errors.landUse[props.landUseRecordIndex].forageHarvested && touched.landUse[props.landUseRecordIndex].forageHarvested
                                && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex] && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex]
                                && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].numberOfBales && touched.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].numberOfBales
                                && errors.landUse[props.landUseRecordIndex].forageHarvested[props.forageIndex].numberOfBales}
                        </ErrorText>
                    </div>
                </InputSubContainerRow>
            )}
        </InputSubContainer>
    )
}

export default ForageHarvested