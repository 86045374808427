import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import axios, { AxiosError } from "axios";
import { IAdminDetailSummaryFilters, IBenchmarkingFilters, IElectricityAndFuelData, IFeedAndBeddingData, IFootprintData, IFootprintState, IHorseDetailsData, ILandUseData, ILivestockData, IManureManagementData } from "./footprintSlice.interfaces";
import { IFootPrintMetaValues } from "./holdingSlice.interfaces";
import { useAppSelector } from "../../hooks";

axios.defaults.withCredentials = true;

const initialState: IFootprintState = {
    loading: false,
    detailSummaryLoading: false,
    benchmarkLoading: false,
}


export const getFootprints = createAsyncThunk(
    "footprint/get",
    async (props: IFootprintData, { rejectWithValue }) => {
        try {
            const { holdingId, PageNumber } = props
            const { data } = await axios.get('/api/footprint', { params: { holdinginformationid: holdingId, PageNumber } });
            // console.log(data)
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getAllFootprints = createAsyncThunk(
    "footprint/get-all",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get('/api/footprint');
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const createFootprint = createAsyncThunk(
    "footprint/new",
    async (props: IFootPrintMetaValues, { rejectWithValue }) => {
        try {
            const { holdingId, assessmentReference, assessmentType, accountingPeriodEndMonth, accountingPeriodEndYear } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                },
                params: {
                    holdinginformationid: holdingId
                }
            };
            const { data } = await axios.post('/api/footprint', { assessmentReference, assessmentType, accountingPeriodEndMonth, accountingPeriodEndYear }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const submitFootprint = createAsyncThunk(
    "footprint/submit",
    async (props: { id: string, status: string }, { rejectWithValue }) => {
        try {
            const { id, status } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                },
            };
            const { data } = await axios.put(`/api/footprint/${id}`, { status }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getFootprintById = createAsyncThunk(
    "footprint/getById",
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.get(`/api/footprint/${id}`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getLandUseDataById = createAsyncThunk(
    "footprint/landUseData/get",
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.get(`/api/footprint/${id}/land-use`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putLandUseDataById = createAsyncThunk(
    "footprint/landUseData/put",
    async (props: { landUseDetails: ILandUseData[] }, { rejectWithValue }) => {
        try {
            const { landUseDetails } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                LandUseDetails: landUseDetails
            }
            const { data } = await axios.put(`/api/footprint/${id}/land-use`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getHorsesOnSiteDataById = createAsyncThunk(
    "footprint/horsesOnSiteData/get",
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.get(`/api/footprint/${id}/horse-details`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putHorsesOnSiteDataById = createAsyncThunk(
    "footprint/horsesOnSiteData/put",
    async (props: IHorseDetailsData[], { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = props
            const { data } = await axios.put(`/api/footprint/${id}/horse-details`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putManureManagementDataById = createAsyncThunk(
    "footprint/manureManagement/put",
    async (props: IManureManagementData, { rejectWithValue }) => {
        try {
            const { manureType, manureDisposalMethod, manureRemovalFrequency, isMuckHeapsCovered, isMuckHeapsOnSolidBase } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                ManureManagement: {
                    manureType,
                    manureDisposalMethod,
                    manureRemovalFrequency,
                    isMuckHeapsCovered,
                    isMuckHeapsOnSolidBase
                }
            }
            // console.log(body)
            const { data } = await axios.put(`/api/footprint/${id}/land-use`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getElectricityAndFuelDataById = createAsyncThunk(
    "footprint/electricityFuel/get",
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.get(`/api/footprint/${id}/electricity-and-fuel`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putElectricityDetailsById = createAsyncThunk(
    "footprint/electricityDetails/put",
    async (props: {
        solarAnnualUsageOnSite: number, solarAnnualQuantityExported: number, windAnnualUsageOnSite: number,
        windAnnualQuantityExported: number, biomassAnnualUsageOnSite: number, biomassAnnualQuantityExported: number,
        biogasAnnualUsageOnSite: number, biogasAnnualQuantityExported: number, gridAnnualUsageOnSite: number
    }, { rejectWithValue }) => {
        try {
            const { solarAnnualUsageOnSite,
                solarAnnualQuantityExported,
                windAnnualUsageOnSite,
                windAnnualQuantityExported,
                biomassAnnualUsageOnSite,
                biomassAnnualQuantityExported,
                biogasAnnualUsageOnSite,
                biogasAnnualQuantityExported,
                gridAnnualUsageOnSite } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                electricityDetails: [
                    {
                        electricityType: "GridElectricity",
                        annualUsage: gridAnnualUsageOnSite
                    },
                    {
                        electricityType: "BiogasCHP",
                        annualUsage: biogasAnnualUsageOnSite,
                        annualExported: biogasAnnualQuantityExported
                    },
                    {
                        electricityType: "Wind",
                        annualUsage: windAnnualUsageOnSite,
                        annualExported: windAnnualQuantityExported
                    },
                    {
                        electricityType: "BiomassCHP",
                        annualUsage: biomassAnnualUsageOnSite,
                        annualExported: biomassAnnualQuantityExported
                    },
                    {
                        electricityType: "Solar",
                        annualUsage: solarAnnualUsageOnSite,
                        annualExported: solarAnnualQuantityExported
                    }
                ]
            }
            const { data } = await axios.put(`/api/footprint/${id}/electricity-and-fuel`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putFuelDetailsById = createAsyncThunk(
    "footprint/fuelDetails/put",
    async (props: {
        redDiesel: number, dieselOther: number, lpg: number,
        dieselHorseTransport: number, petrol: number, heatingOil: number
    }, { rejectWithValue }) => {
        try {
            const { redDiesel, dieselHorseTransport, dieselOther, lpg, petrol, heatingOil } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                fuelDetails: [
                    {
                        fuelType: "RedDiesel",
                        annualUsage: redDiesel
                    },
                    {
                        fuelType: "WhiteDieselHorseTransport",
                        annualUsage: dieselHorseTransport
                    },
                    {
                        fuelType: "WhiteDieselOther",
                        annualUsage: dieselOther
                    },
                    {
                        fuelType: "Petrol",
                        annualUsage: petrol
                    },
                    {
                        fuelType: "LPG",
                        annualUsage: lpg
                    },
                    {
                        fuelType: "HeatingOil",
                        annualUsage: heatingOil
                    }
                ]
            }
            const { data } = await axios.put(`/api/footprint/${id}/electricity-and-fuel`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putContractorsFuelDetailsById = createAsyncThunk(
    "footprint/contractorsFuelDetails/put",
    async (props: { smallTractor: number, mediumTractor: number, largeTractor: number }, { rejectWithValue }) => {
        try {
            const { smallTractor, mediumTractor, largeTractor } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                "contractorsFuelDetails": [
                    {
                        "contractorActivityType": "SmallTractor",
                        "annualHours": smallTractor
                    },
                    {
                        "contractorActivityType": "MediumTractor",
                        "annualHours": mediumTractor
                    },
                    {
                        "contractorActivityType": "LargeTractor",
                        "annualHours": largeTractor
                    }
                ]
            }
            const { data } = await axios.put(`/api/footprint/${id}/electricity-and-fuel`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putPurchasedFuelDetailsById = createAsyncThunk(
    "footprint/purchasedFuelDetails/put",
    async (props: {
        petrolCars: number, dieselCars: number, fourByFours: number,
        pickupTrucks: number, vans: number, lgvThreePointFive: number, lgvSevenPointFive: number,
        hgvOverSevenPointFive: number
    }, { rejectWithValue }) => {
        try {
            const { petrolCars, dieselCars, fourByFours, pickupTrucks, vans, lgvThreePointFive, lgvSevenPointFive, hgvOverSevenPointFive } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                "purchasedFuelDetails": [
                    {
                        "vehicleType": "CarsDiesel",
                        "annualDistance": dieselCars
                    },
                    {
                        "vehicleType": "CarsPetrol",
                        "annualDistance": petrolCars
                    },
                    {
                        "vehicleType": "FourByFours",
                        "annualDistance": fourByFours
                    },
                    {
                        "vehicleType": "PickupTrucks",
                        "annualDistance": pickupTrucks
                    },
                    {
                        "vehicleType": "Vans",
                        "annualDistance": vans
                    },
                    {
                        "vehicleType": "HGVsOver7_5t",
                        "annualDistance": hgvOverSevenPointFive
                    },
                    {
                        "vehicleType": "LGVsUpTo3_5t",
                        "annualDistance": lgvThreePointFive
                    },
                    {
                        "vehicleType": "LGVsUpTo7_5t",
                        "annualDistance": lgvSevenPointFive
                    }
                ]
            }
            const { data } = await axios.put(`/api/footprint/${id}/electricity-and-fuel`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putHorseTransportDetailsById = createAsyncThunk(
    "footprint/horseTransportDetails/put",
    async (props: {
        "vehicleType": string,
        "animalsTransportedByRoad":
        {
            "distance": string,
            "numberOfAnimals": number
        }[],
        "animalsTransportedOther"?:
        {
            "otherTransportType": string,
            "location": string,
            "numberOfAnimals": number
        }
    }[], { rejectWithValue }) => {
        try {

            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                "horseTransportDetails": props
            }
            const { data } = await axios.put(`/api/footprint/${id}/electricity-and-fuel`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getFeedAndBeddingDataById = createAsyncThunk(
    "footprint/feedAndBeddingData/get",
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.get(`/api/footprint/${id}/feed-and-bedding`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putPurchasedForageDataById = createAsyncThunk(
    "footprint/purchasedForage/put",
    async (props: IFeedAndBeddingData["forage"], { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                forage: props
            }
            const { data } = await axios.put(`/api/footprint/${id}/feed-and-bedding`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putSupplementaryFeedDataById = createAsyncThunk(
    "footprint/supplementaryFeed/put",
    async (props: IFeedAndBeddingData["supplementaryFeed"], { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                supplementaryFeed: props
            }
            const { data } = await axios.put(`/api/footprint/${id}/feed-and-bedding`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putOtherBeddingDataById = createAsyncThunk(
    "footprint/otherBedding/put",
    async (props: IFeedAndBeddingData["otherBedding"], { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const body = {
                otherBedding: props
            }
            const { data } = await axios.put(`/api/footprint/${id}/feed-and-bedding`, body, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getLivestockDataById = createAsyncThunk(
    "footprint/livestock/get",
    async (props: { id: string }, { rejectWithValue }) => {
        try {
            const { id } = props;
            const { data } = await axios.get(`/api/footprint/${id}/livestock`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const putLivestockDataById = createAsyncThunk(
    "footprint/livestock/put",
    async (props: ILivestockData[], { rejectWithValue }) => {
        try {
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const id = sessionStorage.getItem("footprintId")
            const { data } = await axios.put(`/api/footprint/${id}/livestock`, props, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const deleteFootprint = createAsyncThunk(
    "footprint/delete",
    async (props: { id: string, permanentDelete?: boolean }, { rejectWithValue }) => {
        try {
            const { id, permanentDelete } = props;
            const { data } = await axios.delete(`/api/footprint/${id}?permanentDelete=${permanentDelete}`);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const updateFootprint = createAsyncThunk(
    "footprint/update",
    async (props: IFootPrintMetaValues, { rejectWithValue }) => {
        try {
            const { id, assessmentReference, assessmentType, accountingPeriodEndMonth, accountingPeriodEndYear } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.put(`/api/footprint/${id}`, { assessmentReference, assessmentType, accountingPeriodEndMonth, accountingPeriodEndYear }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);


export const duplicateFootprint = createAsyncThunk(
    "footprint/duplicate",
    async (props: IFootPrintMetaValues, { rejectWithValue }) => {
        try {
            const { id, assessmentReference, assessmentType, accountingPeriodEndMonth, accountingPeriodEndYear } = props;
            const config = {
                headers: {
                    "Content-Type": "application/json"
                }
            };
            const { data } = await axios.post(`/api/footprint/${id}/copy`, { assessmentReference, assessmentType, accountingPeriodEndMonth, accountingPeriodEndYear }, config);
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getInitialSummary = createAsyncThunk(
    "admin/initial-summary",
    async (_, { rejectWithValue }) => {
        try {
            const { data } = await axios.get('/api/reports/get-initial-summary');
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getDetailSummary = createAsyncThunk(
    "admin/detail-summary",
    async (props: IAdminDetailSummaryFilters, { rejectWithValue }) => {
        try {
            const { minimumNumberOfHorses, minimumTotalHoldingArea, maximumTotalHoldingArea, maximumNumberOfHorses, region, pageNumber, startYear, holdingSystem, assessmentType, endYear } = props

            const params: IAdminDetailSummaryFilters = {
                pageNumber,
                pageSize: 5
            }

            if (minimumNumberOfHorses) params["minimumTotalHoldingArea"] = minimumNumberOfHorses;
            if (minimumTotalHoldingArea) params["minimumTotalHoldingArea"] = minimumTotalHoldingArea;
            if (maximumTotalHoldingArea) params["maximumTotalHoldingArea"] = maximumTotalHoldingArea;
            if (maximumNumberOfHorses) params["maximumNumberOfHorses"] = maximumNumberOfHorses;
            if (region) params["region"] = region;
            if (startYear) params["startYear"] = startYear;
            if (holdingSystem) params["holdingSystem"] = holdingSystem;
            if (assessmentType) params["assessmentType"] = assessmentType;
            if (endYear) params["endYear"] = endYear;
            
            const { data } = await axios.get('/api/reports/get-detail-summary', {
                params
            });
            
            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);

export const getBenchmarkingData = createAsyncThunk(
    "footprint/benchmarking",
    async (props: IBenchmarkingFilters, { rejectWithValue }) => {
        try {
            const { currentFootprintId, minimumNumberOfHorses, minimumTotalHoldingArea, maximumTotalHoldingArea, maximumNumberOfHorses, region, startYear, holdingSystem, assessmentType, endYear } = props

            const params: IBenchmarkingFilters = {
                currentFootprintId
            }

            if (minimumNumberOfHorses) params["minimumTotalHoldingArea"] = minimumNumberOfHorses;
            if (minimumTotalHoldingArea) params["minimumTotalHoldingArea"] = minimumTotalHoldingArea;
            if (maximumTotalHoldingArea) params["maximumTotalHoldingArea"] = maximumTotalHoldingArea;
            if (maximumNumberOfHorses) params["maximumNumberOfHorses"] = maximumNumberOfHorses;
            if (region) params["region"] = region;
            if (startYear) params["startYear"] = startYear;
            if (holdingSystem) params["holdingSystem"] = holdingSystem;
            if (assessmentType) params["assessmentType"] = assessmentType;
            if (endYear) params["endYear"] = endYear;

            const { data } = await axios.get('/api/benchmarking', {
                params
            });

            return data;
        } catch (error) {
            if (error instanceof AxiosError) {
                return rejectWithValue(error.response?.data);
            }
            // unhandled non-AxiosError goes here
            throw error;
        }
    }
);




const footprintSlice = createSlice({
    name: "footprint",
    initialState,
    reducers: {
        reset(state) {
            state.success = undefined;
            state.isDeleted = undefined;
            state.isUpdated = undefined;
            state.isDuplicated = undefined;
        },
        resetFootprint(state) {
            state.footprint = undefined;
        },
        resetBenchmarking(state) {
            state.benchmarkingData = undefined;
        },
        clearErrors(state) {
            state.errors = undefined;
        }
    },
    extraReducers(builder) {
        builder.addCase(getFootprints.pending, (state, action) => {
            state.loading = true;
        })
            .addCase(getFootprints.fulfilled, (state, action) => {
                state.loading = false;
                state.footprints = action.payload.data;
                state.paginationMetaData = action.payload.paginationMetaData;
            })
            .addCase(getFootprints.rejected, (state, action) => {
                state.loading = false;
                state.footprints = undefined;
                state.paginationMetaData = undefined;
                state.errors = action.payload;
            })

            .addCase(getAllFootprints.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getAllFootprints.fulfilled, (state, action) => {
                state.loading = false;
                state.footprints = action.payload.data;
            })
            .addCase(getAllFootprints.rejected, (state, action) => {
                state.loading = false;
                state.footprints = undefined;
                state.errors = action.payload;
            })

            .addCase(getInitialSummary.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getInitialSummary.fulfilled, (state, action) => {
                state.loading = false;
                state.adminInitialSummary = action.payload;
            })
            .addCase(getInitialSummary.rejected, (state, action) => {
                state.loading = false;
                state.adminInitialSummary = undefined;
                state.errors = action.payload;
            })

            .addCase(getDetailSummary.pending, (state, action) => {
                state.detailSummaryLoading = true;
            })
            .addCase(getDetailSummary.fulfilled, (state, action) => {
                state.detailSummaryLoading = false;
                state.adminDetailSummary = action.payload;
            })
            .addCase(getDetailSummary.rejected, (state, action) => {
                state.detailSummaryLoading = false;
                state.adminDetailSummary = undefined;
                state.errors = action.payload;
            })

            .addCase(getBenchmarkingData.pending, (state, action) => {
                state.benchmarkLoading = true;
            })
            .addCase(getBenchmarkingData.fulfilled, (state, action) => {
                state.benchmarkLoading = false;
                state.benchmarkingData = action.payload;
            })
            .addCase(getBenchmarkingData.rejected, (state, action) => {
                state.benchmarkLoading = false;
                state.benchmarkingData = undefined;
                state.errors = action.payload;
            })

            .addCase(getLandUseDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getLandUseDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.landUse = action.payload.landUse;
            })
            .addCase(getLandUseDataById.rejected, (state, action) => {
                state.loading = false;
                state.landUse = undefined;
                state.errors = action.payload;
            })

            .addCase(getHorsesOnSiteDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getHorsesOnSiteDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.horseDetails = action.payload.horseDetails;
            })
            .addCase(getHorsesOnSiteDataById.rejected, (state, action) => {
                state.loading = false;
                state.horseDetails = undefined;
                state.errors = action.payload;
            })

            .addCase(putHorsesOnSiteDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putHorsesOnSiteDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.horseDetails = action.payload.horseDetails;
            })
            .addCase(putHorsesOnSiteDataById.rejected, (state, action) => {
                state.loading = false;
                state.horseDetails = undefined;
                state.errors = action.payload;
            })

            .addCase(putLandUseDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putLandUseDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.landUse = action.payload.landUse;
            })
            .addCase(putLandUseDataById.rejected, (state, action) => {
                state.loading = false;
                state.landUse = undefined;
                state.errors = action.payload;
            })

            .addCase(submitFootprint.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(submitFootprint.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
            })
            .addCase(submitFootprint.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })

            .addCase(putManureManagementDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putManureManagementDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.landUse = action.payload.landUse;
            })
            .addCase(putManureManagementDataById.rejected, (state, action) => {
                state.loading = false;
                state.landUse = undefined;
                state.errors = action.payload;
            })

            .addCase(getElectricityAndFuelDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getElectricityAndFuelDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.electricityAndFuel = action.payload.electricityAndFuel;
            })
            .addCase(getElectricityAndFuelDataById.rejected, (state, action) => {
                state.loading = false;
                state.electricityAndFuel = undefined;
                state.errors = action.payload;
            })

            .addCase(putElectricityDetailsById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putElectricityDetailsById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.electricityAndFuel = action.payload.electricityAndFuel;
            })
            .addCase(putElectricityDetailsById.rejected, (state, action) => {
                state.loading = false;
                state.electricityAndFuel = undefined;
                state.errors = action.payload;
            })

            .addCase(putFuelDetailsById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putFuelDetailsById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.electricityAndFuel = action.payload.electricityAndFuel;
            })
            .addCase(putFuelDetailsById.rejected, (state, action) => {
                state.loading = false;
                state.electricityAndFuel = undefined;
                state.errors = action.payload;
            })

            .addCase(putContractorsFuelDetailsById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putContractorsFuelDetailsById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.electricityAndFuel = action.payload.electricityAndFuel;
            })
            .addCase(putContractorsFuelDetailsById.rejected, (state, action) => {
                state.loading = false;
                state.electricityAndFuel = undefined;
                state.errors = action.payload;
            })

            .addCase(putPurchasedFuelDetailsById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putPurchasedFuelDetailsById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.electricityAndFuel = action.payload.electricityAndFuel;
            })
            .addCase(putPurchasedFuelDetailsById.rejected, (state, action) => {
                state.loading = false;
                state.electricityAndFuel = undefined;
                state.errors = action.payload;
            })

            .addCase(putHorseTransportDetailsById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putHorseTransportDetailsById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.electricityAndFuel = action.payload.electricityAndFuel;
            })
            .addCase(putHorseTransportDetailsById.rejected, (state, action) => {
                state.loading = false;
                state.electricityAndFuel = undefined;
                state.errors = action.payload;
            })

            .addCase(getFeedAndBeddingDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getFeedAndBeddingDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.feedAndBedding = action.payload.feedAndBedding;
            })
            .addCase(getFeedAndBeddingDataById.rejected, (state, action) => {
                state.loading = false;
                state.feedAndBedding = undefined;
                state.errors = action.payload;
            })

            .addCase(putPurchasedForageDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putPurchasedForageDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.feedAndBedding = action.payload.feedAndBedding;
            })
            .addCase(putPurchasedForageDataById.rejected, (state, action) => {
                state.loading = false;
                state.feedAndBedding = undefined;
                state.errors = action.payload;
            })

            .addCase(putSupplementaryFeedDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putSupplementaryFeedDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.feedAndBedding = action.payload.feedAndBedding;
            })
            .addCase(putSupplementaryFeedDataById.rejected, (state, action) => {
                state.loading = false;
                state.feedAndBedding = undefined;
                state.errors = action.payload;
            })

            .addCase(putOtherBeddingDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putOtherBeddingDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.feedAndBedding = action.payload.feedAndBedding;
            })
            .addCase(putOtherBeddingDataById.rejected, (state, action) => {
                state.loading = false;
                state.feedAndBedding = undefined;
                state.errors = action.payload;
            })

            .addCase(getLivestockDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(getLivestockDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.livestock = action.payload.livestockDetails;
            })
            .addCase(getLivestockDataById.rejected, (state, action) => {
                state.loading = false;
                state.livestock = undefined;
                state.errors = action.payload;
            })


            .addCase(getFootprintById.pending, (state, action) => {
                state.metaDataLoading = true;
            })
            .addCase(getFootprintById.fulfilled, (state, action) => {
                state.metaDataLoading = false;
                state.footprint = action.payload;
            })
            .addCase(getFootprintById.rejected, (state, action) => {
                state.metaDataLoading = false;
                state.footprint = undefined;
                state.errors = action.payload;
            })

            .addCase(putLivestockDataById.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(putLivestockDataById.fulfilled, (state, action) => {
                state.loading = false;
                state.success = true;
                state.livestock = action.payload.livestockDetails;
            })
            .addCase(putLivestockDataById.rejected, (state, action) => {
                state.loading = false;
                state.livestock = undefined;
                state.errors = action.payload;
            })

            .addCase(createFootprint.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(createFootprint.fulfilled, (state, action) => {
                state.loading = false;
                state.footprint = action.payload;
                state.success = true;
            })
            .addCase(createFootprint.rejected, (state, action) => {
                state.loading = false;
                state.footprint = undefined;
                state.errors = action.payload;
            })

            .addCase(deleteFootprint.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(deleteFootprint.fulfilled, (state, action) => {
                state.loading = false;
                state.isDeleted = true;
            })
            .addCase(deleteFootprint.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })

            .addCase(updateFootprint.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(updateFootprint.fulfilled, (state, action) => {
                state.loading = false;
                state.isUpdated = true;
            })
            .addCase(updateFootprint.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })

            .addCase(duplicateFootprint.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(duplicateFootprint.fulfilled, (state, action) => {
                state.loading = false;
                state.isDuplicated = true;
            })
            .addCase(duplicateFootprint.rejected, (state, action) => {
                state.loading = false;
                state.errors = action.payload;
            })




    },
})

export const { clearErrors, reset, resetFootprint, resetBenchmarking } = footprintSlice.actions;
export default footprintSlice.reducer;