import React, { FormEvent, useEffect, useState } from 'react';
import { Formik, FieldArray, useField } from 'formik';
import { CheckBox, Input, InputFormButton, InputFormButtonsContainer, InputFormDescription, InputFormHeading, InputFormSaveButton, Select } from '../../styles/Input.styled';
import { ErrorText, InputForm, StandardInputGroup } from '../../styles/Form.styled';
import { AddMainRecordButton, UtilityButton } from '../../styles/Button.styled';
import { InputSubContainer, InputSubContainerRow, LandUseInputsContainer } from '../../styles/Container.styled';
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from 'react-router-dom';
import { useActiveStepHandler } from '../Input';
import { useAppDispatch, useAppSelector } from '../../../hooks';
import { getDropdownValues } from '../../../features/operations/operationsSlice';
import { toast } from 'react-toastify';
import { IOtherBeddingTypes } from '../Input.interface';
import tippy from 'tippy.js';

type Props = {
    index: number,
    errors: any,
    touched: any,
    deleteRecordHandler: (a: string) => void,
    inputIsLocked: () => boolean
}

const OtherBeddingRecord = (props: Props) => {

    // binding record properties using index
    const [otherBeddingTypeField, otherBeddingTypeMeta, otherBeddingTypeHelpers] = useField(`otherBeddingTypes[${props.index}].otherBeddingType`);
    const [packagingDisposalMethodField, packagingDisposalMethodMeta, packagingDisposalMethodHelpers] = useField(`otherBeddingTypes[${props.index}].packagingDisposalMethod`);
    const [totalTonnagePurchasedAnnuallyField, totalTonnagePurchasedAnnuallyMeta, totalTonnagePurchasedAnnuallyHelpers] = useField(`otherBeddingTypes[${props.index}].totalTonnagePurchasedAnnually`);
    const [estimatedDistanceFromDistributorField, estimatedDistanceFromDistributorMeta, estimatedDistanceFromDistributorHelpers] = useField(`otherBeddingTypes[${props.index}].estimatedDistanceFromDistributor`);
    const [idField, idMeta, idHelpers] = useField(`otherBeddingTypes[${props.index}].id`);

    const [showBeddingInfo, setShowBeddingInfo] = useState(false);

    const errors = props.errors;
    const touched = props.touched;

    const { loading, dropdowns } = useAppSelector(state => state.operations);

    useEffect(() => {
        if (errors.otherBeddingTypes && errors.otherBeddingTypes[props.index] && touched.otherBeddingTypes &&
            touched.otherBeddingTypes[props.index]) setShowBeddingInfo(true)

        tippy('.bedding-type-tooltip', {
            content: "Select the most compatible option from the dropdown.",
        });

        tippy('.tonnage-purchased-tooltip', {
            content: "Enter the weight volume in tonnes of the selected bedding purchased annually.  Most bales weigh about 20kg.",
        });

        tippy('.disposal-method-tooltip', {
            content: "Select the most appropriate method used for disposal of packaging, if more than one method is used select the method that is used for the largest volume.",
        });

        tippy('.distributor-distance-tooltip', {
            content: "Select the estimated distance the holding is from the product distributor. This allows the tool to calculate the transport emissions associated with delivery of the product.",
        });
    }, [errors, props.index, touched, showBeddingInfo])

    const onOtherBeddingTypeInput = (e: FormEvent) => {
        let target = e.target as HTMLSelectElement
        otherBeddingTypeHelpers.setValue(target.value);
    };
    const onOtherBeddingTypeBlur = () => {
        otherBeddingTypeHelpers.setTouched(true);
    };

    const onPackagingDisposalMethodInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        packagingDisposalMethodHelpers.setValue(target.value);
    };
    const onPackagingDisposalMethodBlur = () => {
        packagingDisposalMethodHelpers.setTouched(true);
    };

    const onTotalTonnagePurchasedAnnuallyInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        totalTonnagePurchasedAnnuallyHelpers.setValue(target.value);
    };
    const onTotalTonnagePurchasedAnnuallyBlur = () => {
        totalTonnagePurchasedAnnuallyHelpers.setTouched(true);
    };

    const onEstimatedDistanceFromDistributorInput = (e: FormEvent) => {
        let target = e.currentTarget as HTMLInputElement;
        estimatedDistanceFromDistributorHelpers.setValue(target.value);
    };
    const onEstimatedDistanceFromDistributorBlur = () => {
        estimatedDistanceFromDistributorHelpers.setTouched(true);
    };

    const toggleBeddingInfo = () => {
        setShowBeddingInfo(!showBeddingInfo);
    }

    var [beddings, meta, helpers] = useField("otherBeddingTypes")
    var existingOtherBeddingTypeRecords = beddings.value.filter((element: { id: any; }) => element.id !== idField.value).map((element: { otherBeddingType: any; }) => { return element.otherBeddingType });

    return (
        <InputSubContainer>

            <InputSubContainerRow>
                <div>
                    <label>Bedding type</label>
                </div>

                <div>
                    <select
                        className='form-control'
                        value={otherBeddingTypeField.value}
                        onChange={onOtherBeddingTypeInput}
                        onBlur={onOtherBeddingTypeBlur}
                        data-role="none"
                        disabled={props.inputIsLocked()}
                    >
                        <option value="">--Please choose an option--</option>
                        {dropdowns?.filter(element => element.theme === "Bedding" && !existingOtherBeddingTypeRecords.includes(element.key)).map(value => {
                            return <option key={value.key} value={value.key}>{value.description}</option>
                        })}
                    </select>
                    <ErrorText>{errors.otherBeddingTypes && errors.otherBeddingTypes[props.index] && touched.otherBeddingTypes && touched.otherBeddingTypes[props.index] && errors.otherBeddingTypes[props.index].otherBeddingType && touched.otherBeddingTypes[props.index].otherBeddingType && errors.otherBeddingTypes[props.index].otherBeddingType}</ErrorText>
                </div>


                <div>
                    <UtilityButton className='bedding-type-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                    {showBeddingInfo ? (
                        <UtilityButton src="/icons/closeSection.png" alt='close section' onClick={toggleBeddingInfo} />) : (
                        <UtilityButton src="/icons/openSection.png" alt='open section' onClick={toggleBeddingInfo} />
                    )}
                    <UtilityButton src="/icons/delete.png" alt='tooltip' onClick={() => props.deleteRecordHandler(idField.value)} />
                </div>
            </InputSubContainerRow>

            {showBeddingInfo && (
                <InputSubContainer>

                    <InputSubContainerRow>
                        <div>
                            <label>Total tonnage purchased annually (tonnes)</label>

                        </div>

                        <div>
                            <input
                                className='form-control'
                                data-role="none"
                                type="number"
                                value={totalTonnagePurchasedAnnuallyField.value}
                                onChange={onTotalTonnagePurchasedAnnuallyInput}
                                onBlur={onTotalTonnagePurchasedAnnuallyBlur}
                                disabled={props.inputIsLocked()}
                            />
                            <ErrorText>
                                {errors.otherBeddingTypes && errors.otherBeddingTypes[props.index] && touched.otherBeddingTypes &&
                                    touched.otherBeddingTypes[props.index] && errors.otherBeddingTypes[props.index].totalTonnagePurchasedAnnually && touched.otherBeddingTypes[props.index].totalTonnagePurchasedAnnually &&
                                    errors.otherBeddingTypes[props.index].totalTonnagePurchasedAnnually}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton className='tonnage-purchased-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow>

                    <InputSubContainerRow>
                        <div>
                            <label>Packaging disposal method</label>
                        </div>

                        <div>
                            <select
                                className='form-control'
                                data-role="none"
                                value={packagingDisposalMethodField.value}
                                onChange={onPackagingDisposalMethodInput}
                                onBlur={onPackagingDisposalMethodBlur}
                                disabled={props.inputIsLocked()}
                            >
                                <option value="">--Please choose an option--</option>
                                {dropdowns?.filter(element => element.theme === "PackagingDisposalOption").map(value => {
                                    return <option key={value.key} value={value.key}>{value.description}</option>
                                })}
                            </select>
                            <ErrorText>
                                {errors.otherBeddingTypes && errors.otherBeddingTypes[props.index] && touched.otherBeddingTypes &&
                                    touched.otherBeddingTypes[props.index] && errors.otherBeddingTypes[props.index].packagingDisposalMethod && touched.otherBeddingTypes[props.index].packagingDisposalMethod &&
                                    errors.otherBeddingTypes[props.index].packagingDisposalMethod}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton className='disposal-method-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow>

                    <InputSubContainerRow>
                        <div>
                            <label>Estimated distance from distributor (km)</label>

                        </div>

                        <div>
                            <select
                                className='form-control'
                                data-role="none"
                                value={estimatedDistanceFromDistributorField.value}
                                onChange={onEstimatedDistanceFromDistributorInput}
                                onBlur={onEstimatedDistanceFromDistributorBlur}
                                disabled={props.inputIsLocked()}
                            >
                                <option value="">--Please choose an option--</option>
                                {dropdowns?.filter(element => element.theme === "TransportDistance").map(value => {
                                    return <option key={value.key} value={value.key}>{value.description}</option>
                                })}
                            </select>
                            <ErrorText>
                                {errors.otherBeddingTypes && errors.otherBeddingTypes[props.index] && touched.otherBeddingTypes &&
                                    touched.otherBeddingTypes[props.index] && errors.otherBeddingTypes[props.index].estimatedDistanceFromDistributor && touched.otherBeddingTypes[props.index].estimatedDistanceFromDistributor &&
                                    errors.otherBeddingTypes[props.index].estimatedDistanceFromDistributor}
                            </ErrorText>
                        </div>

                        <div>
                            <UtilityButton className='distributor-distance-tooltip' src="/icons/tooltip.png" alt='tooltip' />
                        </div>
                    </InputSubContainerRow>
                </InputSubContainer>
            )}

        </InputSubContainer>
    )
}

export default OtherBeddingRecord